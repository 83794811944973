import _ from 'lodash'
import actions from './actions'
import mutations from './mutations'
import icons from '@/theme/icons'

export default {
  namespaced: true,
  state: {
    data: {
      items: null,
      count: 0
    },
    props: {
      name: 'Monitor',
      icon: icons.monitor,
      singleton: true,
      layout: ['columns'] // Restrict to layout columns only
    },
    monitoredItems: {}, // List of items to fill Monitor Widgets
    monitoredItemsMetrics: {} // All data of items to draw gauges and graphs
  },
  getters: {
    REFRESH_INTERVAL: state => { return 5 }, // 5 minutes
    data: state => {
      return state.data
    },
    props: state => {
      return state.props
    },
    monitoredItems: state => {
      return _.sortBy(state.monitoredItems, item => {
        return (item.name || '').toUpperCase()
      })
    },
    monitoredItemsMetrics: state => itemId => {
      return state.monitoredItemsMetrics[itemId]
    }
  },
  mutations,
  actions
}
