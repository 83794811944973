// TODO: Import the configuration like this :
//       export * from `./{{ NODE_ENV }}`
// TODO: Extract config from env files like in https://cli.vuejs.org/guide/mode-and-env.html#environment-variables.

let env = '{{ NODE_ENV }}'

if (process.env.NODE_ENV === 'development') {
  env = 'development'
}

switch (env) {
  case 'development':
  case 'develop':
  case 'dev':
    module.exports = require('./dev/app')
    break

  case 'staging':
  case 'stage':
    module.exports = require('./stage/app')
    break

  case 'production':
  case 'prod':
    module.exports = require('./production/app')
    break
}
