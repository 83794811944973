<template>
  <v-dialog v-model="show" persistent width="100%" :max-width="$vuetify.breakpoint.mdAndUp ? '90%' : ''">
    <v-card tile height="100%">
      <v-card-title>
        <v-icon dark left @click.native="close()">
          $vuetify.icons.close
        </v-icon>
        <span class="text-h6">
          {{ instance.name }}
        </span>
        <v-spacer />
        <span>{{ instance.provider }} / {{ $t(instance.machineType) }} {{ instance.customerName ? ' — ' + instance.customerName : '' }} / {{ instance.customerId || '-' }}</span>
      </v-card-title>

      <cs-color-divider color="widget-monitor" class="mb-2" />

      <v-card-text v-if="src" class="py-0 px-2">
        <iframe ref="internal-console-frame" :title="title" :src="src" style="width:100%;height:700px" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn rounded class="main-button" @click.native="close()">
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConsoleDialog',
  data () {
    return {
      instance: {},
      show: false,
      src: null,
      title: ''
    }
  },
  methods: {
    close () {
      this.resolve(false)
      this.show = false
    },
    open (instance, hostname, socketId) {
      this.show = true
      this.instance = instance
      this.src = `/console/console.html?p=${hostname}&u=${socketId}&n=${instance.name}`
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    }
  }
}
</script>
