import Vue from 'vue'
import Router from 'vue-router'
import routesServices from '@/services/routes'

Vue.use(Router)

export const router = new Router({
  routes: [
    routesServices.loadViewRoute('/', 'Main', null, [
      routesServices.loadViewRoute('/', 'Home', 'home', [], false), // Do not lazy load because it will loop-reload!
      routesServices.loadViewRoute('/dashboard', 'Dashboard', 'dashboard'),
      routesServices.loadViewRoute('/dashboardgrid', 'DashboardGrid', 'dashboardgrid'),
      routesServices.loadViewRoute('/secrets/:secretid', 'Secrets', 'secret'),
      routesServices.loadViewRoute('/password-reset/:token', 'ResetPassword', 'resetpassword'),
      routesServices.loadViewRoute('/about', 'About', 'about'),
      routesServices.pageNotFound()
    ])
  ]
})

export function registerRouteCheck (stratusLib, router) {
  const { name, version, isValid } = stratusLib.services.browser.checkBrowser()
  console.log(`[browser] ${name} ${version}`, isValid ? 'good 🗸' : 'bad 🗴')

  router.beforeEach((to, from, next) => {
    if (isValid) next()
    else {
      console.error('[browser] is not valid!')

      let el = document.getElementById('wrong-browser-version')
      el.innerText = `${name} ${version}`
      el = document.getElementById('wrong-browser')
      if (el) el.classList.remove('hidden')
      el = document.getElementById('loading-message')
      if (el) el.classList.add('hidden')
      el = document.getElementById('app')
      if (el) el.classList.add('hidden')
      next(false)
    }
  })
}

export default {
  registerRouteCheck,
  router
}
