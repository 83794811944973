const baseUrl = process.env.VUE_APP_API_URL || 'http://api.scalair.localhost'
const sentryDSN = '' // No Sentry for DEV env
const appVersion = '<local>'

const config = {
  beta: true,
  appVersion,
  env: 'development',
  url: baseUrl,
  api: `${baseUrl}`,
  sentryDSN,
  urlScalairDashboard: process.env.VUE_APP_DASHBOARD_URL || 'http://dashboard.scalair.localhost',
  urlScalairManager: process.env.VUE_APP_MANAGER_URL || 'http://manager.scalair.localhost',
  urlScalairStatus: process.env.VUE_APP_STATUS_URL || 'http://status.scalair.localhost',
  ajaxUploadUrl: `${baseUrl}/upload`,
  infraStatusBaseUrl: 'http://status.scalair.localhost',
  infraStatusServicesUrl: 'http://api.status.scalair.localhost/services',
  servicesIconsPath: '/img/services/',
  avatarApi: 'https://www.gravatar.com/avatar/<%=hash%>?s=64&d=retro',
  locale: 'fr-FR',
  debug: {
    i18n: true, // Log warning if translation is missing
    router: true, // Display log for route loading
    http: true // Log every HTTP request
  },
  metrics: {
    uptime: { ignore: true }
  },
  cloud: {
    // Can be started/stopped/rebooted and can setup alert thresholds from this app
    virtualVDC: 'vm-vdc',
    virtualVM: 'vm-mutu',
    virtuals: ['vm-mutu', 'vm-vdc'],
    // Can NOT be started/stoped/rebooted and NO setup alert thresholds from this app
    physicals: ['server'], // First is the default
    // Our Infra is fully manage by us ;)
    providers: {
      internals: ['scalair'], // First is the default
      // Third party infra we do NOT fully manage
      externals: ['aws']
    }
  }
}

export default config
