import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    list: [],
    roles: [],
    types: []
  },
  getters: {
    list: state => { return state.list || [] }
  },
  actions,
  mutations
}
