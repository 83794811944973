import actions from './actions'
import mutations from './mutations'
import icons from '@/theme/icons'

export default {
  namespaced: true,
  state: {
    BLINK_DELAY: 60 * 60 * 1000, // Blink if commented by user less than the delay ago
    data: {
      items: null,
      count: 0
    },
    props: {
      name: 'Tickets',
      icon: icons.tickets,
      singleton: true,
      layout: ['columns', 'grid']
    },
    error: null
  },
  getters: {
    data: state => {
      return state.data
    },
    props: state => {
      return state.props
    },
    error: state => {
      return state.error
    }
  },
  mutations,
  actions
}
