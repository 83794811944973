<template>
  <scalair-widget-responsive :title="monitoredItem ? monitoredItem.name : $t(widgetProps.name)" :icon="widgetProps.icon" :cls="'widget-' + id + '--text widget-background-' + id" :id="id" :can-configure="canConfigure" :loading="isLoading">
    <v-card slot="menu" class="pa-4" outlined tile>
      <v-row align="center" v-if="monitoredItem && monitoredItem.id" dense>
        <v-col>
          <v-autocomplete v-model.trim="searchItem" :filter="filterInstances" :loading="isSearching" :items="searchItems" item-text="name" item-value="id" dense auto-select-first return-object cache-items hide-no-data hide-details :label="$t('Search...')" prepend-inner-icon="$vuetify.icons.search" clearable />
        </v-col>
        <v-col class="shrink">
          <v-btn icon @click="addSearchToMonitor">
            <v-icon class="menu-icon--text">
              $vuetif.icons.add
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row align="center" dense>
        <v-col cols="12">
          <v-switch dense inset flat v-model="widgetConfig.dense" :label="$t('Dense display')" class="ma-0" />
        </v-col>
      </v-row>

      <v-row align="center" dense>
        <v-col cols="12" class="text-center">
          <v-btn rounded class="menu-icon--text" @click="removeWidget()">
            <v-icon small left>
              $vuetify.icons.close
            </v-icon>
            <span class="v-label">{{ $t('Remove this widget') }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-row slot="content" class="pa-2">
      <v-col>
        <template v-if="monitoredItem && monitoredItem.id">
          <cs-monitor-card hide-vm-name :class="monitorWidthClass" :vm="monitoredItem" @saveMonitoredItems="saveMonitoredItem" :ref="`monitor-card-${widgetId}`" :dense-gauges="widgetConfig.dense" @widgetRefreshed="onWidgetRefreshed" />
        </template>
        <template v-else>
          <v-row align="baseline">
            <v-col>
              <v-autocomplete v-model="searchItem" :filter="filterInstances" :loading="isSearching" :items="searchItems" :label="$t('Search...')" item-text="name" item-value="id" dense auto-select-first return-object hide-no-data hide-details cache-items prepend-inner-icon="$vuetify.icons.search" clearable />
            </v-col>
            <v-col class="shrink text-center">
              <v-btn icon @click="addSearchToMonitor">
                <v-icon class="menu-icon--text">
                  $vuetif.icons.add
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              {{ $tc('No instance|One instance|{count} instances', this.searchItems.length, { count: this.searchItems.length}) }}
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </scalair-widget-responsive>
</template>

<script>
import MonitorCard from './MonitorCard'

const WIDGET_STORE = 'monitor'
const WIDGET_ID = 'monitorSingle'
const SUPERVISION_WIDGET_ID = 'supervision'
const DEFAULT_CONFIG = {
  widgetType: WIDGET_ID,
  hideHeaders: false,
  showHidePieChart: false,
  dense: false
}
const REFRESH_INTERVAL = 5 * 60 * 1000 // Refresh widget data every 3 minutes

export default {
  name: 'WidgetMonitorSingle',
  components: {
    'cs-monitor-card': MonitorCard
  },
  props: {
    widgetId: { type: String, required: true }
  },
  data () {
    return {
      canConfigure: true,
      config: {},
      isLoading: true,
      menu: false,
      interval: {},
      id: WIDGET_STORE, // Use same styles as "monitor"
      isSearching: false,
      searchItem: null,
      searchItems: [],
      widgetConfig: DEFAULT_CONFIG,
      menuVisible: false // Internal use
      // monitoredItem: null
    }
  },
  watch: {
    widgetConfig: {
      // Just a watcher to save configuration of the widget
      handler () {
        if (this.widgetConfig && this.widgetConfig.monitoredItem) {
          this.widgetConfig.widgetType = WIDGET_ID
          this.$store.dispatch('saveWidgetConfig', { widgetId: this.widgetId, config: this.widgetConfig })
        }
      },
      deep: true
    }
  },
  computed: {
    widgetProps () { return this.$store.getters['widgets/monitorSingle/props'] },
    monitorWidthClass: function () {
      return 'xs12'
    },
    monitoredItem () { return this.widgetConfig.monitoredItem }
  },
  methods: {
    getMonitoredItem (instanceId) {
      const tmp = this.$store.getters.MonitoredItems.find(o => o.id === instanceId)
      return tmp
    },
    addSearchToMonitor () {
      if (this.searchItem) this.addInstanceToMonitor(this.searchItem)
    },
    addInstanceToMonitor (itemToFollow) {
      // Check if it is already monitored
      // Save VM list
      this.addingVmToMonitor = true
      this.$store.dispatch('loadProfilePreferences', 'widgets')
        .then(result => {
          const instanceToSave = {
            id: itemToFollow.id,
            name: itemToFollow.name,
            provider: itemToFollow.provider,
            // isConfigAble: itemToFollow.isConfigAble,
            machineType: itemToFollow.machineType
          }
          instanceToSave.showChart = false // Initialize on current item before saving
          instanceToSave.showStorage = [true] // Initialize on current item before saving

          this.$store.commit('addMonitoredItems', instanceToSave)
          const monitorWidget = (result || {})[this.widgetId] || DEFAULT_CONFIG
          this.widgetConfig.monitoredItem = { ...instanceToSave }
          // this.monitoredItem = this.getMonitoredItem(itemToFollow.id)
          monitorWidget.monitoredItem = { ...instanceToSave }
          monitorWidget.widgetType = WIDGET_ID

          this.$store.dispatch('saveWidgetConfig', { widgetId: this.widgetId, config: monitorWidget })
            .then(() => {
              this.refreshWidgetData()
              this.$stratus.services.notify.success(this.$t('Instance {name} added successfully', { name: instanceToSave.name }))
              this.addingVmToMonitor = false
            })
            .catch(error => {
              console.error(error)
              this.$stratus.services.notify.error(error)
              this.addingVmToMonitor = false
            })
        })
    },
    filterInstances (item, queryText, itemText) {
      return (itemText || '').toLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) >= 0
    },
    async loadSearchItems () {
      this.isSearching = true
      let _items = this.$store.getters[`widgets/${SUPERVISION_WIDGET_ID}/data`]
      if (!_items || !_items.count) {
        await this.$store.dispatch(`widgets/${SUPERVISION_WIDGET_ID}/getData`)
        _items = this.$store.getters[`widgets/${SUPERVISION_WIDGET_ID}/data`] || []
      }
      this.searchItems = this.$stratus.services.sort.natural(_items.items, { name: 'asc' })
      this.isSearching = false
    },
    onWidgetRefreshed () {
      this.$emit('widgetRefreshed', { ref: `monitor-card-${this.widgetId}`, configId: this.widgetId })
    },
    resizeContent (i, newH, newW, newHPx, newWPx, widgetConf) {
      try {
        if (this.$refs[`monitor-card-${this.widgetId}`]) this.$refs[`monitor-card-${this.widgetId}`].resizeContent(newHPx, newWPx)
      } catch (e) {
        console.warn(`[MonitorSingle] resizeContent ${this.widgetId}`)
        console.error(e)
      }
    },
    saveConfig () {
      this.widgetConfig.widgetType = WIDGET_ID
      this.$store.dispatch('saveWidgetConfig', { widgetId: this.widgetId, config: this.widgetConfig })
      this.menuVisible = false
    },
    saveMonitoredItem (item, refresh) {
      this.$store.dispatch('loadProfilePreferences', 'widgets')
        .then(result => {
          const widgetConfig = (result || {})[this.widgetId] || {}
          widgetConfig.widgetType = WIDGET_ID
          widgetConfig.dense = true

          const vm = this.getMonitoredItem(item.id)
          if (vm) {
            vm.page = item.page || 'gauges'
            vm.delay = item.delay || '1h'
            vm.showChart = item.showChart || false
            vm.showStorage = item.showStorage || [true]
            vm.hideGraphs = item.hideGraphs || {}
            // this.monitoredItem = vm
            widgetConfig.monitoredItem = vm
          }
          this.$store.dispatch('saveWidgetConfig', { widgetId: this.widgetId, config: widgetConfig })
          if (refresh) {
            this.refreshWidgetData()
          }
        })
    },
    async refreshMonitoredItem () {
      if (this.monitoredItem) {
        try {
          if (this.$refs[`monitor-card-${this.widgetId}`]) await this.$refs[`monitor-card-${this.widgetId}`].refresh()
        } catch (e) {
          console.error(this.$refs[`monitor-card-${this.widgetId}`])
          console.error(e)
        }
      }
      setTimeout(this.refreshMonitoredItem, REFRESH_INTERVAL)
    },
    refreshWidgetData () {
      this.isLoading = true
      this.$store.dispatch('loadProfilePreferences', 'widgets')
        .then(result => {
          // this.monitoredItem = null
          this.widgetConfig = (result || {})[this.widgetId] || {}
          if (this.widgetConfig.monitoredItem) {
            // Convert old provider and machine type
            if (this.widgetConfig.monitoredItem.provider === 'vsphere') this.widgetConfig.monitoredItem.provider = this.$store.getters.appConfig.cloud.providers.internals[0]
            if (this.widgetConfig.monitoredItem.machineType === 'vm') this.widgetConfig.monitoredItem.machineType = this.$store.getters.appConfig.cloud.virtualVM
            if (this.widgetConfig.monitoredItem.machineType === 'server') this.widgetConfig.monitoredItem.machineType = this.$store.getters.appConfig.cloud.physicals[0]
            this.$store.commit('addMonitoredItems', this.widgetConfig.monitoredItem)
            // this.monitoredItem = this.getMonitoredItem(this.widgetConfig.id)
            setTimeout(this.refreshMonitoredItem, 1000)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    removeWidget () {
      this.$emit('removeWidget', this.widgetId)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  created () {
    this.loadSearchItems()
  },
  mounted () {
    this.widgetConfig.showHidePieChart = this.$store.getters.showPieChart
    this.widgetConfig.dense = this.$store.getters.dense
    this.refreshWidgetData()
  }
}
</script>
