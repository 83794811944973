<template>
  <cs-expand-panel :header="$t('Backups')" dense :count="backupsCount" @expand="onExpand">
    <v-row slot="header" align="center" no-gutters>
      <slot name="header-extension" />
    </v-row>

    <v-sheet slot="content" v-if="value.length" class="transparent">
      <div v-for="(backup, index) in value" :key="index">
        <div v-if="backup.backups.length === 0" class="d-flex align-center">
          {{ $t(backup.backup_policy_name) || $t('Undefined') }}
          <v-chip small class="ml-1" color="primary">
            {{ backup.backups.length || $t('None') }}
          </v-chip>
        </div>

        <cs-expand-panel v-else :header="$t(backup.backup_policy_name)" :expanded="true" dense :count="backup.backups.length">
          <v-row slot="content" no-gutters>
            <v-col cols="12" sm="6" xl="4" v-for="(item, key) in backup.backups" :key="key" class="mt-1">
              <v-hover v-slot="{ hover }">
                <div :class="{ focused: hover }" class="d-flex align-center">
                  <v-chip v-if="isLord" small color="primary">
                    {{ item.provider }}
                  </v-chip>
                  <div class="ml-1 text-caption">
                    {{ item.creation_ts ? $t('Created at {date}', { date: formatTimestamp(item.creation_ts) }) : '' }}
                    <br>
                    {{ item.expiration_ts ? $t('Expires on {date}', { date: formatTimestamp(item.expiration_ts) }) : '' }}
                  </div>
                </div>
              </v-hover>
            </v-col>
          </v-row>
        </cs-expand-panel>

        <v-divider v-if="index + 1 < value.length" class="ma-2" />
      </div>
    </v-sheet>
  </cs-expand-panel>
</template>

<script>
export default {
  name: 'CsBackupsPanel',
  props: {
    value: { type: Array, default () { return [] } }
  },
  data () {
    return {
      opened: true
    }
  },
  computed: {
    backupsCount () {
      let count = 0
      for (const backup of this.value) {
        if (Array.isArray(backup.backups)) count += backup.backups.length
      }
      return count
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    isLord () { return this.$store.getters['$stratus-states/isLord'] }
  },
  methods: {
    formatTimestamp (ts) {
      return this.$stratus.dt(ts).format('l LT')
    },
    onExpand (expanded) {
      this.$emit('expand', expanded)
    }
  }
}
</script>
