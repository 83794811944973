/*
 * Defines and export default icons
 * Usage: <v-icon>$vuetify.icons.close</v-icon>
 */
import { INSTANCES_ICONS } from './widgets'

export default {
  ...INSTANCES_ICONS,
  alarms: 'icon-alarm-filled',
  monitor: 'icon-eye-filled',
  supervision: 'icon-supervision-filled',
  tickets: 'icon-ticket'
}
