<template>
  <v-row>
    <v-col>
      <v-slide-y-transition>
        <v-row no-gutters v-show="statusBarVisible">
          <v-col>
            <v-card outlined tile class="pa-2 ma-1">
              <infra-status />
            </v-card>
          </v-col>
        </v-row>
      </v-slide-y-transition>

      <v-slide-y-transition>
        <widgets-bar v-show="widgetsBarVisible && !fullscreen" @visibleChange="refreshDisplay" />
      </v-slide-y-transition>

      <v-row align="center" v-if="!visibleCount()" dense>
        <v-col cols="12">
          <v-card outlined tile class="mx-1">
            <v-card-title>
              <v-row class="fill-height" align="center" justify="start">
                <v-col>
                  <h3 class="text-h6">
                    {{ $t('Dashboard') }}
                  </h3>
                  <p class="mt-6">
                    {{ $t('Activate one or more widgets above...') }}
                  </p>
                </v-col>
              </v-row>
            </v-card-title>

            <v-list three-line>
              <v-list-item>
                <v-list-item-action>
                  <v-avatar :dark="dark" class="widget-background-supervision">
                    <v-icon small :dark="dark" class="widget-supervision">
                      icon-supervision-filled
                    </v-icon>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('Supervision') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('The Supervision widget gives you a grid that will display current alarms status for all your instances.') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-avatar :dark="dark" class="widget-background-alarms">
                    <v-icon small :dark="dark" class="widget-alarms">
                      icon-alarm-filled
                    </v-icon>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('Alarms') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('The Alarms widget gives you an up-to-date list of all alarms that your instances raised.') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-avatar :dark="dark" class="widget-background-tickets">
                    <v-icon small :dark="dark" class="widget-tickets">
                      $vuetif.icons.ticket
                    </v-icon>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('Tickets') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('The Tickets widget allow to keep an eye on all your opened tickets shared with our support team.') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-avatar :dark="dark" class="widget-background-monitor">
                    <v-icon small :dark="dark" class="widget-monitor">
                      icon-eye-filled
                    </v-icon>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('Monitor') }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t('The Monitor widget gives you an up-to-date detailed view of the resources that an instance is consuming.') }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-container class="mt-1 pa-0" fluid>
        <v-row no-gutters>
          <v-col v-show="widgetsConfig.supervision && widgetsConfig.supervision.visible && canIShow('supervision')" :cols="cols.default" :sm="cols.sm" :md="cols.md" :lg="cols.lg" :xl="cols.xl">
            <widget-supervision ref="widget_supervision" class="mx-1" />
          </v-col>
          <v-col v-show="widgetsConfig.alarms && widgetsConfig.alarms.visible && canIShow('alarms')" :cols="cols.default" :sm="cols.sm" :md="cols.md" :lg="cols.lg" :xl="cols.xl">
            <widget-alarms ref="widget_alarms" class="mx-1" />
          </v-col>
          <v-col v-show="widgetsConfig.tickets && widgetsConfig.tickets.visible && canIShow('tickets')" :cols="cols.default" :sm="cols.sm" :md="cols.md" :lg="cols.lg" :xl="cols.xl">
            <widget-tickets ref="widget_tickets" class="mx-1" />
          </v-col>
          <v-col v-show="widgetsConfig.monitor && widgetsConfig.monitor.visible && canIShow('monitor')" :cols="cols.default" :sm="cols.sm" :md="cols.md" :lg="cols.lg" :xl="cols.xl">
            <widget-monitor ref="widget_monitor" class="mx-1" />
          </v-col>
        </v-row>
      </v-container>

      <cs-supervision-dialog ref="supervision-instance-dialog" />
      <cs-instance-alert-setup ref="instance-alert-setup-alarms" />
    </v-col>
  </v-row>
</template>

<script>
import InstanceThresholdsSetup from '@/components/cs/InstanceThresholdsSetup'
import SupervisionDialog from '@/components/widgets/SupervisionDialog'

export default {
  components: {
    'cs-instance-alert-setup': InstanceThresholdsSetup, // Component with ref are tricky to async load, so I avoid doing this
    'cs-supervision-dialog': SupervisionDialog
  },
  data () {
    return {
      widgetsConfig: {}
    }
  },
  computed: {
    dark () {
      return this.$store.getters['$stratus-states/isDark']
    },
    fullscreen () {
      return this.$store.getters.fullscreen
    },
    statusBarVisible () {
      return this.$store.getters.statusBarVisible
    },
    widgetsBarVisible () {
      return this.$store.getters.widgetsBarVisible
    },
    nbActiveWidget () { return this.$store.getters.nbActiveWidget },
    cols () {
      const count = this.visibleCount()
      // Avoid infinite loop
      if (count !== this.nbActiveWidget) this.$store.commit('setNbActiveWidget', count)

      let result = { default: 12, sm: 12, md: 12, lg: 12, xl: 12 }
      if (this.$vuetify.breakpoint.mdAndUp) {
        switch (count) {
          case 2:
            result = { default: 12, sm: 12, md: 6, lg: 6, xl: 6 }
            break
          case 3:
            result = { default: 12, sm: 12, md: 6, lg: 4, xl: 4 }
            break
          case 4:
            result = { default: 12, sm: 6, md: 6, lg: 3, xl: 3 }
            break
          case 6:
            result = { default: 12, sm: 6, md: 4, lg: 2, xl: 2 }
            break
        }
      }
      return result
    }
  },
  methods: {
    canIShow (widgetName) {
      return this.$store.getters['$alto-roles/canIWidget'](widgetName)
    },
    refreshDisplay (widgetsConfig) {
      // This will render display again
      // Read https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      this.widgetsConfig = Object.assign({}, widgetsConfig)
    },
    visibleCount () {
      let _count = 0

      if (this.widgetsConfig) {
        Object.keys(this.widgetsConfig).forEach(widget => {
          _count += this.widgetsConfig[widget] && this.widgetsConfig[widget].visible && this.canIShow(widget) ? 1 : 0
        })
      }

      return _count
    }
  },
  async beforeCreate () {
    // Load user preferences
    console.log('Current profile is', this.$store.getters.dashboardProfile)
    try {
      this.widgetsConfig = await this.$store.dispatch('loadProfilePreferences', 'widgets') || {}
    } catch (error) {
      console.error(error)
      this.$stratus.services.notify.error(error)
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$t('Dashboard'))
  },
  mounted () {
    if (!this.$stratus.services.auth.isLogged()) {
      this.$stratus.services.auth.logout()
      this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
      window.location.reload()
      return
    }

    this.$root['supervision-instance-dialog'] = this.$refs['supervision-instance-dialog']

    this.refreshDisplay(this.widgetsConfig)

    if (!this.visibleCount()) {
      // If no widget is visible, then forced to show the widgets bar
      this.$store.commit('setWidgetBarVisible', true)
    }

    this.$root['instance-alert-setup-alarms'] = this.$refs['instance-alert-setup-alarms']
    // For the WidgetBar
    this.$root.widget_supervision = this.$refs.widget_supervision
    this.$root.widget_alarms = this.$refs.widget_alarms
    this.$root.widget_tickets = this.$refs.widget_tickets
    this.$root.widget_monitor = this.$refs.widget_monitor
  }
}
</script>
