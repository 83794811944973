/*
* Alerts, alarms, Notifications colors.
* Use it in color or class attribute.
* @example
* <span color="alert_ok_text alert_ok">Success!</span>
*/

// TODO: Use Prometheus alerts.
export const ALERTS_SCORES = {
  ok: 10,
  success: 10,
  information: 20,
  notice: 20,
  low: 30,
  warning: 40,
  moderate: 40,
  average: 40,
  error: 50,
  high: 50,
  unknown: 60,
  vm_off: 90,
  critical: 100,
  disaster: 100
}

export function getAlarmStateColor (state) {
  return ALERTS_COLORS['alert_' + state] || ALERTS_COLORS.alert_unknown
}

export function getAlarmStateClass (state) {
  return ALERTS_COLORS['alert_' + state] ? 'alert_' + state : ''
}

export function getTicketPriorityColor (priority) {
  switch (priority) {
    case '1': return ALERTS_COLORS.alert_critical
    case '2': return ALERTS_COLORS.alert_error
    case '3': return ALERTS_COLORS.alert_warning
    case '4': return ALERTS_COLORS.alert_vm_off
    case '5': return ALERTS_COLORS.alert_ok
    default: return ALERTS_COLORS.alert_unknown
  }
}

const ALERTS_COLORS = {
  alert_ok: '#6BDE81',
  alert_ok_text: '#ebf7ed',
  alert_success: '#ebf7ed',
  alert_success_text: '#6BDE81',
  alert_critical: '#EB5128',
  alert_critical_text: '#ffffff',
  alert_low: '#eeeeee',
  alert_low_text: '#000000',
  alert_information: '#C1CCF8',
  alert_information_text: '#141B32',
  alert_warning: '#FFC133',
  alert_warning_text: '#141B32',
  alert_error: '#EB5128',
  alert_error_text: '#141B32',
  alert_unknown: '#A5A5A5',
  alert_unknown_text: '#ffffff',
  alert_vm_off: '#546e7a'
}

export const ALERTS_STYLES = {
  alert_success: ALERTS_COLORS.alert_success,
  alert_success_text: ALERTS_COLORS.alert_success_text,
  alert_ok: ALERTS_COLORS.alert_success,
  alert_ok_text: ALERTS_COLORS.alert_success_text,
  alert_disaster: ALERTS_COLORS.alert_critical,
  alert_disaster_text: ALERTS_COLORS.alert_critical_text,
  alert_critical: ALERTS_COLORS.alert_critical,
  alert_critical_text: ALERTS_COLORS.alert_critical_text,
  alert_1: ALERTS_COLORS.alert_critical,
  alert_1_text: ALERTS_COLORS.alert_critical_text,
  alert_low: ALERTS_COLORS.alert_low,
  alert_low_text: ALERTS_COLORS.alert_low_text,
  alert_4: ALERTS_COLORS.alert_low,
  alert_4_text: ALERTS_COLORS.alert_low_text,
  alert_notice: ALERTS_COLORS.alert_information,
  alert_notice_text: ALERTS_COLORS.alert_information_text,
  alert_5: ALERTS_COLORS.alert_information,
  alert_5_text: ALERTS_COLORS.alert_information_text,
  alert_information: ALERTS_COLORS.alert_information,
  alert_information_text: ALERTS_COLORS.alert_information_text,
  alert_warning: ALERTS_COLORS.alert_warning,
  alert_warning_text: ALERTS_COLORS.alert_warning_text,
  alert_moderate: ALERTS_COLORS.alert_warning,
  alert_moderate_text: ALERTS_COLORS.alert_warning_text,
  alert_3: ALERTS_COLORS.alert_warning,
  alert_3_text: ALERTS_COLORS.alert_warning_text,
  alert_average: ALERTS_COLORS.alert_warning,
  alert_average_text: ALERTS_COLORS.alert_warning_text,
  alert_error: ALERTS_COLORS.alert_error,
  alert_error_text: ALERTS_COLORS.alert_error_text,
  alert_ko: ALERTS_COLORS.alert_error,
  alert_ko_text: ALERTS_COLORS.alert_error_text,
  alert_high: ALERTS_COLORS.alert_error,
  alert_high_text: ALERTS_COLORS.alert_error_text,
  alert_2: ALERTS_COLORS.alert_error,
  alert_2_text: ALERTS_COLORS.alert_error_text,
  alert_unknown: ALERTS_COLORS.alert_unknown,
  alert_unknown_text: ALERTS_COLORS.alert_unknown_text,
  alert_not_classified: ALERTS_COLORS.alert_unknown,
  alert_not_classified_text: ALERTS_COLORS.alert_unknown_text,
  alert_vm_off: ALERTS_COLORS.alert_vm_off
}

export default ALERTS_STYLES
