/*
* Widgets specific colors.
* Use it in color or class attribute.
* @example
* <span color="alert_ok_text alert_ok">Success!</span>
*/

export const graphColors = {
  background: {
    centreon: 'rgba(255, 198, 208, 0.1)',
    default: 'rgba(255, 225, 198, 0.1)',
    storage: 'rgba(247, 75, 111, 0.1)',
    cpu: 'rgba(255, 193, 51, 0.1)',
    ram: 'rgba(23, 225, 203, 0.1)'
  },
  border: {
    centreon: '#ffc6d0',
    default: '#ffe1c6',
    storage: '#f74b6f',
    cpu: '#ffc133',
    ram: '#17e1cb'
  }
}

export const chartColorPalette = [
  { html: '#ffc133', rgb: 'rgb(255, 193, 51)', opacity10: 'rgba(255, 193, 51, 0.1)' },
  { html: '#d90368', rgb: 'rgb(217, 3, 104)', opacity10: 'rgba(217, 3, 104, 0.1)' },
  { html: '#820263', rgb: 'rgb(130, 2, 99)', opacity10: 'rgba(130, 2, 99, 0.1)' },
  { html: '#c75146', rgb: 'rgb(199, 81, 70)', opacity10: 'rgba(199, 81, 70, 0.1)' },
  { html: '#192a51', rgb: 'rgb(25, 42, 81)', opacity10: 'rgba(25, 42, 81, 0.1)' },
  { html: '#3c0000', rgb: 'rgb(60, 0, 0)', opacity10: 'rgba(60, 0, 0, 0.1)' },
  { html: '#d3bcc0', rgb: 'rgb(211, 188, 192)', opacity10: 'rgba(211, 188, 192, 0.1)' },
  { html: '#cf4d6f', rgb: 'rgb(207, 77, 111)', opacity10: 'rgba(207, 77, 111, 0.1)' },
  { html: '#8d7dff', rgb: 'rgb(141, 125, 255)', opacity10: 'rgba(141, 125, 255, 0.1)' }
]

export function getInstanceStateColor (state) {
  return INSTANCES_COLORS[state] || INSTANCES_COLORS.instanceStateUnknown
}

export function getInstanceStateIcon (state) {
  switch ((state || '').toLowerCase()) {
    case 'on': return INSTANCES_ICONS.instanceStateOn
    case 'off': return INSTANCES_ICONS.instanceStateOff
    case 'stopped': return INSTANCES_ICONS.instanceStateStopped
    default: return INSTANCES_ICONS.instanceStateUnknown
  }
}

export const INSTANCES_ICONS = {
  instanceStateOff: 'icon-lightbulb',
  instanceStateOn: 'icon-lightbulb-filled',
  instanceStateStopped: 'icon-stop',
  instanceStateUnknown: 'icon-info'
}

export const INSTANCES_COLORS = {
  off: '#57b769',
  on: '#6BDE81',
  stopped: '#BFCDFC',
  unknown: '#aa9421',
  storage: '#4bc0c0',
  vcpu: '#ff6384',
  vram: '#F8C050',
  'metric-cpu': '#ff6384',
  'metric-ram': '#F8C050',
  'metric-disk': '#4bc0c0',
  vmOff: '#141B32'
}

export default {
  INSTANCES_COLORS,
  INSTANCES_ICONS,
  chartColorPalette,
  graphColors,
  getInstanceStateColor,
  getInstanceStateIcon
}
