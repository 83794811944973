<template>
  <div id="app" class="panel">
    <div>
      <img src="/img/brand-2024-light.svg">
      <p class="alert">
        {{ error.message }}
      </p>
      <p class="debug">
        Your browser is {{ error.browser.name }} {{ error.browser.version }} on {{ error.browser.os }} ({{ error.browser.userAgent }})
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {}
  },
  computed: {
    error () { return this.$error }
  }
}
</script>

<style scoped>
.panel {
  color: #14C6B2;
  width: 380;
  height: 280px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 8px;
}

p.alert {
  font-family: 'calibri', 'serif';
  font-size: 14px;
  color: #EB5128;
}

p.debug {
  font-family: 'courrier', 'monospace';
  font-size: 12px;
  color: #a39d9d;
}
</style>
