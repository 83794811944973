import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    thresholds: {}
  },
  getters: {
    thresholds: state => instanceId => {
      return state.thresholds[instanceId] || []
    }
  },
  mutations,
  actions
}
