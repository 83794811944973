import actions from '../monitor/actions'
import mutations from '../monitor/mutations'
import icons from '@/theme/icons'

export default {
  namespaced: true,
  state: {
    data: {
      items: null,
      count: 0
    },
    props: {
      name: 'MonitorSingle',
      icon: icons.monitor,
      singleton: false, // Allow multiple instances of this widget
      layout: ['grid'] // Restrict to layout columns only
    }
  },
  getters: {
    data: state => { return state.data },
    props: state => { return state.props }
  },
  mutations,
  actions
}
