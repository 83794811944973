import actions from './actions'
import mutations from './mutations'
import icons from '@/theme/icons'

export default {
  namespaced: true,
  state: {
    data: {
      items: null,
      count: 0
    },
    props: {
      name: 'Supervision',
      icon: icons.supervision,
      singleton: true,
      layout: ['columns', 'grid']
    }
  },
  getters: {
    data: state => {
      return state.data
    },
    props: state => {
      return state.props
    }
  },
  mutations,
  actions
}
