/* eslint-disable quote-props */
export default {
  'Visit <a href="{brand_website}" target="_sws">{brand} website</a> to stay aware about {brand} latest news and offers.': 'Visit <a href="{brand_website}" target="_sws">{brand} website</a> to stay aware about {brand} latest news and offers.', // «Visitez le <a href="{brand_website}" target="_sws">Site Web {brand}</a> pour les dernières actualités et offres.»
  'Manage your products, quotations and orders with <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.': 'Manage your products, quotations and orders with <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.', // «Gérer vos produits, devis et commandes avec <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.»
  'Scalair': 'Scalair', // «Scalair»
  'Scalair Dashboard': 'Scalair Dashboard', // «Scalair Dashboard»
  'Scalair Manager': 'Scalair Manager', // «Scalair Manager»
  'Administration': 'Administration', // «Administration»
  'To automate the transition to full screen, you can install a kiosk extension in your browser.': 'To automate the transition to full screen, you can install a kiosk extension in your browser.', // «Pour automatiser le passage en plein écran, vous pouvez installer une extension de type kiosk dans votre navigateur.»
  'Disconnecting...': 'Disconnecting...', // «Déconnexion…»
  'Error loading {name}: {error}': 'Error loading {name}: {error}', // «{name}, erreur au chargement : {error}»
  'Report': 'Report', // «Signaler»
  'Error: {error}': 'Error: {error}', // «Erreur : {error}»
  'Server error: {error}': 'Server error: {error}', // «Erreur du serveur : {error}»
  'Instance not found': 'Instance not found', // «Instance non trouvée !»
  'Instance not found.': 'Instance not found.', // «Instance non trouvée !»
  'Category: {category}': 'Category: {category}', // «Catégorie : {category}»
  'Issuer: {lastname} {firstname}\nEmail: {email} Company: {company}': 'Issuer: {lastname} {firstname}\nEmail: {email} Company: {company}', // «Émetteur : {lastname} {firstname} Email : {email} Société : {company}» 'Your password request has been sent. You should receive an email soon.': 'Your password request has been sent. You should receive an email soon.', // «Votre demande de mot de passe a été envoyée. Vous recevrez bientôt un mail.»
  'Important!': 'Important!', // «Important !»
  'Unknown': 'Unknown', // «Inconnu»
  'None': 'None', // «Aucun(e)»
  'Empty': 'Empty', // «Vide»
  'Light theme': 'Light theme', // «Thème clair»
  'Dark theme': 'Dark theme', // «Thème sombre»
  'Languages': 'Languages', // «Langages»
  'Home': 'Home', // «Accueil»
  'Reloading application...': 'Reloading application...', // «Rechargement de l'application…»
  'You must login to access your Scalair dashboard.': 'You must login to access your Scalair dashboard.', // «Connectez-vous pour accéder à votre tableau de bord Scalair.»
  'Can not connect to Scalair Dashboard!': 'Can not connect to Scalair Dashboard!', // «Impossible de se connecter au tableau de bord Scalair !»
  'Share a secret': 'Share a secret', // «Partager un secret»
  'This feature allows you to securely transmit information to an email. The recipient will receive two emails: one with the link for open the secret, a second with the password for unlock it. Once read, the secret will no longer be accessible.': 'This feature allows you to securely transmit information to an email. The recipient will receive two emails: one with the link for open the secret, a second with the password for unlock it. Once read, the secret will no longer be accessible.', // «Créer et partager un secret avec un utilisateur.»
  'Secret send to {email}.': 'Secret send to {email}.', // «Secret envoyé à {email}.»
  'Recipient email': 'Recipient email', // «Email du destinataire»
  'Reveal a secret': 'Reveal a secret', // «Révéler un secret»
  'Secret identifier': 'Secret identifier', // «Identifiant du secret»
  'Reveal': 'Reveal', // «Révéler»
  'Secret': 'Secret', // «Secret»
  'Secrets': 'Secrets', // «Secrets»
  'Please, enter the secret identifier, and password. Then click on «Reveal» button to display the content of the secret.': 'Please, enter the secret identifier, and password. Then click on «Reveal» button to display the content of the secret.', // «Saisissez l'identifiant et le mot de passe du secret. Puis cliquez sur le bouton « Révéler » pour en afficher le contenu.»
  'Please, enter the password for the secret and click on «Reveal» button to display the content of the secret.': 'Please, enter the password for the secret and click on «Reveal» button to display the content of the secret.', // «Saisissez le mot de passe du secret, puis cliquez sur le bouton « Révéler » pour en afficher le contenu.»
  'Secret as been read and is now erased.': 'Secret as been read and is now erased.', // «Le secret a été lu et est maintenant effacé.»
  'No secret for this id and password.': 'No secret for this id and password.', // «Aucun secret ne correspond à cet identifiant et ce mot de passe.»
  'Username': 'Username', // «Identifiant»
  'Users': 'Users', // «Utilisateurs»
  'User': 'User', // «Utilisateur»
  'Settings': 'Settings', // «Préférences»
  'Comments': 'Comments', // «Commentaires»
  '{count} comments': '{count} comments', // «Pas de commentaires|1 commentaire|{count} commentaires»
  'Connection: ': 'Connection: ', // «Connexion : »
  '{count} Items': '{count} Items', // «{count} éléments»
  'chart-time-tooltip-format': 'chart-time-tooltip-format', // «DD/MM/YYYY HH:mm»
  'chart-time-display-formats-minute': 'chart-time-display-formats-minute', // «h:mm a»
  '{percent}%': '{percent}%', // «{percent} %»
  'Average free space {avg}% on {count}{unit}.': 'Average free space {avg}% on {count}{unit}.', // «Espace libre moyen de {avg} % sur {count} {unit}.»
  'Priority': 'Priority', // «Priorité»
  'priority-critical': 'Critical', // «Critique»
  'priority-high': 'High', // «Élevée»
  'priority-moderate': 'Moderate', // «Modérée»
  'priority-low': 'Low', // «Basse»
  'priority-planning': 'Planning', // «Planifiée»
  'priority-1': 'Critical', // «Critique»
  'priority-2': 'High', // «Élevée»
  'priority-3': 'Moderate', // «Modérée»
  'priority-4': 'Low', // «Basse»
  'priority-5': 'Scheduled', // «Planifiée»
  'state-1': 'New', // «Nouveau»
  'state-2': 'In progress', // «En cours…»
  'state-3': 'On hold',
  'state-6': 'Resolved',
  'state-7': 'Closed',
  'state-8': 'Canceled',
  'Severity': 'Severity', // «Gravité»
  'severity-ok': 'OK', // OK»
  'severity-information': 'Information', // «Information»
  'severity-disaster': 'Disaster', // «Catastrophique»
  'severity-critical': 'Critical', // «Critique»
  'severity-high': 'High', // «Élevée»
  'severity-moderate': 'Moderate', // «Modérée»
  'severity-average': 'Average', // «Moyenne»
  'severity-warning': 'Warning', // «Avertissement»
  'severity-low': 'Low', // «Basse»
  'severity-planning': 'Scheduled', // «Planifiée»
  'severity-unknown': 'Unknown',
  'severity-not_classified': 'Not classified', // «Non classé»
  'vm': 'vm', // «Instance»
  'Instance': 'Instance', // «Instance»
  'No Instance.': 'No Instance.', // «Aucune instance.»
  'server': 'server', // «Serveur»
  'ec2': 'ec2', // «EC2»
  'State': 'State', // «État»
  'state-new': 'state-new', // «Nouveau»
  'state-in progress': 'state-in progress', // «En cours…»
  'Compute': 'Compute', // «Calcul»
  'Storage': 'Storage', // «Stockage»
  '«{drive}»': '«{drive}»', // «« {drive} »»
  'Storage|Storages': 'Storage|Storages', // «Stockage|Stockages»
  'Network': 'Network', // «Réseau»
  'VoIP': 'VoIP', // «VoIP»
  'Other': 'Other', // «Autre»
  'scalair-status-compute': 'Compute', // «Calcul»
  'scalair-status-storage': 'Storage', // «Stockage»
  'scalair-status-network': 'Network', // «Réseau»
  'scalair-status-voip': 'VoIP', // «VoIP»
  'scalair-status-other': 'Other', // «Autre»
  'and': 'and', // «et»
  'Back': 'Back', // «Retour»
  'Edit': 'Edit', // «Modifier»
  'Save': 'Save', // «Enregistrer»
  'Create': 'Create', // «Créer»
  'Submit': 'Submit', // «Enregistrer»
  'Created at': 'Created at', // «Créé le»
  'Updated at': 'Updated at', // «Modifié le»
  'Name': 'Name', // «Nom»
  'Title': 'Title', // «Titre»
  'Page Not Found.': 'Page Not Found.', // «Page non trouvée»
  'Demo user': 'Demo user', // «Utilisateur de démonstration»
  'Type': 'Type', // «Type»
  'Id': 'Id', // «Id»
  'Scalair account': 'Scalair account', // «Compte Scalair»
  'Label': 'Label', // «Intitulé»
  'Notes': 'Notes', // «Notes»
  'Saved!': 'Saved!', // «Enregistré !»
  'Last update:': 'Last update:', // «Mise à jour :»
  'Since {date}': 'Since {date}', // «Dernier changement le {date}»
  'Services Status': 'Services Status', // «Météo des services»
  'Toggle fullscreen': 'Toggle fullscreen', // «Activer/Désactiver le plein écran»
  'Toggle widgets bar': 'Toggle widgets bar', // «Afficher/cacher la barre des widgets»
  'Display/hide notifications': 'Display/hide notifications', // «Afficher/cacher les notifications»
  'Toggle Scalair services status bar': 'Toggle Scalair services status bar', // «Afficher/cacher la barre de statut des services Scalair»
  'Supervision': 'Supervision', // «Supervision»
  'Alarms': 'Alarms', // «Alarmes»
  'Tickets': 'Tickets', // «Tickets»
  'Hardware': 'Hardware', // «Matériel»
  'Monitor': 'Monitor', // «Suivi»
  'MonitorSingle': 'Instance', // «Suivi»
  'Activate one or more widgets above...': 'Activate one or more widgets above...', // «Activez un ou plusieurs widget ci-dessus…»
  'The Supervision widget gives you a grid that will display current alarms status for all your instances.': 'The Supervision widget gives you a grid that will display current alarms status for all your instances.', // «Le widget Supervision permet de visualiser l'état d'alarme de vos instances d'un coup d'œil.»
  'The Alarms widget gives you an up-to-date list of all alarms that your instances raised.': 'The Alarms widget gives you an up-to-date list of all alarms that your instances raised.', // «Le widget Alarmes affiche la liste de toutes les alarmes de vos instances en temps réel.»
  'The Tickets widget allow to keep an eye on all your opened tickets shared with our support team.': 'The Tickets widget allow to keep an eye on all your opened tickets shared with our support team.', // «Le widget Tickets montre tous vos tickets ouverts gérés par notre équipe Support.»
  'The Monitor widget gives you an up-to-date detailed view of the resources that an instance is consuming.': 'The Monitor widget gives you an up-to-date detailed view of the resources that an instance is consuming.', // «Le widget Suivi donne une vision détaillée de la consommation des ressources d'une instance.»
  'Hide supervision widget': 'Hide supervision widget', // «Cacher le widget de supervision»
  'Show supervision widget': 'Show supervision widget', // «Afficher le widget de supervision»
  'Hide': 'Hide', // «Cacher»
  'No data for {name}.': 'No data for {name}.', // «Pas de données pour {name}.»
  'Hide grid columns headers': 'Hide grid columns headers', // «Cacher les entêtes des colonnes»
  'Display dates in long format': 'Display dates in long format', // «Afficher les dates en format long»
  'Display tickets with recent customer comments first': 'Display tickets with recent customer comments first', // «Afficher en premier les tickets récemment commentés par les clients»
  'Customer': 'Customer', // «Société»
  'Ticket': 'Ticket', // «Ticket»
  'Assigned to': 'Assigned to', // «Assigné à»
  'Profiles': 'Profiles', // «Profils»
  'Choose a profile': 'Choose a profile', // «Choisir un profil»
  'Profile': 'Profile', // «Profil»
  'Profile: ': 'Profile: ', // «Profil : »
  'Profile name': 'Profile name', // «Nom du profil»
  'New profile': 'New profile', // «Nouveau profil»
  '1 profile | {count} profiles': '1 profile | {count} profiles', // «1 profil | {count} profils»
  'Default profile': 'Default profile', // «Profil par défaut»
  'Choose as default profile': 'Choose as default profile', // «Choisir comme profil par défaut»
  'Confirm deletion of «{item}»?': 'Confirm deletion of «{item}»?', // «Confirmez-vous l'effacement de « {item} » ?»
  'Address': 'Address', // «Adresse»
  'Host': 'Host', // «Hôte»
  'Service': 'Service', // «Service»
  'Message': 'Message', // «Message»
  'Use the widget menu to choose a service...': 'Use the widget menu to choose a service...', // «Utilisez le menu du widget pour activer des services…»
  'Hide powered off items': 'Hide powered off items', // «Cacher les éléments éteints»
  'Hide items without alarms': 'Hide items without alarms', // «Cacher les éléments sans alarmes»
  'Show VM Chart': 'Show VM Chart', // «Montrer le graphe des VM»
  'ON': 'ON', // «ALLUMÉE»
  'OFF': 'OFF', // «ÉTEINTE»
  'STOPPED': 'STOPPED', // «ARRÊTÉE»
  'Do you want to follow this instance ?': 'Do you want to follow this instance ?', // «Voulez-vous suivre cette instance ?»
  'vCPU {n}': 'vCPU {n}', // «vCPU {n}»
  'vRAM {n}GB': 'vRAM {n}GB', // «vRAM {n} GO»
  'vCPU': 'vCPU', // «vCPU»
  'No vCPU!|{count} vCPU': 'No vCPU!|{count} vCPU', // «Pas de vCPU !|{count} vCPU|{count} vCPU»
  'vRAM': 'vRAM', // «vRAM»
  'No vRAM!|{count} GB vRAM': 'No vRAM!|{count} GB vRAM', // «Pas de vRAM !|{count} Go vRAM»
  'Display graph only': 'Display graph only', // «Afficher le graphique seulement»
  'Display all metrics': 'Display all metrics', // «Afficher toutes les métriques»
  'Stop monitoring': 'Stop monitoring', // «Ne plus suivre»
  'No instance.|One instance.|{count} instances.': 'No instance.|One instance.|{count} instances.', // «Aucune instance.|Une instance.|{count} instances.»
  'Loading instances data...': 'Loading instances data...', // «Chargement des données d'instances…»
  'No storage for this instance.': 'No storage for this instance.', // «Pas de stockage pour cette instance.»
  'No history for this instance.': 'No history for this instance.', // «Pas d'historique pour cette instance.»
  'No history for this period.': 'No history for this period.', // «Pas d'historique pour cette période.»
  '1h': '1 hour', // «1 h»
  '6h': '6 hour', // «6 h»
  '1d': '1 day', // «1 jour»
  '1w': '1 week', // «1 sem»
  '1M': '1 month', // «1 mois»
  '1Q': '1 quarter', // «3 mois»
  'Percent Used': 'Percent Used', // «Pourcentage d'utilisation»
  '{percent}% used.': '{percent}% used.', // «{percent} % utilisé.»
  'Data update frequency': 'Data update frequency', // «Fréquence de mise à jour des données»
  'Click to hide/show graph for {name}': 'Click to hide/show graph for {name}', // «Cliquez pour afficher/cacher le graphique {name}»
  'Uptime since {date}': 'Uptime since {date}', // «En activité depuis {date}»
  '{count}{unit}': '{count}{unit}', // «{count} {unit}»
  'Alerts thresholds': 'Alerts thresholds', // «Seuils d'alertes»
  'Set alerts thresholds': 'Set alerts thresholds', // «Régler les seuils d'alertes»
  'Reset alerts thresholds': 'Reset alerts thresholds', // «Réinitialiser les seuils d'alertes»
  'Confirm the reset of all this thresholds?': 'Confirm the reset of all this thresholds?', // «Confirmez-vous la réinitialisation de tous ces seuils ?»
  'You can set the thresholds that trigger alerts at your convenience.': 'You can set the thresholds that trigger alerts at your convenience.', // «Vous pouvez régler à votre convenance les seuils qui déclenchent l'émission des alertes.»
  'vm_cpu_high_threshold_warning': 'Warning for CPU high', // «Avertissement de CPU élevée»
  'vm_cpu_high_threshold_critical': 'Warning for CPU critical', // «Avertissement de CPU critique»
  'vm_mem_high_threshold_warning': 'Warning for RAM high', // «Avertissement de mémoire élevée»
  'vm_mem_high_threshold_critical': 'Warning for RAM critical', // «Avertissement de mémoire critique»
  'vm_disk_high_threshold_warning': 'Warning for storage high', // «Avertissement de stockage élevé»
  'vm_disk_high_threshold_critical': 'Warning for storage critical', // «Avertissement de stockage critique»
  'Metrics saved for {name}.': 'Metrics saved for {name}.', // «Métriques enregistrées pour {name}.»
  'Add {name} to monitoring widget': 'Add {name} to monitoring widget', // «Ajouter {name} au widget «Suivi»»
  'Do you really want to stop following this instance ?': 'Do you really want to stop following this instance ?', // «Voulez-vous vraiment arrêter de suivre cette instance ?»
  'Remove all monitored instances': 'Remove all monitored instances', // «Arrêtez le suivi de toutes ces instances»
  'Do you really want to stop following all this instances ?': 'Do you really want to stop following all this instances ?', // «Voulez-vous vraiment arrêter de suivre toutes ces instances ?»
  '{name} is already followed!': '{name} is already followed!', // «{name} est déjà suivie !»
  'Instance {name} added successfully': 'Instance {name} added successfully', // «Instance {name} ajoutée au suivi»
  'Instance is no longer monitored': 'Instance is no longer monitored', // «L'instance n'est plus suivie.»
  'Add to monitoring': 'Add to monitoring', // «Ajouter au suivi»
  'Alerts setting': 'Alerts setting', // «Réglage des alertes»
  'Disable alerts for this metric': 'Disable alerts for this metric', // «Désactiver les alertes pour cette métrique»
  'Disabled': 'Disabled', // «Désactivé»
  'Warn {0}%, critical {1}%': 'Warn {0}%, critical {1}%', // «Avertissement {0} %, critique {1} %»
  'Measures': 'Measures', // «Métriques»
  'Loading thresholds...': 'Loading thresholds...', // «Chargement des seuils…»
  'Error loading thresholds!': 'Error loading thresholds!', // «Erreur au chargement des seuils !»
  'The new thresholds will be applied within 15 minutes.': 'The new thresholds will be applied within 15 minutes.', // «Les nouveaux seuils seront appliqués dans un délai de 15 minutes.»
  'No threshold setup for this instance {name}.': 'No threshold setup for this instance {name}.', // «Aucun seuil réglable pour l'instance {name}.»
  'Pick some metrics': 'Pick some metrics', // «Choisissez des métriques»
  'Choose one or more metrics to setup their warning and critical alerts threshold.': 'Choose one or more metrics to setup their warning and critical alerts threshold.', // «Choisissez une ou plusieurs métriques pour régler leurs seuils d'avertissement et d'alerte critique.»
  'Welcome to Scalair Dashboard': 'Welcome to Scalair Dashboard', // «Bienvenue dans le Tableau de bord Scalair»
  'This application allows you to monitor your instances and tickets.': 'This application allows you to monitor your instances and tickets.', // «Cette application vous permet de superviser vos instances et vos tickets.»
  'Follow resources consumption': 'Follow resources consumption', // «Suivre la consommation des ressources»
  'Prioritize your tickets': 'Prioritize your tickets', // «Prioriser vos tickets»
  'Be alerted when something goes wrong': 'Be alerted when something goes wrong', // «Être averti quand quelque chose déraille»
  'Keep in touch with our teams...': 'Keep in touch with our teams...', // «Rester en contact avec nos équipes…»
  'Your report as been submitted.': 'Your report as been submitted.', // «Votre rapport a été envoyé.»
  'on': 'on', // «allumé»
  'off': 'off', // «éteinte»
  'stopped': 'stopped', // «suspendue»
  'state_on': 'On', // «allumée»
  'state_off': 'Off', // «éteinte»
  'state_stopped': 'Stopped', // «suspendue»
  'state_unknown': 'Unknown state', // «état inconnu»
  'state_undefined': 'Undefined state', // «état inconnu»
  'state_suspended': 'Suspended', // «suspendue»
  'Start {name}...': 'Start {name}...', // «Démarrer {name}…»
  'Stop {name}...': 'Stop {name}...', // «Arrêter {name}…»
  'Force stop {name}...': 'Force stop {name}...', // «Forcer l'arrêt de {name}…»
  'Reboot {name}...': 'Reboot {name}...', // «Redémarrer {name}…»
  'Open console on {name}...': 'Open console on {name}...', // «Ouvrir la console de {name}…»
  'Start order send for {name}...': 'Start order send for {name}...', // «Démarrage demandée pour {name}…»
  'Stop order send for {name}...': 'Stop order send for {name}...', // «Arrêt demandé pour {name}…»
  'Reboot order send for {name}...': 'Reboot order send for {name}...', // «Redémarrage demandé pour {name}…»
  'Confirm the start of this instance.': 'Confirm the start of this instance.', // «Confirmez-vous le démarrage de cette instance ?»
  'Confirm the stop of this instance.': 'Confirm the stop of this instance.', // «Confirmez-vous l'arrêt de cette instance ?»
  'Confirm the reboot of this instance.': 'Confirm the reboot of this instance.', // «Confirmez-vous le redémarrage de cette instance ?»
  'Confirm the reboot of this instance. (As vmtools are not installed, a hard reboot well be performed)': 'Confirm the reboot of this instance. (As vmtools are not installed, a hard reboot well be performed)', // «Confirmez-vous le redémarrage de cette instance ? (Les outils vmtools ne sont pas installés, c'est un redémarrage à froid qui sera fait.)»
  'Service pack: {pack}': 'Service pack: {pack}', // «Pack de service : {pack}»
  'Thresholds saved for {name}.': 'Thresholds saved for {name}.', // «Seuils enregistrés pour {name}.»
  'The ticket should have at least one comment.': 'The ticket should have at least one comment.', // «Ce ticket devrait avoir au moins un commentaire.»
  'CPU consumption threshold to trigger alert': 'CPU consumption threshold to trigger alert', // «Seuil de déclenchement d'alerte de consommation de CPU»
  'RAM consumption threshold to trigger alert': 'RAM consumption threshold to trigger alert', // «Seuil de déclenchement d'alerte de consommation de RAM»
  'This is the value returned by the instance operating system.': 'This is the value returned by the instance operating system.', // «Cette valeur est celle que retourne le système d'exploitation.»
  'vmWare tools need date': 'vmWare tools need date', // «vmWare tools à mettre à jour.»
  'vmWare tools up-to-date': 'vmWare tools up-to-date', // «vmWare tools à jour.»
  'vm-start': 'Instance started', // «Instance {name} démarrée»
  'vm-stop': 'Instance stopped', // «Instance {name} arrêtée»
  'vm-reboot': 'Instance rebooted', // «Instance {name} redémarrée»
  'No alarm.': 'No alarm.', // «Aucune alarme.»
  'No ticket.': 'No ticket.', // «Aucun ticket.»
  '{label} use {value}{unit} at {date} (Raw: {raw}).': '{label} use {value}{unit} at {date} (Raw: {raw}).', // «{value} {unit} de {label} utilisé le {date}.»
  'Type of view:': 'Type of view:', // «Type de vue :»
  'Click to show or hide all items from {name}.': 'Click to show or hide all items from {name}.', // «Cliquer pour afficher ou cacher les éléments de {name}.»
  'Instantaneous state': 'Instantaneous state', // «Consommation instantanée»
  'Performance history': 'Performance history', // «Historique de consommation»
  'All': 'All', // «Tous»
  'Email': 'Email', // «Email»
  'Password': 'Password', // «Mot de passe»
  'New password': 'New password', // «Nouveau mot de passe»
  'Add': 'Add', // «Ajouter»
  'Confirm': 'Confirm', // «Confirmation»
  'Confirm deletion of item?': 'Confirm deletion of item?', // «Confirmez-vous la suppression de l'élément ?»
  'Update': 'Update', // «Modifier»
  'Delete': 'Delete', // «Supprimer»
  'Send': 'Send', // «Envoyer»
  'Details': 'Details', // «Détails»
  'Identifier': 'Identifier', // «Identifiant»
  'Tags': 'Tags', // «Étiquettes»
  'Show': 'Show', // «Afficher»
  'Rows per page': 'Rows per page', // «Lignes par page»
  'OS': 'OS', // «SE»
  'Operating system: {name}': 'Operating system: {name}', // «Système d\'exploitation: {name}»
  'VDC: {name}': 'Dedicated Cloud: {name}', // «Cloud dédié: {name}»
  'vm-mutu': 'Scalair Cloud', // «Cloud Scalair»
  'vm-vdc': 'Dedicated Cloud', // Cloud Dédié»
  'physical': 'Physical', // «Équipements»
  'Arrange the widgets in columns for this profile.': 'Arrange the widgets in columns for this profile.', // «Disposer les widgets en colonnes pour ce profil.»
  'Arrange the widgets freely for this profile.': 'Arrange the widgets freely for this profile.', // «Disposer les widgets librement pour ce profil.»
  'Force card view': 'Force card view', // «Afficher en carte»
  'Remove this widget': 'Remove this widget', // «Retirer ce widget»
  'Dense display': 'Dense display', // «Affichage dense»
  'Use the(+) button to place widgets on the dashboard.<br><br><b>Move a widget</b> by grabbing it by its title or an empty part and dropping it at the desired location.<br><br><b>Resize a widget</b> by grabbing its handle in the bottom right corner and releasing it when you reach the desired size.': 'Use the(+) button to place widgets on the dashboard.<br><br><b>Move a widget</b> by grabbing it by its title or an empty part and dropping it at the desired location.<br><br><b>Resize a widget</b> by grabbing its handle in the bottom right corner and releasing it when you reach the desired size.', // «Utilisez le bouton (+) pour placer les widgets sur le tableau de bord.<br><br><b>Déplacez un widget</b> en le saisissant par son titre ou une partie vide et en le déposant à l\'endroit souhaité.<br> <br><b> Redimensionnez un widget</b> en saisissant sa poignée dans le coin inférieur droit et en la relâchant lorsque vous atteignez la taille souhaitée.»
  'Visible columns': 'Visible columns', // «Colonnes visibles»
  'Backups': 'Backups', // «Sauvegardes»
  'No backup': 'No backup', // «Pas de sauvegarde»
  'Created at {date}': 'Created at {date}', // «Créé le {date}»
  'Expires on {date}': 'Expires on {date}', // «Expire le {date}»
  'BACKUP_7J': '7 days', // «7 jours»
  'BACKUP_7J_4S': '7d / 4weeks', // «7j. / 4sem.»
  'BACKUP_7J_4S_12M': '7d / 4w / 12 months', // «7j. / 4sem. / 12mois»
  'BACKUP_12H': '12 hours', // «12 heures»
  '7D': '7 days', // «7 jours»
  '7jours': '7 days', // «7 jours»
  '7D4W': '7d / 4weeks', // «7j. / 4sem.»
  '7jours4semaines': '7d / 4weeks', // «7j. / 4sem.»
  '7D4W12M': '7d / 4w / 12 months', // «7j. / 4sem. / 12mois»
  '7D4W12M-ADV': '7d / 4w / 12 months (adv)', // «7j. / 4sem. / 12mois»
  '7jours4semaines12mois': '7d / 4w / 12 months', // «7j. / 4sem. / 12mois»
  '7jours4semaines12mois3ans': '7d / 4w / 12 months', // «7j. / 4sem. / 12mois / 3ans»
  'Hide grid pagination controls': 'Hide grid pagination controls', // «Cacher les contrôles de pagination»
  'No instance|One instance|{count} instances': 'No instance|One instance|{count} instances', // «Aucune instance|Une instance|{count} instances»
  'user-role-bus': 'Business', // «Business»
  'user-role-tec': 'Technician', // «Technicien»
  'user-role-adm': 'Administrator', // «Administrateur»
  'user-role-acc': 'Accountant', // «Comptable»
  'user-role-prman': 'Project Manager', // «Chef de Projet»
  'user-role-spe': 'Spectator', // «Spectateur»
  'user-role-sup': 'Supervisor', // «Superviseur»
  'Last authentication: {date}': 'Last authentication: {date}', // «Dernière authentification le {date}»
  'Connect as...': 'Connect as...', // «Se connecter en tant que…»
  'Choose a user...': 'Choose a user...', // «Choisissez un utilisateur…»
  'Please choose the user you want to connect as. Only user accounts are shown, bot account are not allowed.': 'Please choose the user you want to connect as. Only user accounts are shown, bot account are not allowed.', // «Choisissez l\'utilisateur dont vous voulez emprunter le compte. Les comptes de type automate ne sont pas autorisés.»
  'Returning to your account.': 'Returning to your account.', // «Retour à votre compte.»
  'Error connecting as {name}!': 'Error connecting as {name}!', // «Erreur de connexion en tant que {name} !»
  'Error: cannot open console of {name}.': 'Error: cannot open console of {name}.', // «Erreur: impossible d\'ouvrir la console de {name}.»
  'No response from {name} console': 'No response from {name} console', // «Pas de réponse de la console de {name}»
  'Console of {name} is ready!': 'Console of {name} is ready!', // «La console de {name} est prête !»
  'Open': 'Open', // «Ouvrir»
  'Phone': 'Phone', // «Téléphone»
  'Firstname': 'Firstname', // «Prénom»
  'Cart': 'Cart', // «Devis»
  'Order': 'Order', // «Commande»
  'Document': 'Document', // «Document»
  'Lastname': 'Lastname', // «Nom de famille»
  'Managed': 'Managed',
  'Not managed': 'Not managed',
  '/s': '/s',
  'I-O/s': 'I-O/s',
  'Source: {source}': 'Source : {source}',
  'source-centreon': 'Operating System',
  'source-prometheus': 'Hypervisor',
  'Download as CSV': 'Download as CSV',
  'To open a console, your instance must be started.': 'To open a console, your instance must be started.',
  'Probes': 'Probes',
  'Probe': 'Probe',
  'Output': 'Output',
  'event-status-analysis': 'Analyzing...',
  'event-status-closed': 'Done',
  'event-status-done': 'Done',
  'event-status-identified': 'Outage cause identified',
  'event-status-in_progress': 'In progress...',
  'event-status-pause': 'Pause',
  'event-status-scheduled': 'Scheduled',
  'event-type-incident': 'Incident',
  'event-type-maintenance': 'Maintenance',
  'Caution! This shutdown will abruptly stop all running programs. It is equivalent to cutting the power.': 'Caution! This shutdown will abruptly stop all running programs. It is equivalent to cutting the power.',
  'Maximize': 'Maximize',
  'Reduce': 'Reduce',
  'Return to summary': 'Return to resume',
  'Show metrics': 'Show metrics',
  '{count} alarms': 'No alarm|1 alarm|{count} alarms',
  'LAN to LAN': 'LAN to LAN',
  'Name / Alias': 'Name / Alias',
  'Status change': 'Status change',
  'All link are up.': 'All link are up.',
  'Some link are not up.': 'Some link are not up.',
  'Automatic scale': 'Automatic scale'
}
