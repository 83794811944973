<template>
  <scalair-widget-responsive :title="$t(widgetProps.name)" :icon="widgetProps.icon" :count="count()" :cls="'widget-' + id + '--text widget-background-' + id" :id="id" :can-configure="Boolean(canConfigure)" :loading="isLoading">
    <v-row slot="content" no-gutters>
      <v-col cols="12">
        <cs-lan2lan-widget ref="supervision-lan-2-lan-widget" />
      </v-col>

      <v-col cols="12">
        <v-container class="pa-0 ma-0 mb-12" fluid v-if="Object.keys(providers).length">
          <v-row v-for="(services, provider) in providers" :key="provider">
            <v-col v-for="(service, serviceName) in services" :key="service.uid" cols="12">
              <cs-supervision-group :ref="`supervision-group-${provider}-${serviceName}`" :is-visible="activeProviders[service.uid]" :items="service.items" :id="service.uid" :provider="provider" :name="serviceName" :count="service.items.length" @toggleProviderVisible="toggleProviderVisible" />
            </v-col>
          </v-row>
        </v-container>

        <div v-else>
          <cs-error-panel v-if="error" v-model="error" />
          <p v-else class="text-center pa-6">
            {{ isLoading ? $t('Loading instances data...') : $t('Use the widget menu to choose a service...') }}
          </p>
        </div>
      </v-col>
    </v-row>
  </scalair-widget-responsive>
</template>

<script>
import _ from 'lodash'
import Lan2LanWidget from './Lan2Lan'
import SupervisionGroup from './SupervisionGroup'

const WIDGET_ID = 'supervision'
const REFRESH_INTERVAL = 15 * 60 * 1000 // Refresh widget data every 15 minutes
const LAN2LAN_REFRESH_DELAY = 5 // Minutes

export default {
  name: 'widget-' + WIDGET_ID,
  components: {
    'cs-lan2lan-widget': Lan2LanWidget,
    'cs-supervision-group': SupervisionGroup
  },
  data () {
    return {
      activeProviders: {},
      canConfigure: false, // Set this to false to disable configuration menu
      canShowExpansionPanel: false,
      error: '',
      id: WIDGET_ID,
      isLoading: true,
      menuVisible: false, // Internal use
      providers: {},
      values: {},
      widgetConfig: { visible: false, activeProviders: {} },
      widgetProps: this.$store.getters[`widgets/${WIDGET_ID}/props`]
    }
  },
  methods: {
    count () {
      const data = this.$store.getters[`widgets/${WIDGET_ID}/data`] || []
      return data.count
    },
    onChange () {
      this.savePreferences()
    },
    refreshConfig () {
      if (this.activeProviders) {
        this.widgetConfig.activeProviders = { ...this.activeProviders } // Clone object (shallow)
      }
    },
    refreshGroups () {
      _.forEach(this.providers, (services, provider) => {
        _.forEach(services, (service, serviceName) => {
          if (this.$refs[`supervision-group-${provider}-${serviceName}`] && this.$refs[`supervision-group-${provider}-${serviceName}`][0]) {
            this.$refs[`supervision-group-${provider}-${serviceName}`][0].refreshDrawing()
          }
        })
      })
    },
    refreshLan2LanWidget () {
      if (this.$refs['supervision-lan-2-lan-widget']) this.$refs['supervision-lan-2-lan-widget'].refresh()
      setTimeout(this.refreshLan2LanWidget, LAN2LAN_REFRESH_DELAY * 60 * 1000)
    },
    refreshWidgetData () {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch(`widgets/${WIDGET_ID}/getData`)
          .then(() => {
            const _data = this.$store.getters[`widgets/${WIDGET_ID}/data`]
            this.providers = _data.providers

            const _activeProviders = {}
            _.forEach(this.providers, (services, provider) => {
              _.forEach(services, service => {
                _activeProviders[service.uid] = this.widgetConfig.activeProviders[service.uid] || false
              })
            })
            this.activeProviders = { ..._activeProviders } // Clone object (shallow)

            resolve()
          })
          .catch(error => {
            this.error = this.$t(this.$stratus.services.format.getErrorMessage(error))
            console.error(error)
            reject(error)
          })
          .finally(() => {
            this.isLoading = false
          })
        setTimeout(this.refreshInstances, REFRESH_INTERVAL)
      })
    },
    refreshInstances () {
      if (!this.widgetConfig.visible) return
      this.refreshLan2LanWidget()
      this.refreshWidgetData()
        .then(() => {
          return this.$store.dispatch(`widgets/${WIDGET_ID}/getAllAlarms`)
        })
        .then(() => {
          const _data = this.$store.getters[`widgets/${WIDGET_ID}/data`]
          this.providers = { ..._data.providers }
          this.refreshGroups()
        })
        .catch(error => {
          this.$stratus.services.notify.warning(error)
        })
    },
    savePreferences () {
      this.widgetConfig.activeProviders = this.activeProviders
      this.widgetConfig.widgetType = WIDGET_ID
      this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: this.widgetConfig })
    },
    toggleProviderVisible (id) {
      this.activeProviders[id] = !this.activeProviders[id]
      this.savePreferences()
    }
  },
  created () {
    this.$store.dispatch('loadProfilePreferences', 'widgets')
      .then(result => {
        if (result) this.widgetConfig = Object.assign(this.widgetConfig, result[WIDGET_ID])
        setTimeout(this.refreshInstances, 1000)
      })
  }
}
</script>
