<template>
  <div v-if="data.length > 0" outlined class="pa-2 mb-4 transition" :class="dark ? ' theme--dark' : ''">
    <cs-expand-panel dense :expanded="expanded" block>
      <v-row slot="header" align="center" dense>
        <v-col class="shrink text-no-wrap">
          {{ $t('LAN to LAN') }}
        </v-col>

        <v-col class="shrink">
          <v-chip small>
            {{ data.length }}
          </v-chip>
        </v-col>

        <v-col class="text-right">
          <v-icon small :color="allSuccess ? STATUS_UP_COLOR : STATUS_WARN_COLOR">
            {{ allSuccess ? '$vuetify.icons.isOK' : '$vuetify.icons.warning' }}
          </v-icon>
          <span class="text-caption" :class="`${allSuccess ? STATUS_UP_COLOR : STATUS_WARN_COLOR}--text`">
            {{ allSuccess ? $t('All link are up.') : $t('Some link are not up.') }}
          </span>
        </v-col>
      </v-row>

      <v-simple-table slot="content" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t('Name / Alias') }}
              </th>
              <th class="text-center">
                {{ $t('State') }}
              </th>
              <th class="text-left">
                {{ $t('Information') }}
              </th>
              <th class="text-left">
                {{ $t('Status change') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(link, index) in sortedLan2Lan" :key="index">
              <td>
                {{ link.name || '-' }} /
                <span class="text-caption">
                  {{ link.alias || '-' }}
                </span>
              </td>
              <td class="text-center">
                <v-icon small :color="statusColor(link.status)">
                  {{ statusIcon(link) }}
                </v-icon>
                {{ link.status || $t('Unknown') }}
              </td>
              <td class="text-caption">
                {{ link.information }}
              </td>
              <td class="text-no-wrap">
                {{ $stratus.dt(link.lastStatusChange).format('l LT') }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </cs-expand-panel>
  </div>
</template>

<script>
import _ from 'lodash'

const WIDGET_ID = 'monitor'

const STATUS_UP = 'UP'
const STATUS_UP_COLOR = 'success'
const STATUS_DOWN = 'DOWN'
const STATUS_DOWN_COLOR = 'error'
const STATUS_WARN_COLOR = 'warning'

export default {
  name: 'Lan2Lan',
  props: {
    expanded: { type: Boolean, default: false }
  },
  data () {
    return {
      STATUS_DOWN,
      STATUS_DOWN_COLOR,
      STATUS_UP,
      STATUS_UP_COLOR,
      STATUS_WARN_COLOR,
      data: [],
      refreshing: false
    }
  },
  computed: {
    allSuccess () {
      return _.filter(this.data, { status: 'UP' }).length === this.data.length // All is UP -> True
    },
    dark () {
      return this.$store.getters['$stratus-states/isDark']
    },
    sortedLan2Lan () {
      return _.sortBy(this.data, l2l => { return l2l.name })
    }
  },
  methods: {
    async refresh () {
      this.refreshing = true
      try {
        const list = await this.$store.dispatch(`widgets/${WIDGET_ID}/getLan2Lan`)
        // Add a Date object
        this.data = [..._.forEach(list, l2l => {
          l2l.lastStatusChange = this.$stratus.dt(l2l.last_status_change)
        })]
      } catch (error) {
        console.error('[lan-2-lan-widget]', error)
      } finally {
        this.refreshing = false
      }
    },
    statusColor (status) {
      switch (status.toUpperCase()) {
        case STATUS_UP: return 'success'
        case STATUS_DOWN: return 'error'
        default: return ''
      }
    },
    statusIcon (link) {
      switch (link.status.toUpperCase()) {
        case STATUS_UP: return '$vuetify.icons.isOK'
        case STATUS_DOWN: return '$vuetify.icons.isKO'
        default: return '$vuetify.icons.unknown'
      }
    }
  }
}
</script>
