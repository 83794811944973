import actions from './actions'
import mutations from './mutations'
import icons from '@/theme/icons'

export default {
  namespaced: true,
  state: {
    data: {
      items: null,
      count: 0
    },
    props: {
      name: 'Alarms',
      icon: icons.alarms,
      singleton: true,
      layout: ['columns', 'grid']
    },
    error: null
  },
  getters: {
    data: state => {
      return state.data
    },
    dataHash: state => {
      // Return array of alarms for each instance name
      const result = {}
      if (state.data.items) {
        state.data.items.forEach(item => {
          if (!result[item.hostName]) {
            result[item.hostName] = []
          }
          result[item.hostName].push(item)
        })
      }
      return result
    },
    props: state => {
      return state.props
    },
    error: state => {
      return state.error
    }
  },
  mutations,
  actions
}
