<template>
  <v-card outlined tile class="background-blurred widget-wrapper-responsive" style="height: 100%; overflow: hidden;">
    <div class="d-flex align-center">
      <div class="text-h5 px-2">
        {{ title }}
      </div>
      <cs-icon-loading small v-if="loading" />
      <div v-else-if="count">
        <v-chip small :class="cls">
          {{ count }}
        </v-chip>
      </div>

      <div class="ml-auto">
        <slot name="extension" />
      </div>

      <v-menu v-if="canConfigure" offset-y :close-on-content-click="false" :nudge-width="200" v-model="menuVisible">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon small class="menu-icon--text">
              $vuetify.icons.options
            </v-icon>
          </v-btn>
        </template>
        <slot name="menu" />
      </v-menu>
    </div>

    <!-- <cs-color-divider :color="'color-divider widget-underline-' + id" /> -->
    <v-container class="pa-2" fluid>
      <slot name="content" />
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    canConfigure: { type: Boolean, default: false },
    cls: { type: String, default: '' },
    count: { type: Number, default: 0 },
    icon: { type: String, default: 'icon-kebab-menu' },
    id: { type: String, required: true },
    loading: { type: Boolean, default: false },
    title: { type: String, required: true }
  },
  data () {
    return {
      menuVisible: false
    }
  }
}
</script>
