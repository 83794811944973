import _ from 'lodash'
import { ALERTS_SCORES } from '@/theme/alerts'

function getWorstAlarm (alarms) {
  // Return the alarm with the most critical status
  let result
  let score = 0
  _.forEach(alarms, alarm => {
    if (ALERTS_SCORES[alarm.state] && score < ALERTS_SCORES[alarm.state]) {
      score = ALERTS_SCORES[alarm.state]
      result = alarm
    }
  })
  return result
}

export default {
  GET_DATA (state, { instances, alarms = {}, probes = {}, rootState }) {
    if (Array.isArray(instances.results)) {
      const isLord = !rootState.$stratus.services.localStorage.get('abdicate-lord', false) && (rootState['$stratus-states'].me || {}).is_lord

      const groupedAlarms = _.groupBy((alarms).results, 'hostName')
      const groupedProbes = _.groupBy((probes).results, 'hostName')
      const providerServices = {}
      const allInstances = []
      let prov

      _.forEach(instances.results, instance => {
        // Sanitize
        instance.name = _.trim(instance.name)
        instance.machineType = (instance.machineType || 'unknown').toLowerCase() // This will be translated
        instance.vdcName = (instance.vdcName || '') // Do not translate

        // Get alarms for this instance
        instance.alarms = _.chain(groupedAlarms[instance.name])
          .map(alarm => {
            alarm.state = (alarm.state || 'unknown').toLowerCase().replace(' ', '_')
            alarm.create_at = rootState.$stratus.dt(alarm.create_at)
            return alarm
          })
          .value()
        if (instance.alarms.length > 0) {
          const worstAlarm = getWorstAlarm(instance.alarms)
          instance.worstAlarm = {
            state: (worstAlarm.state || 'unknown').toLowerCase(),
            create_at: rootState.$stratus.dt(worstAlarm.create_at)
          }
        }

        // Get probes for this instance
        instance.probes = _.chain(groupedProbes[instance.name])
          .map(probe => {
            probe.state = (probe.state || 'unknown').toLowerCase().replace(' ', '_')
            probe.create_at = rootState.$stratus.dt(probe.create_at)
            probe.update_at = rootState.$stratus.dt(probe.update_at)
            return probe
          })
          .value()

        // Create provider if it does not already exists
        if (!providerServices[instance.provider]) {
          providerServices[instance.provider] = {}
        }

        // Group all vdc for lord
        if (isLord || !instance.vdcName.length) {
          prov = providerServices[instance.provider][instance.machineType]
        } else {
          // A «vdcName» is defined and we want it to be displayed in its own supervision group
          prov = providerServices[instance.provider][instance.vdcName]
        }
        // Create service in provider if it does not already exists
        if (!prov) {
          if (isLord || !instance.vdcName.length) {
            providerServices[instance.provider][instance.machineType] = {
              uid: instance.provider + '/' + instance.machineType, // Example: «aws/ec2», «vsphere/vm-mutu»
              items: []
            }
            prov = providerServices[instance.provider][instance.machineType]
          } else {
            providerServices[instance.provider][instance.vdcName] = {
              uid: instance.provider + '/' + instance.vdcName,
              items: []
            }
            prov = providerServices[instance.provider][instance.vdcName]
          }
        }

        // Push a clone and keep a ref for sorting by provider&service
        allInstances.push({ ...instance })
        prov.items.push(allInstances[allInstances.length - 1]) // Ref
      })

      state.data.items = allInstances
      state.data.count = allInstances.length
      state.data.providers = providerServices
    }
  },
  SET_ALARMS (state, { alarms, rootState }) {
    const allInstances = { ...state.data }
    if (Array.isArray(allInstances.items) && allInstances.items.length) {
      const groupedAlarms = _.groupBy((alarms).results, 'hostName')
      _.forEach(allInstances.items, instance => {
        // Get alarms for this instance
        instance.alarms = _.chain(groupedAlarms[instance.name])
          .map(alarm => {
            alarm.state = (alarm.state || 'unknown').toLowerCase().replace(' ', '_')
            alarm.create_at = rootState.$stratus.dt(alarm.create_at)
            return alarm
          })
          .value()
        if (instance.alarms.length > 0) {
          const worstAlarm = getWorstAlarm(instance.alarms)
          instance.worstAlarm = {
            state: (worstAlarm.state || 'unknown').toLowerCase(),
            create_at: rootState.$stratus.dt(worstAlarm.create_at)
          }
        }
      })

      state.data = { ...allInstances }
    }
  },
  SET_PROBES (state, { probes, rootState }) {
    const allInstances = { ...state.data }
    if (Array.isArray(allInstances.items) && allInstances.items.length) {
      const groupedProbes = _.groupBy((probes).results, 'hostName')
      _.forEach(allInstances.items, instance => {
        // Get probes for this instance
        instance.probes = _.chain(groupedProbes[instance.name])
          .map(probe => {
            probe.state = (probe.state || 'unknown').toLowerCase().replace(' ', '_')
            probe.create_at = rootState.$stratus.dt(probe.create_at)
            probe.update_at = rootState.$stratus.dt(probe.update_at)
            return probe
          })
          .value()
      })

      state.data = { ...allInstances }
    }
  },
  API_FAILURE: (state, error) => {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  }
}
