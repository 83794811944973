<template>
  <v-row no-gutters>
    <template v-for="widget in widgetsList">
      <v-col :key="widget" :class="widgetWidthClass" @click.capture="toggleVisible(widget)" v-show="canIShow(widgetsProps[widget].name)">
        <v-card v-if="widgetsList.length" tile outlined ripple :class="'pa-4 mx-1 my-1 widgets-bar background-blurred ' + (widgetsConfig[widget].visible ? 'widget-visible' : '')">
          <v-chip small :class="count(widget) >= 0 ? 'widget-background-' + widget + ' widget-' + widget + '--text' : 'transparent'">
            {{ count(widget) }}
          </v-chip>
          <v-card-text class="text-center" :class="widgetsConfig[widget].visible ? '' : ' opacity-60'">
            <v-avatar size="64" :dark="dark" :class="'widget-' + widget + ' widget-background-' + widget">
              <v-icon :class="'widget-' + widget + (widgetsConfig[widget].visible ? '' : ' opacity-60')">
                {{ widgetsProps[widget].icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
          <v-card-text class="text-center" :class="widgetsConfig[widget].visible ? '' : 'opacity-60'">
            <span class="text-h6">{{ $t(widgetsProps[widget].name) }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'WidgetsBar',
  data () {
    return {
      widgetsList: [],
      widgetsProps: {},
      widgetsConfig: {}
    }
  },
  computed: {
    dark () {
      return this.$store.getters['$stratus-states/isDark']
    },
    widgetWidthClass () {
      const count = this.visibleCount()
      if (this.$vuetify.breakpoint.mdAndUp) {
        switch (count) {
          case 0: return ''
          case 1: return 'cols="12"'
          case 2: return 'cols="12" md="6"'
          case 3: return 'cols="12" md="6" lg="4"'
          case 4: return 'cols="12" sm="6" lg="3"'
          case 6: return 'cols="12" sm="6" md="4" lg="2"'
          default: return ''
        }
      } else {
        return 'cols="12"'
      }
    }
  },
  methods: {
    canIShow (widgetName) {
      return this.$store.getters['$alto-roles/canIWidget'](widgetName)
    },
    count (widget) {
      let nbItems
      const data = this.$store.getters[`widgets/${widget}/data`] || []
      nbItems = data.pagination ? data.pagination.totalItems : (data.count ? data.count : (data.items ? data.items.length : data.length))
      if (widget === 'monitor' && this.$store.getters.MonitoredItems.length > 0) {
        nbItems = this.$store.getters.MonitoredItems.length
      }
      return nbItems
    },
    isAllowed (widget) {
      switch (widget) {
        case 'alarms':
          return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ALARMS, this.$alto.API_PERMISSIONS.LIST)
        case 'monitor':
          return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.INSTANCES, this.$alto.API_PERMISSIONS.LIST) && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.METRICS, this.$alto.API_PERMISSIONS.LIST)
        case 'supervision':
          return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.INSTANCES, this.$alto.API_PERMISSIONS.LIST)
        case 'tickets':
          return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS, this.$alto.API_PERMISSIONS.LIST)
      }

      return false
    },
    async loadWidgets () {
      const _widgetsList = []
      try {
        const result = await this.$store.dispatch('loadProfilePreferences', 'widgets')
        this.widgetsConfig = result || {}
        this.widgetsProps = this.$store.getters.widgetsProps

        // Create missing configurations
        this.$store.getters['widgets/list'].forEach(widget => {
          // Keep only widgets for our layout
          if (this.widgetsProps[widget].layout.includes('columns')) {
            if (this.isAllowed(widget)) {
              _widgetsList.push(widget)
              if (!this.widgetsConfig[widget]) {
                this.widgetsConfig[widget] = {
                  visible: false
                }
              }
            }
          }
        })
      } catch (error) {
        console.error('[dashboard]', error)
        setTimeout(() => { this.loadWidgets() }, 5000)
      } finally {
        // This will launch rendering of widget bar
        this.$nextTick(() => {
          this.widgetsList = _widgetsList
        })
      }
    },
    async toggleVisible (widget) {
      this.$set(this.widgetsConfig[widget], 'visible', !this.widgetsConfig[widget].visible)
      if (widget === 'monitor') {
        this.widgetsConfig[widget].MonitoredItems = this.$store.getters.MonitoredItems
      }
      if (widget === 'supervision' && this.widgetsConfig[widget].visible) {
        if (this.$root.widget_supervision) this.$root.widget_supervision.refreshWidgetData()
      }
      if (widget === 'alarms' && this.widgetsConfig[widget].visible) {
        if (this.$root.widget_alarms) this.$root.widget_alarms.refreshWidgetData(true)
      }
      if (widget === 'tickets' && this.widgetsConfig[widget].visible) {
        if (this.$root.widget_tickets) this.$root.widget_tickets.refreshWidgetData(true)
      }
      this.widgetsConfig[widget].widgetType = widget
      await this.$store.dispatch('saveWidgetConfig', { widgetId: widget, config: this.widgetsConfig[widget] })
      // Notify parent (the dashboard)
      this.$emit('visibleChange', this.widgetsConfig)
    },
    visibleCount () {
      let _count = 0

      if (this.widgetsConfig) {
        Object.keys(this.widgetsConfig).forEach(widget => {
          _count += /* this.widgetsConfig[widget] && this.widgetsConfig[widget].visible && */ this.canIShow(widget) ? 1 : 0
        })
      }

      return _count
    }
  },
  mounted () {
    // Load properties and configuration for all available widgets
    this.loadWidgets()
  }
}
</script>
