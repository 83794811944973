<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters align="center" class="transparent px-0">
        <v-col class="shrink">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" @click="toggleVisible(id)" icon>
                <v-icon :class="'menu-icon--text ' + (isVisible ? 'rotate-once-180' : '')">
                  $vuetif.icons.expand
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('Click to show or hide all items from {name}.', { name: provider + '/' + $t(name) }) }}</span>
          </v-tooltip>
        </v-col>

        <v-col class="grow">
          <v-row no-gutters align="center">
            <v-col class="shrink">
              <v-img :src="'/img/services/' + provider + '.png?1'" :width="18" />
            </v-col>
            <v-col>
              <span class="text-subtitle-1 pl-1">{{ provider | uppercase }}</span>
            </v-col>
            <v-col cols="12">
              <span class="text-caption">{{ $t(name) }}</span>

              <v-chip small link class="ml-1 widget-supervision--text widget-background-supervision" @click="toggleVisible(id)">
                <cs-icon-loading v-if="isDrawing" />
                <span v-else class="text-caption">{{ data.length }}</span>
              </v-chip>
            </v-col>
          </v-row>
        </v-col>

        <v-col v-if="isVisible" lg="3" class="text-right">
          <cs-search-input @search="onSearch" :loading="isSearching" :placeholder="$t('Find...')" />
        </v-col>

        <v-col class="shrink">
          <v-menu v-if="isVisible" offset-x :close-on-content-click="false" :nudge-width="200" v-model="menuVisible">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon>
                <v-icon small class="menu-icon--text">
                  $vuetify.icons.options
                </v-icon>
              </v-btn>
            </template>
            <v-card class="px-2" outlined tile>
              <v-switch dense inset flat v-model="config.hideItemsOff" color="accent" :label="$t('Hide powered off items')" class="ma-0 pt-6" />
              <v-switch dense inset flat v-model="config.hideItemsWithoutAlarms" color="accent" :label="$t('Hide items without alarms')" class="ma-0" />
              <v-btn link text rounded :loading="isDownloading" @click="downloadCSV">
                {{ $t('Download as CSV') }}
              </v-btn>
              <v-divider />
              <p class="mt-2">
                <span class="text-subtitle-1">{{ $t('Type of view:') }}</span>
                <v-btn small icon :class="widgetSize === 0 ? 'primary--text': 'menu-icon--text'" @click="widgetSize = 0">
                  <v-icon size="10">
                    icon-dashboard
                  </v-icon>
                </v-btn>
                <v-btn small icon :class="widgetSize === 1 ? 'primary--text': 'menu-icon--text'" @click="widgetSize = 1">
                  <v-icon size="15">
                    icon-dashboard
                  </v-icon>
                </v-btn>
                <v-btn small icon :class="widgetSize === 2 ? 'primary--text': 'menu-icon--text'" @click="widgetSize = 2">
                  <v-icon size="20">
                    icon-dashboard
                  </v-icon>
                </v-btn>
                <v-btn small icon :class="widgetSize === 10 ? 'primary--text': 'menu-icon--text'" @click="widgetSize = 10">
                  <v-icon size="20">
                    icon-list
                  </v-icon>
                </v-btn>
              </p>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row v-if="isVisible" justify="center" no-gutters>
        <v-col cols="12" class="pa-1">
          <div v-if="[0, 1, 2].indexOf(widgetSize) >= 0">
            <cs-supervision-canvas :items="data" :id="id" :widget-size="widgetSize" @drawing="onDrawing" ref="cs-supervision-canvas" />
          </div>
          <cs-supervision-list-view v-else :items="data" :id="id" :machine-type="name" @drawing="onDrawing" :config="widgetConfig" ref="cs-supervision-list-view" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'
import CsSupervisionCanvas from './SupervisionCanvas.vue'
import CsSupervisionListView from './SupervisionListView.vue'

const WIDGET_ID = 'supervision'
const WIDGET_MONITOR = 'monitor'

export default {
  name: 'SupervisionGroup',
  components: {
    'cs-supervision-canvas': CsSupervisionCanvas,
    'cs-supervision-list-view': CsSupervisionListView
  },
  props: {
    isVisible: { type: Boolean, required: true },
    items: { type: Array, required: true },
    count: { type: Number, required: true },
    name: { type: String, required: true },
    provider: { type: String, default: '' },
    id: { type: String, required: true }
  },
  data () {
    return {
      config: {
        hideItemsOff: false,
        hideItemsWithoutAlarms: false
      },
      menuVisible: false,
      menuX: 0,
      menuY: 0,
      clickedInstance: null,
      vmDialog: false,
      addVmToMonitor: false,
      resourceToFind: '',
      filteredData: this.items,
      widgetConfig: {},
      widgetSize: 1,
      isDownloading: false,
      isDrawing: false,
      isSearching: false
    }
  },
  watch: {
    'config.hideItemsOff': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isSearching = true
        this.refreshDrawing()
        setTimeout(() => {
          this.$stratus.services.localStorage.set('widget_supervision_hide_items_off_' + this.id, this.config.hideItemsOff)
          this.isSearching = false
        }, 500 + this.data.length)
      }
    },
    'config.hideItemsWithoutAlarms': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isSearching = true
        this.refreshDrawing()
        setTimeout(() => {
          this.$stratus.services.localStorage.set('widget_supervision_hide_items_without_alarms_' + this.id, this.config.hideItemsWithoutAlarms)
          this.isSearching = false
        }, 500 + this.data.length)
      }
    },
    widgetSize (newVal, oldVal) {
      this.isSearching = true
      setTimeout(() => {
        this.$stratus.services.localStorage.set('widget_supervision_grid_size_' + this.id, this.widgetSize)
        this.isSearching = false
      }, 500 + this.data.length)
    }
  },
  computed: {
    data () {
      let _items = this.items
      const self = this

      const getFilteredData = data => {
        // Return data filtered according to checked checkboxes
        return _.filter(data, item => {
          let term
          if (self.config.hideItemsOff && self.config.hideItemsWithoutAlarms) term = item.alarms && item.alarms.length > 0 && item.state !== 'off'
          else if (self.config.hideItemsOff && !self.config.hideItemsWithoutAlarms) term = item.state !== 'off'
          else if (self.config.hideItemsWithoutAlarms && !self.config.hideItemsOff) term = item.alarms && item.alarms.length > 0
          return term
        })
      }

      if (this.resourceToFind) {
        const text2Search4 = this.resourceToFind.toLowerCase()
        _items = this.items.filter(item => {
          return item.name.toLowerCase().includes(text2Search4) ||
          (item.customerName || '').toLowerCase().includes(text2Search4) ||
          (item.customerId || '').toLowerCase().includes(text2Search4) ||
          (item.OSType || '').toLowerCase().includes(text2Search4)
        })
        return _.sortBy(Object.values(this.config).indexOf(true) >= 0 ? getFilteredData(_items) : _items, ['name'])
      } else if (Object.values(this.config).indexOf(true) >= 0) _items = getFilteredData(_items)

      return _.sortBy(_items, ['name'])
    },
    getHeaderBackground () {
      return this.$store.getters['$stratus-states/isDark'] ? 'spv-service-title-dark-bg' : 'spv-service-title-light-bg'
    },
    instanceMenuVisible: {
      get () {
        return this.clickedInstance !== null
      },
      set (value) {
        this.clickedInstance = null
      }
    },
    serviceTitleLeftSideWidth () {
      return this.getServiceTitleWidth('xs12 sm5 md4')
    },
    serviceTitleRightSideWidth () {
      return this.getServiceTitleWidth('xs12 sm5 md5 offset-sm2 offset-md3')
    },
    searchInputClass () {
      return this.getServiceTitleWidth('xs11 sm11 md10 offset-md1')
    }
  },
  methods: {
    async downloadCSV () {
      // Keeping relevant data
      const data = _.map(this.data, item => {
        return {
          name: item.name,
          customerId: item.customerId,
          customerName: item.customerName,
          state: item.state,
          cpu: item.cpu,
          ram: item.ram,
          servicePack: item.servicePack,
          vCenterName: item.vCenterName,
          OSType: item.OSType,
          toolsOk: item.toolsOk,
          machineType: item.machineType,
          backups: item.backups ? item.backups.length : 0,
          alarms: item.alarms ? item.alarms.length : 0,
          worstAlarm: item.worstAlarm ? item.worstAlarm.state : ''
        }
      })
      try {
        this.isDownloading = true
        await this.$stratus.services.api.downloadProgress('/transform/jsontocsv', this.provider + '-' + this.name + '.csv', true, { data, format: 'csv' })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
      setTimeout(() => { this.isDownloading = false }, 3000)
    },
    getServiceTitleWidth (defaultClass) {
      let c
      const nbServices = this.$store.getters.nbActiveService
      const nbActiveWidget = this.$store.getters.nbActiveWidget
      if (nbActiveWidget > 1) {
        c = (nbActiveWidget === 2 && nbServices > 1) || (nbActiveWidget > 2 && nbServices === 1) ? 'xs12 sm12 md12' : 'xs12 sm12 md6'
        if (nbActiveWidget === 2 && nbServices === 1) {
          c = defaultClass
        }
      } else {
        c = nbServices === 1 ? defaultClass : 'xs12 sm12 md6'
      }
      return c
    },
    onDrawing (isDrawing) {
      this.isDrawing = isDrawing
    },
    onSearch (value) {
      this.isSearching = true
      this.resourceToFind = value // This will trigger the filtering by computed prop data()
      this.refreshDrawing()
      setTimeout(() => { this.isSearching = false }, 1000)
    },
    refreshDrawing () {
      if ([0, 1, 2].indexOf(this.widgetSize) >= 0) {
        if (this.$refs['cs-supervision-canvas']) this.$refs['cs-supervision-canvas'].refresh(this.widgetSize)
      }
    },
    toggleVisible (id) {
      // Call parent to update visible attribute for us. This is to avoid mutating a prop directly since the value will be overwritten whenever the parent component re-renders.
      this.$emit('toggleProviderVisible', id)
    }
  },
  created () {
    const size = this.$stratus.services.localStorage.get('widget_supervision_grid_size_' + this.id, 1)
    this.config.hideItemsOff = this.$stratus.services.localStorage.get('widget_supervision_hide_items_off_' + this.id, false)
    this.config.hideItemsWithoutAlarms = this.$stratus.services.localStorage.get('widget_supervision_hide_items_without_alarms_' + this.id, false)
    // Filter allowed view types:
    this.widgetSize = [0, 1, 2, 10].indexOf(size) >= 0 ? size : 1
  },
  mounted () {
    this.$store.dispatch('loadProfilePreferences', 'widgets')
      .then(result => {
        if (result) {
          this.widgetConfig = Object.assign(this.widgetConfig, result[WIDGET_ID])
          if (this.widgetConfig[WIDGET_MONITOR] && this.widgetConfig[WIDGET_MONITOR].MonitoredItems) {
            this.widgetConfig[WIDGET_MONITOR].MonitoredItems.forEach((vm) => {
              this.$store.commit('addMonitoredItems', vm)
            })
          }
        }
      })
  }
}
</script>
