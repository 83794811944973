export default (Vue) => {
  Vue.http.interceptors.push((req, next) => {
    next((response) => {
      return response
      // if (response && response.status === 0) {
      //   let count
      //   try {
      //     count = +(localStorage.getItem('retry-0') || '10') // 10 retry max
      //   } catch (error) {
      //     count = 10
      //   }
      //   if (count >= 1) {
      //     console.warn('[http] request refresh token', count)
      //     // Decrease retry count
      //     count--
      //     localStorage.setItem('retry-0', count)
      //     auth.refreshToken().then(() => {
      //       next((response) => {
      //         if (response && response.status < 300) {
      //           // Resend request that get a 0
      //           const method = req.method.toLowerCase()
      //           if (['get', 'head', 'delete', 'jsonp'].includes(method)) {
      //             return Vue.http[method](req.url, req)
      //           } else {
      //             // post, put, patch
      //             return Vue.http[method](req.url, req.body, req)
      //           }
      //         }
      //         return response
      //       })
      //     })
      //   } else {
      //     // Do not resend request, we've try 10 times before!
      //     localStorage.setItem('retry-0', 0)
      //     return response
      //   }
      // } else if (response && response.status === 401) {
      //   if (auth.isLogged()) {
      //     let count
      //     try {
      //       count = +(localStorage.getItem('retry-401') || '10') // 10 retry max
      //     } catch (error) {
      //       count = 10
      //     }
      //
      //     if (count >= 1) {
      //       console.warn('[http] request bis repetita', count)
      //       // Decrease retry count
      //       count--
      //       localStorage.setItem('retry-401', count)
      //       auth.refreshToken().then(() => {
      //         next((response) => {
      //           if (response && response.status < 300) {
      //             // Resend request that returns 401
      //             const method = req.method.toLowerCase()
      //             if (['get', 'head', 'delete', 'jsonp'].includes(method)) {
      //               return Vue.http[method](req.url, req)
      //             } else {
      //               // post, put, patch
      //               return Vue.http[method](req.url, req.body, req)
      //             }
      //           } return response
      //         })
      //       })
      //     } else {
      //       // Do not resend request, we've try 10 times before!
      //       localStorage.setItem('retry-401', 0)
      //       return response
      //     }
      //   } else {
      //     localStorage.removeItem('retry-401')
      //     localStorage.removeItem('retry-0')
      //     // Not logged in, go to home page
      //     console.warn('[http] session expired.')
      //     if (window.location.hash !== '#/') {
      //       localStorage.setItem('from-url', window.location.href)
      //       window.location.href = '/'
      //     }
      //   }
      // } else {
      //   localStorage.removeItem('retry-401')
      //   localStorage.removeItem('retry-0')
      //   return response
      // }
    })
  })

  console.log('HTTP interceptor installed')
}
