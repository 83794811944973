import _ from 'lodash'
import { graphColors, chartColorPalette } from '@/theme/widgets.js'

const GB_TO_BYTES = Math.pow(10, 9)

/*
* MANDATORY values for current (=more recent) metrics. Detect and add them.
*
* Disk metrics have the following format:
* metrics: {ram: {unit: "%", current: {time: "2019-01-11T09:27:24.000Z", value: 3.9978},…},…}
* +- disk: {C:\: {free: {unit: "%", current: {time: "2019-01-11T09:08:09.000Z", value: 72.0771},…},…},…}
*    +- C:\: {free: {unit: "%", current: {time: "2019-01-11T09:08:09.000Z", value: 72.0771},…},…}
*    +- D:\: {free: {unit: "%", current: {time: "2019-01-11T09:08:10.000Z", value: 34.1827},…},…}
*    +- W:\: {free: {unit: "%", current: {time: "2019-01-11T09:08:11.000Z", value: 36.6397},…},…}
*    +- Y:\: {free: {unit: "%", current: {time: "2019-01-11T09:08:12.000Z", value: 99.6471},…},…}
*/
export function getStorageCurrent (metrics) {
  const stats = {
    avgFree: 0.0,
    maxFree: 0.0,
    minFree: 0.0,
    totalSize: 0.0
  }
  const free = []
  const result = {}

  const drivesCount = Object.keys(metrics).length

  if (drivesCount > 0) {
    _.forEach(metrics, (metric, drive) => { // Iteration gives each drive/disk
      if (metric && metric.free && metric.capacity && metric.free.values && metric.free.values.length > 0) {
        result[drive] = {
          free: parseFloat(metric.free.values[metric.free.values.length - 1].value) * GB_TO_BYTES, // Bytes
          total: parseFloat(metric.capacity.values[metric.capacity.values.length - 1].value) * GB_TO_BYTES // Bytes
        }
        result[drive].used = 100 * (result[drive].total - result[drive].free) / result[drive].total // Percentage
        stats.totalSize += result[drive].total
        free.push(result[drive].free)
      }
    })

    stats.avgFree = _.sum(free) / drivesCount
    stats.maxFree = _.max(free)
    stats.minFree = _.min(free)
  }

  return { current: result, stats }
}

/*
* MANDATORY values for current (=more recent) metrics. Detect and add them.
*
* All non-disk metrics MUST have the same format:
* metrics: {ram: {unit: "%", current: {time: "2019-01-11T09:15:21.000Z", value: 25},…},…}
* +- ram: {unit: "%", current: {time: "2019-01-11T09:15:21.000Z", value: 25},…}
*     +- current: {time: "2019-01-11T09:15:21.000Z", value: 25}
*     +- values: [{time: "2019-01-11T09:15:21.000Z", value: 25},…]
*/
export function getDataCollection (stratus, metrics, appConfig, hideDataset) {
  function addMetricPeriod (period, graphName) {
    if (!period) return

    if (!periods[period]) {
      periods[period] = {
        value: parseInt(period),
        unit: period.replace(/\d/g, '').trim(),
        graphs: []
      }
    }
    if (periods[period].graphs.indexOf(graphName) < 0) periods[period].graphs.push(graphName)
  }

  // Contains charts by type of metric unit (percent, uptime, etc)
  const charts = {} // Datasets of point to draw
  const periods = {} // How often data are retrieved
  const refs = {} // Shortcut to datasets (to show/hide them)
  let metricKey = stratus.uuid() // One chart only for all Prometheus data
  let newData
  const dataCount = {}
  let datasetId = 0
  let colorIndex = 0

  _.forEach(metrics.prometheus, (metric, metricName) => { // Iteration gives metrics.<metric name>
    try {
      if (typeof metric === 'object' && (!appConfig.metrics || !appConfig.metrics[metricName] || !appConfig.metrics[metricName].ignore)) {
        // Prometheus graph are grouped by unit
        metricKey = 'prometheus-' + (metric.unit || '%')
        if (!charts[metricKey]) {
          charts[metricKey] = {
            source: 'prometheus',
            chartKey: metricKey,
            metricNames: [],
            unit: metric.unit,
            datasets: []
          }
        }
        charts[metricKey].metricNames.push(metricName)
        if (!dataCount[metricKey]) dataCount[metricKey] = 0 // empty

        if (metricName === 'disk') { // disk have a level that is drive name
          _.forEach(metric, (dMetric, drive) => {
            datasetId++

            // Refresh periodicity, example: "30m" for every 30 minutes
            addMetricPeriod(dMetric.free.delay, metricName)

            newData = []
            _.forEach(dMetric.free.values, (m, index) => {
              newData.push({
                x: stratus.dt(m.time).toDate(), // Javascript date
                y: 100 * (parseFloat(dMetric.capacity.values[index].value) - parseFloat(m.value)) / parseFloat(dMetric.capacity.values[index].value) // Value gives free space GB, we need used space percent
              })
            })
            // Add the drive
            refs[datasetId] = {
              chartKey: metricKey,
              dataset: {
                chartKey: metricKey,
                datasetId,
                label: drive,
                hidden: hideDataset[metricKey] && hideDataset[metricKey][datasetId] !== undefined ? hideDataset[metricKey][datasetId] : false,
                data: newData,
                lineTension: 0, // Straight lines
                fill: true,
                backgroundColor: graphColors.background.storage,
                borderColor: graphColors.border.storage
              }
            }
            charts[metricKey].datasets.push(refs[datasetId].dataset)
            dataCount[metricKey] += newData.length
          })
        } else {
          datasetId++

          newData = []
          // Refresh periodicity, example: "3m" for every 3 minutes
          addMetricPeriod(metric.delay, metricName)

          _.forEach(metric.values, m => {
            newData.push({
              x: stratus.dt(m.time).toDate(), // Javascript date
              y: parseFloat(m.value)
            })
          })
          // Add the metric (not a drive)
          refs[datasetId] = {
            chartKey: metricKey,
            dataset: {
              chartKey: metricKey,
              datasetId,
              label: metricName,
              hidden: hideDataset[metricKey] && hideDataset[metricKey][datasetId] !== undefined ? hideDataset[metricKey][datasetId] : false,
              data: newData,
              lineTension: 0, // Straight lines
              fill: true,
              backgroundColor: graphColors.background[metricName] || graphColors.background.default,
              borderColor: graphColors.border[metricName] || graphColors.border.default
            }
          }
          charts[metricKey].datasets.push(refs[datasetId].dataset)
          dataCount[metricKey] += newData.length
        }
      }
    } catch (e) {
      console.error(e)
    }
  })

  let currentUnit
  _.forEach(metrics.centreon, (metric, metricName) => { // Iteration gives metrics.<metric name>
    try {
      if (typeof metric === 'object' && (!appConfig.metrics || !appConfig.metrics[metricName] || !appConfig.metrics[metricName].ignore)) {
        metricKey = metricName // stratus.uuid() // One chart for each metric
        if (!charts[metricKey]) {
          charts[metricKey] = {
            source: 'centreon',
            chartKey: metricKey,
            metricNames: [],
            unit: null,
            extraUnits: [],
            datasets: []
          }
        }
        charts[metricKey].metricNames.push(metricName)
        if (!dataCount[metricKey]) dataCount[metricKey] = 0

        // Centreon group by metric name
        _.forEach(metric, (subMetric, subMetricKey) => {
          datasetId++
          const metricUnit = subMetric.unit || 'count'

          if (!charts[metricKey].unit || charts[metricKey].unit === metricUnit) {
            charts[metricKey].unit = metricUnit
          } else if (!charts[metricKey].extraUnits.includes(metricUnit)) {
            charts[metricKey].extraUnits.push(metricUnit)
          }
          currentUnit = metricUnit

          newData = []
          _.forEach(subMetric.values, (m, index) => {
            newData.push({
              x: stratus.dt(m.time).toDate(), // Javascript date
              y: subMetric.values[index].value
            })
          })

          // Add the drive
          refs[datasetId] = {
            chartKey: metricKey,
            unit: currentUnit,
            dataset: {
              yAxisID: 'y-axis-' + currentUnit,
              chartKey: metricKey,
              datasetId,
              label: subMetricKey.includes('(' + currentUnit + ')') ? subMetricKey : subMetricKey + ' (' + currentUnit + ')',
              hidden: hideDataset[metricKey] && hideDataset[metricKey][datasetId] !== undefined ? hideDataset[metricKey][datasetId] : false,
              data: newData,
              lineTension: 0, // Straight lines
              fill: true,
              backgroundColor: chartColorPalette[colorIndex].opacity10,
              borderColor: chartColorPalette[colorIndex].rgb
            }
          }
          colorIndex++
          if (colorIndex >= chartColorPalette.length) colorIndex = 0

          charts[metricKey].datasets.push(refs[datasetId].dataset)
          dataCount[metricKey] += newData.length
        })
      }
    } catch (e) {
      console.error(e)
    }
  })

  let total = 0
  _.forEach(dataCount, (value, key) => {
    total += value
    charts[key].isEmpty = value <= 0
  })

  return { graphs: charts, periods, refs, isEmpty: total <= 0 }
}

export function filterMetrics (metrics, data, appConfig) {
  const result = { ...data }
  _.forEach(metrics.prometheus, (metric, metricName) => {
    if (typeof metric === 'object' && (appConfig.metrics || appConfig.metrics[metricName] || appConfig.metrics[metricName].ignore)) {
      if (metricName === 'disk') {
        result.metrics[metricName] = {
          ...getStorageCurrent(metric)
        }
      } else {
        let current
        if (!metric) {
          console.warn('[metrics] no metrics returned for', metricName)
        } else if (!metric.values) {
          console.warn('[metrics] no values for metric', metricName, metric)
          let _a = []
          _.forEach(metric, m => {
            _a = _a.concat(m.values)
          })
          if (_a.length) {
            current = _a[_a.length - 1]
            result.metrics[metricName] = {
              current: {
                value: current.value,
                unit: metric.unit,
                time: current.time
              }
            }
          }
        } else {
          current = metric.values[metric.values.length - 1]
          if (current) {
            result.metrics[metricName] = {
              current: {
                value: current.value,
                unit: metric.unit,
                time: current.time
              }
            }
          }
        }
      }
    }
  })
  return result
}

export default {
  getDataCollection,
  getStorageCurrent
}
