<template>
  <v-card outlined tile class="widget-wrapper">
    <v-card-title class="pa-2">
      <span class="text-h6 pr-2">{{ title }}</span>
      <cs-icon-loading small v-if="loading" />
      <v-chip small :class="cls" v-else-if="count">
        {{ count }}
      </v-chip>

      <v-spacer />
      <slot name="extension" />

      <v-menu v-if="canConfigure" offset-y :close-on-content-click="false" :nudge-width="200" v-model="menuVisible">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon small class="menu-icon--text">
              $vuetify.icons.options
            </v-icon>
          </v-btn>
        </template>
        <slot name="menu" />
      </v-menu>
    </v-card-title>

    <cs-color-divider :color="'color-divider widget-underline-' + id" />
    <v-container class="pa-0" fluid>
      <slot name="content" />
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    canConfigure: { type: Boolean, default: false },
    cls: { type: String, default: '' },
    count: { type: Number, default: 0 },
    icon: { type: String, default: 'icon-kebab-menu' },
    id: { type: String, required: true },
    loading: { type: Boolean, default: false },
    title: { type: String, required: true }
  },
  data () {
    return {
      menuVisible: false
    }
  }
}
</script>
