<template>
  <v-dialog v-model="show" persistent max-width="640px">
    <v-card>
      <v-card-title class="text-h6 widget-supervision">
        {{ instance.name }}
        <v-spacer />
        <span class="text-subtitle-1">{{ instance.provider }} / {{ instance.customerName }}</span>
      </v-card-title>

      <v-card-text v-if="metricsList.length === 0">
        <v-progress-circular indeterminate small color="primary" /> {{ $t('Loading thresholds...') }}
      </v-card-text>

      <v-card-text v-else>
        <p class="text-caption">
          {{ $t('Choose one or more metrics to setup their warning and critical alerts threshold.') }}
        </p>
        <v-expansion-panels focusable popout flat>
          <v-expansion-panel v-for="(metric, index) in metricsList" :key="index" lazy>
            <v-expansion-panel-header>
              {{ metric.name }} — {{ metric.disabled ? $t('Disabled') : $t('Warn {0}%, critical {1}%', metric.values) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card>
                <v-card-text>
                  <v-checkbox :label="$t('Disable alerts for this metric')" v-model="metric.disabled" />
                  <cs-severity-slider :tag="metric.name" :values="metric.values" :title="$t('Alerts setting')" :disabled="metric.disabled" @update="updateValues" show-help />
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn class="main-button" @click.native="save()" :disabled="metricsList.length === 0">
          {{ $t('Save') }}
        </v-btn>
        <v-btn @click.native="cancel()">
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      instance: {},
      metricsList: [],
      show: false
    }
  },
  methods: {
    cancel () {
      this.resolve(false)
      this.show = false
    },
    load () {
      return this.$store.dispatch('instances/loadThresholds', { provider: this.instance.provider, id: this.instance.name })
    },
    open (instance) {
      this.show = true
      this.instance = instance
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        this.load()
          .then(thresholds => {
            this.metricsList = []
            this.values = {}
            this.$store.getters['instances/thresholds'](this.instance.id).forEach((t) => {
              // Get a copy of metric object to allow modifying it!
              this.metricsList.push(Object.assign({}, t))
            })

            if (this.metricsList.length) {
              resolve(true)
            }

            this.$stratus.services.notify.warning(this.$t('No threshold setup for this instance {name}.', { name: instance.name }))
            this.show = false
            resolve(false)
          })
          .catch(error => {
            this.show = false
            reject(error)
          })
      })
    },
    save () {
      this.$store.dispatch('instances/saveThresholds', {
        id: this.instance.id,
        provider: this.instance.provider,
        metrics: this.metricsList
      })
        .then(() => {
          this.$stratus.services.notify.success(this.$t('Metrics saved for {name}.', { name: this.instance.name }))
          this.show = false
        })
        .catch(error => this.$stratus.services.notify.error(error))
    },
    updateValues (sliderId, tag, event, values, handle, unencoded, tap, positions) {
      this.metricsList.forEach((m) => {
        if (m.name === tag) {
          m.values = values
        }
      })
    }
  }
}
</script>
