<template>
  <scalair-widget-responsive :title="$t(widgetProps.name)" :icon="widgetProps.icon" :count="totalItems" :cls="'widget-' + id + '--text widget-background-' + id" :id="id" :can-configure="canConfigure" :loading="loading">
    <v-card slot="menu" class="px-2" outlined tile>
      <v-switch dense inset flat v-model="widgetConfig.hideHeaders" :label="$t('Hide grid columns headers')" class="ma-0 pt-6" />
      <v-switch dense inset flat v-model="widgetConfig.longDateDisplay" :label="$t('Display dates in long format')" class="ma-0" />
      <v-switch dense inset flat v-model="widgetConfig.forceCardView" :label="$t('Force card view')" class="ma-0" />
      <v-switch dense inset flat v-model="widgetConfig.hidePagination" :label="$t('Hide grid pagination controls')" class="ma-0" />
      <v-select v-model="visibleColumns" :items="sortedHeaders" :label="$t('Visible columns')" @click:clear="resetVisibleColumns" chips multiple deletable-chips />
    </v-card>

    <v-row slot="content">
      <v-col>
        <cs-error-panel v-model="error" />

        <v-container class="ma-0 pa-4" fluid v-if="widgetConfig.forceCardView || $vuetify.breakpoint.mdAndDown || $store.getters.nbActiveWidget > 2">
          <v-data-iterator :items="items || []" must-sort :headers="headers" :hide-default-header="widgetConfig.hideHeaders" :loading="loading" :hide-default-footer="widgetConfig.hidePagination" :options.sync="options" :server-items-length="totalItems">
            <template v-slot:item="props">
              <v-row v-if="isColVisible('state')" dense>
                <v-col>
                  <v-chip label :class="`${getAlarmStateClass(props.item.state)} ${getAlarmStateClass(props.item.state)}_text--text`">
                    {{ $t('severity-' + props.item.state) }}
                  </v-chip>
                </v-col>
                <v-col class="text-right">
                  <cs-duration :format="widgetConfig.longDateDisplay ? '' : 'shorter'" :date="props.item.create_at" />
                </v-col>
              </v-row>

              <v-row v-if="isColVisible('hostName')" dense>
                <v-col v-if="!widgetConfig.hideHeaders" cols="3">
                  {{ $t('Host') }}
                </v-col>
                <v-col>
                  <span class="text-subtitle-1">{{ props.item.hostName }}</span>
                </v-col>
              </v-row>

              <v-row v-if="isColVisible('customerId')" dense>
                <v-col v-if="!widgetConfig.hideHeaders" cols="3">
                  {{ $t('Customer') }}
                </v-col>
                <v-col>
                  {{ props.item.customerId || $t('None') }}
                </v-col>
              </v-row>

              <v-row v-if="isColVisible('address')" dense>
                <v-col v-if="!widgetConfig.hideHeaders" cols="3">
                  {{ $t('Address') }}
                </v-col>
                <v-col>
                  {{ props.item.address || $t('None') }}
                </v-col>
              </v-row>

              <v-row v-if="isColVisible('output')" dense>
                <v-col v-if="!widgetConfig.hideHeaders" cols="3">
                  {{ $t('Message') }}
                </v-col>
                <v-col>
                  {{ props.item.output || $t('None') }}
                </v-col>
              </v-row>

              <v-divider class="ma-6" />
            </template>
            <template v-slot:no-data>
              <div class="pa-6 text-center">
                {{ $t('No alarm.') }}
              </div>
            </template>
          </v-data-iterator>
        </v-container>

        <v-data-table v-else :items="items || []" must-sort :headers="headers" :hide-default-header="widgetConfig.hideHeaders" :loading="loading" :hide-default-footer="widgetConfig.hidePagination" :options.sync="options" :server-items-length="totalItems">
          <v-progress-linear slot:progress color="accent" indeterminate />
          <template v-slot:item="{ item }">
            <tr :class="$store.getters.nbActiveWidget > 2 ? 'card' : ''">
              <td v-if="isColVisible('state')" :data-label="$t('Severity')" class="text-center">
                <v-chip label :class="`${getAlarmStateClass(item.state)} ${getAlarmStateClass(item.state)}_text--text`">
                  {{ $t('severity-' + item.state) }}
                </v-chip>
              </td>
              <td v-if="isColVisible('hostName')" :data-label="$t('Host')" class="text-wrap">
                {{ item.hostName }}
              </td>
              <td v-if="isColVisible('isManaged')" :data-label="$t('Managed')" class="text-center">
                <v-icon v-if="item.isManaged" color="success">
                  $vuetify.icons.managed
                </v-icon>
                <span v-else>—</span>
              </td>
              <td v-if="isColVisible('customerId')" :data-label="$t('Customer')">
                <sca-company-identity v-if="item.customerId" :value="item.customerId" no-code no-icon text-resume="12" />
              </td>
              <td v-if="isColVisible('output')" :data-label="$t('Message')">
                {{ $stratus.services.format.truncate(item.output, 80) }}
              </td>
              <td v-if="isColVisible('create_at')" :data-label="$t('Since')">
                <cs-duration v-if="item.create_at" :format="widgetConfig.longDateDisplay ? '' : 'shorter'" :date="item.create_at" />
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <div class="pa-6 text-center">
              {{ $t('No alarm.') }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </scalair-widget-responsive>
</template>

<script>
import _ from 'lodash'

import { getAlarmStateClass, getAlarmStateColor } from '@/theme/alerts'

const WIDGET_ID = 'alarms'
const REFRESH_INTERVAL = 60 * 1000 // Refresh widget data every minutes
const LOADING_FINISH_DELAY = 500
const DEFAULT_CONFIG = {
  widgetType: WIDGET_ID,
  hideHeaders: false,
  longDateDisplay: false,
  forceCardView: false
}

export default {
  name: 'widget-' + WIDGET_ID,
  data () {
    return {
      error: '',
      allHeaders: [
        { text: this.$t('Severity'), value: 'state' },
        { text: this.$t('Host'), value: 'hostName' },
        { text: this.$t('Managed'), value: 'isManaged' },
        { text: this.$t('Customer'), value: 'customerId' },
        { text: this.$t('Message'), value: 'output' },
        { text: this.$t('Since'), value: 'create_at' }
      ],
      getAlarmStateClass,
      getAlarmStateColor,
      id: WIDGET_ID,
      items: [],
      visibleColumns: [],
      widgetConfig: DEFAULT_CONFIG,
      widgetProps: this.$store.getters[`widgets/${WIDGET_ID}/props`],
      canConfigure: true, // Set this to false to disable configuration menu
      menuVisible: false, // Internal use
      refreshHandler: null,
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['create_at'],
        sortDesc: [true],
        mustSort: true
      },
      loading: true
    }
  },
  computed: {
    headers () {
      const result = []
      _.forEach(this.allHeaders, element => {
        if (this.visibleColumns.indexOf(element.value) >= 0) result.push(element)
      })
      return result.length ? result : this.allHeaders
    },
    sortedHeaders () {
      return this.$stratus.services.sort.natural(this.allHeaders, { text: 'asc' })
    }
  },
  watch: {
    options: {
      deep: true,
      handler () {
        if (!this.loading) this.getDataFromApi().then()
      }
    },
    visibleColumns: {
      handler (newValue) {
        this.widgetConfig.widgetType = WIDGET_ID
        // eslint-disable-next-line dot-notation
        this.widgetConfig['visibleColumns'] = newValue || []
        this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: this.widgetConfig })
      }
    },
    widgetConfig: {
      // Just a watcher to save configuration of the widget
      handler () {
        this.widgetConfig.widgetType = WIDGET_ID
        this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: this.widgetConfig })
      },
      deep: true
    }
  },
  methods: {
    isColVisible (column) {
      return this.visibleColumns.length === 0 || this.visibleColumns.indexOf(column) >= 0
    },
    getDataFromApi () {
      this.loading = true
      return new Promise((resolve, reject) => {
        const criteria = {
          limit: this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0,
          page: this.options.page
        }

        // Our API does not sort on multiple columns
        if (this.options.sortBy[0]) {
          criteria.sort = (this.options.sortDesc[0] ? '-' : '') + this.options.sortBy[0]
        }

        this.$store.dispatch(`widgets/${WIDGET_ID}/getData`, { criteria })
          .then(() => {
            const _data = this.$store.getters[`widgets/${WIDGET_ID}/data`]
            this.items = _data.items
            this.totalItems = _data.pagination.totalItems
            resolve()
          })
          .catch(error => {
            this.error = this.$t(this.$stratus.services.format.getErrorMessage(error))
          })
          .finally(() => {
            if (this.refreshHandler) {
              // Postpone next refresh
              clearTimeout(this.refreshHandler)
              this.refreshHandler = setTimeout(this.refreshWidgetData, REFRESH_INTERVAL)
            }
            setTimeout(() => { this.loading = false }, LOADING_FINISH_DELAY)
          })
      })
    },
    refreshWidgetData (forceRefresh) {
      if (this.widgetConfig && (this.widgetConfig.visible || forceRefresh)) {
        this.getDataFromApi().then(() => {
          this.refreshHandler = setTimeout(this.refreshWidgetData, REFRESH_INTERVAL)
        })
      }
    },
    resetVisibleColumns (config) {
      this.visibleColumns = []
      let _visibleColumns = config ? _.cloneDeep(config.visibleColumns) || [] : []
      if (_visibleColumns.length === 0) {
        // We do not hide all columns. When nothing is selected, then all columns are visible
        _visibleColumns = _.map(this.allHeaders, 'value')
      }
      this.visibleColumns = _visibleColumns
    }
  },
  mounted () {
    this.$store.dispatch('loadProfilePreferences', 'widgets')
      .then(result => {
        if (result) {
          this.widgetConfig = Object.assign(this.widgetConfig, result[WIDGET_ID])
          this.resetVisibleColumns(this.widgetConfig.visibleColumns)
        }
        this.$nextTick(this.refreshWidgetData)
      })
  }
}
</script>
