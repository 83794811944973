<template>
  <scalair-widget-responsive :title="$t(widgetProps.name)" :icon="widgetProps.icon" :count="totalItems" :cls="'widget-' + id + '--text widget-background-' + id" :id="id" :can-configure="canConfigure" :loading="loading">
    <v-card slot="menu" class="px-2" outlined tile>
      <v-switch dense inset flat v-model="widgetConfig.hideHeaders" :label="$t('Hide grid columns headers')" class="ma-0 pt-6" />
      <v-switch dense inset flat v-model="widgetConfig.longDateDisplay" :label="$t('Display dates in long format')" class="ma-0" />
      <v-switch dense inset flat v-model="widgetConfig.orderByComments" :label="$t('Display tickets with recent customer comments first')" class="ma-0" />
      <v-switch dense inset flat v-model="widgetConfig.forceCardView" :label="$t('Force card view')" class="ma-0" />
      <v-switch dense inset flat v-model="widgetConfig.hidePagination" :label="$t('Hide grid pagination controls')" class="ma-0" />
      <!-- <v-select v-model="visibleColumns" :items="sortedHeaders" :label="$t('Visible columns')" @click:clear="resetVisibleColumns" chips multiple /> -->
      <!--
      <v-divider class="mx-6" />
      <p class="mt-2">
        <v-icon left small class="menu-icon--text">
          icon-message
        </v-icon> <span class="text-caption">{{ $t('The ticket should have at least one comment.') }}</span>
      </p> -->
    </v-card>

    <v-row slot="content">
      <v-col>
        <cs-error-panel v-model="error" />

        <v-container class="ma-0 pa-4" fluid v-if="widgetConfig.forceCardView || $vuetify.breakpoint.mdAndDown || $store.getters.nbActiveWidget > 2">
          <v-data-iterator :items="items || []" :hide-default-footer="widgetConfig.hidePagination || widgetConfig.orderByComments" :loading="loading" :headers="headers" :hide-default-header="widgetConfig.hideHeaders" :options.sync="options" :server-items-length="totalItems">
            <template v-slot:item="props">
              <v-row dense>
                <v-col v-if="isColVisible('priority')">
                  <sca-ticket-severity :value="props.item.severity" show-label dense :label="props.item.severity <= 5 ? $t('priority-' + props.item.severity) : $t('Unknown')" />
                </v-col>
                <v-col v-if="isColVisible('update_at')" class="text-right">
                  <cs-duration :format="widgetConfig.longDateDisplay ? '' : 'shorter'" :date="props.item.update_at" />
                </v-col>
              </v-row>

              <v-row v-if="isColVisible('number')" dense>
                <v-col v-if="!widgetConfig.hideHeaders" cols="3">
                  {{ $t('Ticket') }}
                </v-col>
                <v-col>
                  {{ props.item.id }}
                </v-col>
              </v-row>

              <v-row v-if="isColVisible('customer_name')" dense>
                <v-col v-if="!widgetConfig.hideHeaders" cols="3">
                  {{ $t('Customer') }}
                </v-col>
                <v-col>
                  <sca-company-identity :value="props.item.code" />
                </v-col>
              </v-row>

              <v-row v-if="isColVisible('title')" dense>
                <v-col v-if="!widgetConfig.hideHeaders" cols="3">
                  {{ $t('Title') }}
                </v-col>
                <v-col>
                  {{ props.item.name }}
                </v-col>
              </v-row>

              <v-row v-if="isColVisible('assigned_to')" dense>
                <v-col v-if="!widgetConfig.hideHeaders" cols="3">
                  {{ $t('Assigned to') }}
                </v-col>
                <v-col>
                  <sca-user-identity :value="props.item.id_owner" />
                </v-col>
              </v-row>

              <v-row v-if="isColVisible('state')" dense>
                <v-col v-if="!widgetConfig.hideHeaders" cols="3">
                  {{ $t('State') }}
                </v-col>
                <v-col>
                  <sca-ticket-state :value="props.item.state" show-label />
                </v-col>
              </v-row>

              <v-divider class="ma-6" />
            </template>
            <template v-slot:no-data>
              <div class="text-h6 text-center">
                {{ $t('No ticket.') }}
              </div>
            </template>
          </v-data-iterator>
        </v-container>

        <v-data-table v-else :items="items || []" :hide-default-footer="widgetConfig.hidePagination || widgetConfig.orderByComments" :loading="loading" :headers="headers" :hide-default-header="widgetConfig.hideHeaders" :options.sync="options" :server-items-length="totalItems">
          <v-progress-linear slot:progress color="accent" indeterminate />
          <template v-slot:item="props">
            <tr :class="$store.getters.nbActiveWidget > 2 ? 'card' : ''">
              <td v-if="isColVisible('customer_name')" :data-label="$t('Customer')">
                <sca-company-identity :value="props.item.code" no-code no-icon text-resume="12" />
              </td>
              <td v-if="isColVisible('number')" :data-label="$t('Ticket')" class="text-center text-no-wrap text-font-fixed">
                {{ props.item.id }}
              </td>
              <td v-if="isColVisible('priority')" :data-label="$t('Priority')" class="text-center">
                <sca-ticket-severity :value="props.item.severity" show-label dense :label="props.item.severity <= 5 ? $t('priority-' + props.item.severity) : $t('Unknown')" />
              </td>
              <td v-if="isColVisible('state')" :data-label="$t('State')" class="text-center">
                <sca-ticket-state :value="props.item.state" show-label />
              </td>
              <td v-if="isColVisible('title')" :data-label="$t('Title')" class="text-wrap">
                {{ $stratus.services.format.truncate(props.item.name, 80) }}
              </td>
              <td v-if="isColVisible('assigned_to')" :data-label="$t('Assigned to')">
                <sca-user-identity :value="props.item.id_owner" />
              </td>
              <td v-if="isColVisible('update_at')" :data-label="$t('Since')" class="text-no-wrap">
                <cs-duration :format="widgetConfig.longDateDisplay ? '' : 'shorter'" :date="props.item.update_at" />
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <div class="text-h6 text-center">
              {{ $t('No ticket.') }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </scalair-widget-responsive>
</template>

<script>
import _ from 'lodash'

import { getTicketPriorityColor } from '@/theme/alerts'

const WIDGET_ID = 'tickets'
const REFRESH_INTERVAL = 5 * 60 * 1000 // Refresh widget data
const LOADING_FINISH_DELAY = 500
const DEFAULT_CONFIG = {
  widgetType: WIDGET_ID,
  hideHeaders: false,
  longDateDisplay: false,
  orderByComments: false, // Ticket with customer comments updated sooner than last hour will have higher priority
  forceCardView: false // Show v-data-table or v-data-iterator
}

export default {
  name: 'widget-' + WIDGET_ID,
  data () {
    return {
      allHeaders: [
        { text: this.$t('Customer'), value: 'customer_name' },
        { text: this.$t('Ticket'), value: 'number' },
        { text: this.$t('Priority'), value: 'priority' },
        { text: this.$t('State'), value: 'state' },
        { text: this.$t('Title'), value: 'title' },
        { text: this.$t('Assigned to'), value: 'assigned_to' },
        { text: this.$t('Since'), value: 'update_at' }
      ],
      error: '',
      getTicketPriorityColor,
      id: WIDGET_ID,
      isLoading: true,
      showCommentTooltip: {},
      items: [],
      visibleColumns: [],
      widgetProps: this.$store.getters[`widgets/${WIDGET_ID}/props`],
      widgetConfig: DEFAULT_CONFIG,
      canConfigure: true, // Set this to false to disable configuration menu
      menuVisible: false, // Internal use
      refreshHandler: null,
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['update_at'],
        sortDesc: [true],
        mustSort: true
      },
      loading: true
    }
  },
  computed: {
    dataTableClass () {
      return this.$store.getters.nbActiveWidget > 2 ? 'responsive-table' : ''
    },
    headers () {
      const result = []
      // _.forEach(this.allHeaders, element => {
      //   if (this.visibleColumns.indexOf(element.value) >= 0) result.push(element)
      // })
      return result.length ? result : this.allHeaders
    },
    sortedHeaders () {
      return this.$stratus.services.sort.natural(this.allHeaders, { text: 'asc' })
    }
  },
  watch: {
    'options.page' (val, oldVal) {
      if (!this.loading) this.getDataFromApi().then()
    },
    'options.itemsPerPage' (val, oldVal) {
      if (!this.loading) this.getDataFromApi().then()
    },
    'options.sortBy' (val, oldVal) {
      // Sorting change (by or desc)
      if (!this.loading) this.getDataFromApi().then()
    },
    visibleColumns: {
      handler (newValue) {
        this.widgetConfig.widgetType = WIDGET_ID
        // eslint-disable-next-line dot-notation
        this.widgetConfig['visibleColumns'] = newValue || []
        this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: this.widgetConfig })
      }
    },
    widgetConfig: {
      // Just a watcher to save configuration of the widget
      handler () {
        this.widgetConfig.widgetType = WIDGET_ID
        this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: this.widgetConfig })
        if (this.widgetConfig.orderByComments) {
          this.options.itemsPerPage = -1
        }
      },
      deep: true
    }
  },
  methods: {
    isColVisible (column) {
      return this.visibleColumns.length === 0 || this.visibleColumns.indexOf(column) >= 0
    },
    getDataFromApi () {
      this.loading = true
      return new Promise((resolve, reject) => {
        const criteria = {
          page: this.options.page
        }

        // Our API does not sort on multiple columns
        if (this.options.sortBy[0]) {
          criteria.sort = (this.options.sortDesc[0] ? '-' : '') + this.options.sortBy[0]
        }

        if (!this.widgetConfig.orderByComments && this.options.itemsPerPage > 0) {
          criteria.limit = this.options.itemsPerPage
        }

        criteria.fields = 'id,code,state,severity,name,id_owner,update_at,update_by,created_at'
        criteria.query = 'state[nin]=canceled,closed'

        this.$store.dispatch(`widgets/${WIDGET_ID}/getData`, { criteria, orderByComments: this.widgetConfig.orderByComments })
          .then(() => {
            const _data = this.$store.getters[`widgets/${WIDGET_ID}/data`]
            this.items = _data.items
            this.totalItems = _data.pagination.totalItems
            resolve()
          })
          .catch(error => {
            this.error = this.$t(this.$stratus.services.format.getErrorMessage(error))
          })
          .finally(() => {
            if (this.refreshHandler) {
              // Postpone next refresh
              clearTimeout(this.refreshHandler)
              this.refreshHandler = setTimeout(this.refreshWidgetData, REFRESH_INTERVAL)
            }
            setTimeout(() => { this.loading = false }, LOADING_FINISH_DELAY)
          })
      })
    },
    refreshWidgetData (forceRefresh) {
      if (this.widgetConfig && (this.widgetConfig.visible || forceRefresh)) {
        this.getDataFromApi().then(() => {
          this.refreshHandler = setTimeout(this.refreshWidgetData, REFRESH_INTERVAL)
        })
      }
    },
    resetVisibleColumns (config) {
      let _visibleColumns = config ? _.cloneDeep(config.visibleColumns) || [] : []
      if (_visibleColumns.length === 0) {
        // We do not hide all columns. When nothing is selected, then all columns are visible
        _visibleColumns = _.map(this.allHeaders, 'value')
      }
      this.visibleColumns = _visibleColumns
    }
  },
  mounted () {
    this.$store.dispatch('loadProfilePreferences', 'widgets')
      .then(result => {
        if (result) {
          this.widgetConfig = Object.assign(DEFAULT_CONFIG, this.widgetConfig, result[WIDGET_ID])
          this.resetVisibleColumns(this.widgetConfig.visibleColumns)
          if (this.widgetConfig.orderByComments) {
            this.options.itemsPerPage = -1
          }
        }
        this.$nextTick(this.refreshWidgetData)
      })
  }
}
</script>
