<template>
  <scalair-widget-responsive :title="$t(widgetProps.name)" :icon="widgetProps.icon" :count="monitoredItems ? monitoredItems.length : 0" :cls="'widget-' + id + '--text widget-background-' + id" :id="id" :can-configure="canConfigure" :loading="isLoading">
    <v-card outlined tile slot="menu">
      <v-row align="center" justify="center" class="pa-2 ma-0" no-gutters>
        <v-col>
          <v-toolbar flat dense>
            <v-autocomplete v-model="searchItem" :loading="isSearching" :items="searchItems" :filter="filterInstances" item-text="name" item-value="id" dense auto-select-first return-object cache-items hide-no-data hide-details :label="$t('Search...')" prepend-inner-icon="$vuetify.icons.search" clearable />
            <v-btn icon @click="addSearchToMonitor">
              <v-icon class="menu-icon--text">
                $vuetif.icons.add
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

      <v-row class="pa-2 ma-0" no-gutters>
        <v-col class="text-center">
          <v-switch dense inset flat v-model="widgetConfig.dense" :label="$t('Dense display')" class="ma-0" @change="saveConfig" />
          <v-btn rounded @click="removeAllWidgets()">
            <v-icon small class="menu-icon--text">
              $vuetify.icons.close
            </v-icon>
            {{ $t('Remove all monitored instances') }}
          </v-btn>
        </v-col>
      </v-row>
      <cs-confirm-dialog ref="monitor-confirm-dialog" />
    </v-card>

    <div slot="content" class="pa-0 ma-0">
      <cs-lan2lan-widget ref="lan-2-lan-widget" expanded />

      <v-row v-if="monitoredItems.length" no-gutters align="start" justify="start">
        <v-col v-for="(vm, index) in monitoredItems" :key="index" :class="monitorWidthClass">
          <cs-monitor-card v-show="!maximizedVM || vm.id === maximizedVM" :vm="vm" :dense-gauges="widgetConfig.dense" :cls="index > 0 ? 'card-border-left' : ''" allow-maximize can-stop-following @maximize="maximizeVM" @vmUnfollowed="stopFollowingVM" @saveMonitoredItems="saveMonitoredItems" :ref="'monitor-' + vm.id" />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="xs12 pa-6 text-center">
          {{ $tc('No instance.|One instance.|{count} instances.', 0, { count: 0 }) }}
        </v-col>
      </v-row>
    </div>
  </scalair-widget-responsive>
</template>

<script>
import _ from 'lodash'
import Lan2LanWidget from './Lan2Lan'
import MonitorCard from './MonitorCard'

const WIDGET_ID = 'monitor'
const SUPERVISION_WIDGET_ID = 'supervision'
const DEFAULT_CONFIG = {
  widgetType: WIDGET_ID,
  hideHeaders: false,
  showHidePieChart: false,
  dense: false
}
const LAN2LAN_REFRESH_DELAY = 5 // Minutes

export default {
  name: 'widget-' + WIDGET_ID,
  components: {
    'cs-lan2lan-widget': Lan2LanWidget,
    'cs-monitor-card': MonitorCard
  },
  data () {
    return {
      config: {},
      isLoading: true,
      menu: false,
      interval: {},
      id: WIDGET_ID,
      isSearching: false,
      searchItem: null,
      searchItems: [],
      widgetConfig: DEFAULT_CONFIG,
      canConfigure: true, // Set this to false to disable configuration menu
      menuVisible: false, // Internal use
      widgetProps: this.$store.getters[`widgets/${WIDGET_ID}/props`],
      maximizedVM: null,
      monitoredItems: []
    }
  },
  computed: {
    monitorWidthClass: function () {
      if (this.maximizedVM) return 'col-12'

      // Vuetify 2 use class as follow:
      // col-N : col-12 is cols="12"
      // col-breakpoint-N : col-md-6 is for md="6"
      let result = ''
      // Detect width available to dispatch instances panels in widget content area
      if (this.$vuetify.breakpoint.lgAndUp) result = 'col-lg-'
      else if (this.$vuetify.breakpoint.mdAndUp) result = 'col-md-'
      else if (this.$vuetify.breakpoint.smAndUp) result = 'col-sm-'

      // Now try to fill a row. If size is too small, put less instances in a row
      if (result) {
        if (this.$store.getters.nbActiveWidget === 1) {
          switch (this.monitoredItems.length) {
            case 1: result += '12'; break
            case 2: result += '6'; break
            case 3: result += (result === 'col-sm-') ? '6' : '4'; break
            default: result += (result === 'col-sm-') ? '6' : (result === 'col-md-' ? '4' : '3'); break
          }
        } else { // This widget is not the only one to display, we've got less space
          switch (this.monitoredItems.length) {
            case 1: result += '12'; break
            case 2: result += '12'; break
            case 3: result += (result === 'col-sm-') ? '12' : '6'; break
            default: result += (result === 'col-sm-') ? '12' : '6'; break
          }
        }
      }
      return 'col-12 ' + result
    }
  },
  methods: {
    addSearchToMonitor () {
      if (this.searchItem) this.addInstanceToMonitor(this.searchItem)
    },
    addInstanceToMonitor (itemToFollow) {
      // Check if it is already monitored
      if (this.$store.getters.existsMonitoredItems(itemToFollow.id)) {
        this.$stratus.services.notify.warning(this.$t('{name} is already followed!', { name: itemToFollow.name }))
        return
      }
      // Save VM list
      this.addingVmToMonitor = true
      this.$store.dispatch('loadProfilePreferences', 'widgets')
        .then(result => {
          const instanceToSave = {
            id: itemToFollow.id,
            name: itemToFollow.name,
            provider: itemToFollow.provider,
            // isConfigAble: itemToFollow.isConfigAble,
            machineType: itemToFollow.machineType
          }
          instanceToSave.showChart = false // Initialize on current item before saving
          instanceToSave.showStorage = [true] // Initialize on current item before saving
          this.$store.commit('addMonitoredItems', instanceToSave)
          const monitorWidget = (result || {})[WIDGET_ID] || DEFAULT_CONFIG
          monitorWidget.widgetType = WIDGET_ID
          monitorWidget.MonitoredItems = this.$store.getters.MonitoredItems
          this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: monitorWidget })
            .then(() => {
              this.refreshWidgetData([itemToFollow.id])
              this.$stratus.services.notify.success(this.$t('Instance {name} added successfully', { name: instanceToSave.name }))
              this.addingVmToMonitor = false
            })
            .catch(error => {
              console.error(error)
              this.$stratus.services.notify.error(error)
              this.addingVmToMonitor = false
            })
        })
    },
    getMonitoredItems () {
      return _.sortBy(this.$store.getters.MonitoredItems || [], vm => { return vm.name.toUpperCase() })
    },
    filterInstances (item, queryText, itemText) {
      return (itemText || '').toLowerCase().indexOf(queryText.trim().toLocaleLowerCase()) >= 0
    },
    async loadSearchItems () {
      this.isSearching = true
      let _items = this.$store.getters[`widgets/${SUPERVISION_WIDGET_ID}/data`]
      if (!_items || !_items.count) {
        await this.$store.dispatch(`widgets/${SUPERVISION_WIDGET_ID}/getData`)
        _items = this.$store.getters[`widgets/${SUPERVISION_WIDGET_ID}/data`] || []
      }
      this.searchItems = this.$stratus.services.sort.natural(_items.items, { name: 'asc' })
      this.isSearching = false
    },
    maximizeVM ({ id, maximize }) {
      if (maximize) this.maximizedVM = id
      else this.maximizedVM = null
    },
    saveConfig () {
      this.widgetConfig.widgetType = WIDGET_ID
      this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: this.widgetConfig })
      this.menuVisible = false
    },
    saveMonitoredItems (item, refresh) {
      this.$store.dispatch('loadProfilePreferences', 'widgets')
        .then(result => {
          if (result) {
            const widgetConfig = result[WIDGET_ID]
            const vm = this.$store.getters.MonitoredItems.find(o => o.id === item.id)
            if (vm) {
              vm.page = item.page || 'gauges'
              vm.delay = item.delay || '1h'
              vm.showChart = item.showChart || false
              vm.showStorage = item.showStorage || [true]
              vm.hideGraphs = item.hideGraphs || {}
              vm.hideDatasets = item.hideDatasets || {}
              widgetConfig.MonitoredItems = this.$store.getters.MonitoredItems
              widgetConfig.widgetType = WIDGET_ID
              this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: widgetConfig })
              if (refresh) {
                this.refreshWidgetData()
              }
            }
          }
        })
    },
    refreshLan2LanWidget () {
      if (this.$refs['lan-2-lan-widget']) this.$refs['lan-2-lan-widget'].refresh()
      setTimeout(this.refreshLan2LanWidget, LAN2LAN_REFRESH_DELAY * 60 * 1000)
    },
    async refreshMonitoredItem () {
      _.forEach(this.monitoredItems, async (vm) => {
        // Ask each instance monitor to refresh itself
        if (this.$refs['monitor-' + vm.id] && this.$refs['monitor-' + vm.id][0]) {
          try {
            await this.$refs['monitor-' + vm.id][0].refresh()
          } catch (e) {
            console.error(e)
          }
        } else {
          console.warn('[Monitor] refreshMonitoredItem No $ref for', vm.id)
          console.warn('[Monitor] refreshMonitoredItem', this.$refs['monitor-' + vm.id])
        }
      })
      setTimeout(this.refreshMonitoredItem, this.$store.getters[`widgets/${WIDGET_ID}/REFRESH_INTERVAL`] * 60 * 1000)
    },
    refreshWidgetData () {
      this.isLoading = true
      this.$store.dispatch('loadProfilePreferences', 'widgets')
        .then(result => {
          this.refreshLan2LanWidget()
          this.monitoredItems = []
          if (result && result[WIDGET_ID] && result[WIDGET_ID].MonitoredItems) {
            result[WIDGET_ID].MonitoredItems.forEach((vm) => {
              // Convert old provider and machine type
              if (vm.provider === 'vsphere') vm.provider = this.$store.getters.appConfig.cloud.providers.internals[0]
              if (vm.machineType === 'vm') vm.machineType = this.$store.getters.appConfig.cloud.virtualVM
              if (vm.machineType === 'server') vm.machineType = this.$store.getters.appConfig.cloud.physicals[0]
              this.$store.commit('addMonitoredItems', vm)
            })
            setTimeout(() => {
              this.refreshMonitoredItem()
            }, 1000)
          }
        })
        .finally(() => {
          this.monitoredItems = this.getMonitoredItems()
          this.isLoading = false
        })
    },
    removeAllWidgets () {
      this.$refs['monitor-confirm-dialog'].open(this.$t('Monitor'), this.$t('Do you really want to stop following all this instances ?'), { color: 'danger' }).then(async (confirm) => {
        if (confirm) {
          try {
            const result = await this.$store.dispatch('loadProfilePreferences', 'widgets')
            if (result) {
              this.$store.commit('delMonitoredItemsAll')
              const widgetConfig = result[WIDGET_ID]
              widgetConfig.MonitoredItems = this.$store.getters.MonitoredItems
              widgetConfig.widgetType = WIDGET_ID
              await this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: widgetConfig })
              this.$stratus.services.notify.success(this.$t('Instance is no longer monitored'), { duration: 2000 })
              this.refreshWidgetData()
            }
          } catch (error) {
            console.error('[removeAllWidgets]', error)
            this.$stratus.services.notify.error(error)
          }
        }
      })
    },
    stopFollowingVM (vmId) {
      this.$store.dispatch('loadProfilePreferences', 'widgets').then(result => {
        if (result) {
          const widgetConfig = result[WIDGET_ID]
          this.$store.commit('delMonitoredItems', vmId)
          widgetConfig.MonitoredItems = this.$store.getters.MonitoredItems
          widgetConfig.widgetType = WIDGET_ID
          this.$store.dispatch('saveWidgetConfig', { widgetId: WIDGET_ID, config: widgetConfig })
            .then(() => {
              _.remove(this.monitoredItems, vm => { return vm.id === vmId })
              this.$stratus.services.notify.success(this.$t('Instance is no longer monitored'), { duration: 2000 })
              this.refreshWidgetData()
            })
        }
      })
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  created () {
    this.loadSearchItems()
  },
  async mounted () {
    this.widgetConfig.showHidePieChart = this.$store.getters.showPieChart
    try {
      const result = await this.$store.dispatch('loadProfilePreferences', 'widgets')
      if (result) {
        this.widgetConfig = Object.assign(DEFAULT_CONFIG, this.widgetConfig, result[WIDGET_ID])
      }
      this.$nextTick(this.refreshWidgetData)
    } catch (error) {
      console.error(error)
      this.$stratus.services.notify.error(error)
    }
  }
}
</script>
