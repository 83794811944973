<template>
  <div>
    <v-container class="ma-0 pa-4" fluid v-if="$vuetify.breakpoint.smAndDown || $store.getters.nbActiveWidget > 2">
      <v-data-iterator :items="items" :headers="headers" :hide-default-header="config.hideHeaders" must-sort :options.sync="options">
        <template v-slot:item="{ item }">
          <v-list dense>
            <v-list-item>
              <v-list-item-subtitle class="header-fixed" v-if="!config.hideHeaders">
                {{ $t('Instance') }}
              </v-list-item-subtitle>
              <v-list-item-title>
                <v-icon :color="getInstanceStateColor(item.state)">
                  {{ getInstanceStateIcon(item.state) }}
                </v-icon>
                <span class="text-subtitle-1">{{ item.name }}</span>
                <span class="text-caption"> ({{ $t('state_' + item.state) }})</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="item.vcpu">
              <v-list-item-subtitle class="header-fixed" v-if="!config.hideHeaders">
                {{ $t('Alarms') }}
              </v-list-item-subtitle>
              <v-list-item-title>
                <v-chip label small v-if="(item.alarms || []).length" :class="item.worstAlarm ? 'alert_' + item.worstAlarm.state : ''">
                  {{ item.alarms.length || '-' }}
                </v-chip>
                <span v-else class="text-caption">{{ $t('None') }}</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="item.vcpu">
              <v-list-item-subtitle class="header-fixed" v-if="!config.hideHeaders">
                {{ $t('vCPU') }}
              </v-list-item-subtitle>
              <v-list-item-title>
                <v-icon small class="menu-icon--text" left>
                  icon-cpu-filled
                </v-icon>
                <span>{{ item.vcpu }}</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="item.vram">
              <v-list-item-subtitle class="header-fixed" v-if="!config.hideHeaders">
                {{ $t('vRAM') }}
              </v-list-item-subtitle>
              <v-list-item-title>
                <v-icon small class="menu-icon--text" left>
                  icon-ram-filled
                </v-icon>
                <span>{{ $t('{count}{unit}', { count: item.vram, unit: $t('GB') }) }}</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-subtitle class="header-fixed" v-if="!config.hideHeaders">
                {{ $t('OS') }}
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ item.OSType || $t('Unknown') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-subtitle class="header-fixed" v-if="!config.hideHeaders">
                {{ $t('Customer') }}
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ item.customerId }}
              </v-list-item-title>
            </v-list-item>
          </v-list>

          <v-row>
            <v-col class="text-right" cols="12">
              <v-btn icon @click="displayInstanceDialog(item)">
                <v-icon color="menu-icon">
                  icon-eye
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="ma-6" />
        </template>
        <template v-slot:no-data>
          <div class="text-h6 text-center">
            {{ $t('No Instance.') }}
          </div>
        </template>
      </v-data-iterator>
    </v-container>

    <v-data-table v-else :items="items" :headers="headers" :hide-default-header="config.hideHeaders" must-sort class="fixed-columns-data-table" :options="options" dense>
      <template v-slot:item="{ item }">
        <tr :class="$store.getters.nbActiveWidget > 2 ? 'card' : ''">
          <td v-show="isVirtualVDC" :data-label="$t('vm-vdc')">
            {{ item.vdcName }}
          </td>
          <td :data-label="$t('Instance')">
            {{ item.name }}
          </td>
          <td :data-label="$t('State')">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color="getInstanceStateColor(item.state)">
                  {{ getInstanceStateIcon(item.state) }}
                </v-icon>
              </template>
              <span>{{ $t('state_' + item.state) }}</span>
            </v-tooltip>
          </td>
          <td :data-label="$t('Alarms')">
            <v-chip label small v-if="(item.alarms || []).length" :class="item.worstAlarm ? 'alert_' + item.worstAlarm.state : ''">
              {{ item.alarms.length || '-' }}
            </v-chip>
            <span v-else class="text-caption">{{ $t('None') }}</span>
          </td>
          <td :data-label="$t('vCPU')">
            {{ item.vcpu || $t('Undefined') }}
          </td>
          <td :data-label="$t('vRAM')">
            {{ item.vram ? $t('{count}{unit}', { count: item.vram, unit: $t('GB') }) : $t('Undefined') }}
          </td>
          <td :data-label="$t('OS')">
            {{ item.OSType || $t('Unknown') }}
          </td>
          <td :data-label="$t('Customer')">
            {{ item.customerId }}
          </td>
          <td :data-label="$t('Backups')">
            <span v-for="(backup, index) in item.backups" :key="index">
              {{ $t(backup.backup_policy_name) }}
              <v-chip small>
                {{ backup.backups.length }}
              </v-chip>
            </span>
          </td>
          <td :data-label="$t('Actions')">
            <v-btn icon @click="displayInstanceDialog(item)">
              <v-icon color="menu-icon">
                icon-eye
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <div class="text-h6 text-center">
          {{ $t('No Instance.') }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getAlarmStateColor } from '@/theme/alerts'
import { getInstanceStateColor, getInstanceStateIcon } from '@/theme/widgets'

export default {
  name: 'CsSupervisionListView',
  props: {
    id: { type: String, required: true },
    config: { type: Object, required: true },
    items: { type: Array, required: true },
    machineType: { type: String, required: true }
  },
  data () {
    return {
      getInstanceStateIcon,
      getInstanceStateColor,
      loading: true,
      totalItems: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        mustSort: true
      }
    }
  },
  computed: {
    headers () {
      let headers
      if (this.isVirtualVDC) headers = [{ text: this.$t(this.$store.getters.appConfig.cloud.virtualVDC), value: 'vdcName' }]
      else headers = []
      headers = headers.concat([
        { text: this.$t('Instance'), value: 'name' },
        { text: this.$t('State'), value: 'state' },
        { text: this.$t('Alarms'), value: 'alarms' },
        { text: this.$t('vCPU'), value: 'vcpu' },
        { text: this.$t('vRAM'), value: 'vram' },
        { text: this.$t('OS'), value: 'OSType' },
        { text: this.$t('Customer'), value: 'customerId' },
        { text: this.$t('Backups'), value: 'backups' },
        { text: this.$t('Actions'), value: '' }
      ])
      return headers
    },
    isVirtualVDC () {
      return this.machineType === this.$store.getters.appConfig.cloud.virtualVDC
    }
  },
  methods: {
    displayInstanceDialog (instance) {
      if (instance) {
        this.$root['supervision-instance-dialog'].open(instance)
      }
    },
    getAlarmColor (item) {
      return item.worstAlarm && item.worstAlarm.state && getAlarmStateColor(item.worstAlarm.state) ? getAlarmStateColor(item.worstAlarm.state) : (item.state !== 'on' && item.state !== 'off') ? getInstanceStateColor('unknown') : getInstanceStateColor(item.state.toLowerCase())
    },
    isLord () { return this.$store.getters['$stratus-states/isLord'] }
  }
}
</script>
