<template>
  <v-row align="center" v-on="$listeners" :dense="!large">
    <v-col class="shrink">
      <v-avatar class="instance-resource-avatar" :class="dark ? 'theme--dark' : 'theme--light'">
        <v-icon :large="large" class="vcpu--text">
          icon-cpu-filled
        </v-icon>
      </v-avatar>
    </v-col>
    <v-col>
      <span :class="large ? 'text-h6' : ''">
        {{ $tc('No vCPU!|{count} vCPU', value, { count: value }) }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'VCpu',
  props: {
    dark: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    value: { type: Number, default: 0 },
    text: { type: String, default: '' }
  },
  data () {
    return {}
  }
}
</script>
