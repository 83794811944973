/*
 * Register all widget components
 */

import Alarms from './Alarms.vue'
import Monitor from './Monitor.vue'
import MonitorSingle from './MonitorSingle.vue'
import Supervision from './Supervision.vue'
import Tickets from './Tickets.vue'

import WidgetsBar from './WidgetsBar.vue'

export default Vue => {
  Vue.component('widget-alarms', Alarms)
  Vue.component('widget-monitor', Monitor)
  Vue.component('widget-monitor-single', MonitorSingle)
  Vue.component('widget-supervision', Supervision)
  Vue.component('widget-tickets', Tickets)

  // Widget manager
  Vue.component('widgets-bar', WidgetsBar)
}
