const MENU_TOP = [
  { href: '/', title: 'Home', icon: 'icon-home', big: true }
]

const MENU_FOOTER = [
  { href: '/about', title: 'About', icon: 'icon-version' }
]
const MENU_CONNECTED = [
  {
    title: 'Secrets',
    icon: 'icon-spy',
    big: true,
    items: [
      { modal: 'share-secret', title: 'Share a secret', icon: 'icon-secure-email' },
      { modal: 'reveal-secret', title: 'Reveal a secret', icon: 'icon-opened-email' }
    ]
  },
  { divider: true },
  { modal: 'bug-report', title: 'Report', icon: 'icon-bug' }
]

const CONNECTED = MENU_TOP.concat(MENU_CONNECTED).concat(MENU_FOOTER)

const DISCONNECTED = MENU_TOP.concat([{
  modal: 'reveal-secret',
  title: 'Reveal a secret',
  icon: 'icon-opened-email'
}]).concat(MENU_FOOTER)

export default {
  CONNECTED,
  DISCONNECTED
}
