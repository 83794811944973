/* eslint-disable quote-props */
export default {
  'Visit <a href="{brand_website}" target="_sws">{brand} website</a> to stay aware about {brand} latest news and offers.': 'Visitez le <a href="{brand_website}" target="_sws">Site Web {brand}</a> pour les dernières actualités et offres.',
  'Manage your products, quotations and orders with <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.': 'Gérer vos produits, devis et commandes avec <a href="https://manager.scalair.fr" target="_csi">Scalair Manager</a>.',
  'Scalair': 'Scalair',
  'Scalair Dashboard': 'Scalair Dashboard',
  'Scalair Manager': 'Scalair Manager',
  'Administration': 'Administration',
  'To automate the transition to full screen, you can install a kiosk extension in your browser.': 'Pour automatiser le passage en plein écran, vous pouvez installer une extension de type kiosk dans votre navigateur.',
  'Disconnecting...': 'Déconnexion…',
  'Error loading {name}: {error}': '{name}, erreur au chargement : {error}',
  'Report': 'Signaler',
  'Error: {error}': 'Erreur : {error}',
  'Server error: {error}': 'Erreur du serveur : {error}',
  'Instance not found': 'Instance non trouvée !',
  'Instance not found.': 'Instance non trouvée !',
  'Category: {category}': 'Catégorie : {category}',
  'Your password request has been sent. You should receive an email soon.': 'Votre demande de mot de passe a été envoyée. Vous recevrez bientôt un mail.',
  'Important!': 'Important !',
  'All': 'Tous',
  'Unknown': 'Inconnu',
  'None': 'Aucun(e)',
  'Empty': 'Vide',
  'Light theme': 'Thème clair',
  'Dark theme': 'Thème sombre',
  'Languages': 'Langages',
  'Home': 'Accueil',
  'Reloading application...': 'Rechargement de l\'application…',
  'You must login to access your Scalair dashboard.': 'Connectez-vous pour accéder à votre tableau de bord Scalair.',
  'Can not connect to Scalair Dashboard!': 'Impossible de se connecter au tableau de bord Scalair !',
  'Share a secret': 'Partager un secret',
  'This feature allows you to securely transmit information to an email. The recipient will receive two emails: one with the link for open the secret, a second with the password for unlock it. Once read, the secret will no longer be accessible.': 'Cette fonctionnalité permet de transmettre de manière sécurisée, de l\'information à une autre adresse mail. Le destinataire recevra deux mails : l\'un avec un lien pour ouvrir le secret, un second avec le mot de passe pour déverrouiller le secret. Une fois lu, le secret ne sera plus accessible.',
  'Secret send to {email}.': 'Secret envoyé à {email}.',
  'Recipient email': 'Email du destinataire',
  'Reveal a secret': 'Révéler un secret',
  'Secret identifier': 'Identifiant du secret',
  'Reveal': 'Révéler',
  'Secret': 'Secret',
  'Secrets': 'Secrets',
  'Please, enter the secret identifier, and password. Then click on «Reveal» button to display the content of the secret.': 'Saisissez l\'identifiant et le mot de passe du secret. Puis cliquez sur le bouton « Révéler » pour en afficher le contenu.',
  'Please, enter the password for the secret and click on «Reveal» button to display the content of the secret.': 'Saisissez le mot de passe du secret, puis cliquez sur le bouton « Révéler » pour en afficher le contenu.',
  'Secret as been read and is now erased.': 'Le secret a été lu et est maintenant effacé.',
  'No secret for this id and password.': 'Aucun secret ne correspond à cet identifiant et ce mot de passe.',
  'Email': 'Email',
  'Password': 'Mot de passe',
  'Username': 'Identifiant',
  'Users': 'Utilisateurs',
  'User': 'Utilisateur',
  'Settings': 'Préférences',
  'Comments': 'Commentaires',
  '{count} comments': 'Pas de commentaires|1 commentaire|{count} commentaires',
  'Connection: ': 'Connexion : ',
  '{count} Items': '{count} éléments',
  'chart-time-tooltip-format': 'DD/MM/YYYY HH:mm',
  'chart-time-display-formats-minute': 'h:mm a',
  '{percent}%': '{percent} %',
  'Average free space {avg}% on {count}{unit}.': 'Espace libre moyen de {avg} % sur {count} {unit}.',
  'Priority': 'Priorité',
  'priority-critical': 'Critique',
  'priority-high': 'Élevée',
  'priority-moderate': 'Modérée',
  'priority-low': 'Basse',
  'priority-planning': 'Planifiée',
  'priority-1': 'Critique',
  'priority-2': 'Élevée',
  'priority-3': 'Modérée',
  'priority-4': 'Basse',
  'priority-5': 'Planifiée',
  'state-1': 'Nouveau',
  'state-2': 'En cours',
  'state-3': 'En attente',
  'state-6': 'Résolu',
  'state-7': 'Fermé',
  'state-8': 'Annulé',
  'Severity': 'Gravité',
  'severity-ok': 'OK', // OK»
  'severity-information': 'Information',
  'severity-disaster': 'Catastrophique',
  'severity-critical': 'Critique',
  'severity-high': 'Élevée',
  'severity-moderate': 'Modérée',
  'severity-average': 'Moyenne',
  'severity-warning': 'Avertissement',
  'severity-low': 'Basse',
  'severity-planning': 'Planifiée',
  'severity-unknown': 'Inconnu',
  'severity-not_classified': 'Non classé',
  'vm': 'Instance',
  'Instance': 'Instance',
  'No Instance.': 'Aucune instance.',
  'server': 'Serveur',
  'ec2': 'EC2',
  'State': 'État',
  'state-new': 'Nouveau',
  'state-in progress': 'En cours…',
  'Compute': 'Calcul',
  'Storage': 'Stockage',
  '«{drive}»': '« {drive} »',
  'Storage|Storages': 'Stockage|Stockages',
  'Network': 'Réseau',
  'VoIP': 'VoIP',
  'Other': 'Autre',
  'scalair-status-compute': 'Calcul',
  'scalair-status-storage': 'Stockage',
  'scalair-status-network': 'Réseau',
  'scalair-status-voip': 'VoIP',
  'scalair-status-other': 'Autre',
  'and': 'et',
  'Back': 'Retour',
  'Edit': 'Modifier',
  'Add': 'Ajouter',
  'Save': 'Enregistrer',
  'Confirm': 'Confirmation',
  'Confirm deletion of item?': 'Confirmez-vous la suppression de l\'élément ?',
  'Update': 'Modifier',
  'Delete': 'Supprimer',
  'Create': 'Créer',
  'Submit': 'Enregistrer',
  'Send': 'Envoyer',
  'Created at': 'Créé le',
  'Updated at': 'Modifié le',
  'Details': 'Détails',
  'Name': 'Nom',
  'Title': 'Titre',
  'Page Not Found.': 'Page non trouvée',
  'Demo user': 'Utilisateur de démonstration',
  'Type': 'Type',
  'Id': 'Id',
  'Scalair account': 'Compte Scalair',
  'Label': 'Intitulé',
  'Identifier': 'Identifiant',
  'Tags': 'Étiquettes',
  'Notes': 'Notes',
  'Saved!': 'Enregistré !',
  'Last update:': 'Mise à jour :',
  'Since {date}': 'Depuis le {date}',
  'Services Status': 'Météo des services',
  'Toggle fullscreen': 'Activer/Désactiver le plein écran',
  'Toggle widgets bar': 'Afficher/cacher la barre des widgets',
  'Display/hide notifications': 'Afficher/cacher les notifications',
  'Toggle Scalair services status bar': 'Afficher/cacher la barre de statut des services Scalair',
  'Supervision': 'Supervision',
  'Alarms': 'Alarmes',
  'Tickets': 'Tickets',
  'Hardware': 'Matériel',
  'Monitor': 'Suivi',
  'MonitorSingle': 'Instance',
  'Activate one or more widgets above...': 'Activez un ou plusieurs widget ci-dessus…',
  'The Supervision widget gives you a grid that will display current alarms status for all your instances.': 'Le widget Supervision permet de visualiser l\'état d\'alarme de vos instances d\'un coup d\'œil.',
  'The Alarms widget gives you an up-to-date list of all alarms that your instances raised.': 'Le widget Alarmes affiche la liste de toutes les alarmes de vos instances en temps réel.',
  'The Tickets widget allow to keep an eye on all your opened tickets shared with our support team.': 'Le widget Tickets montre tous vos tickets ouverts gérés par notre équipe Support.',
  'The Monitor widget gives you an up-to-date detailed view of the resources that an instance is consuming.': 'Le widget Suivi donne une vision détaillée de la consommation des ressources d\'une instance.',
  'Hide supervision widget': 'Cacher le widget de supervision',
  'Show supervision widget': 'Afficher le widget de supervision',
  'Hide': 'Cacher',
  'Show': 'Afficher',
  'No data for {name}.': 'Pas de données pour {name}.',
  'Rows per page': 'Lignes par page',
  'Hide grid columns headers': 'Cacher les entêtes des colonnes',
  'Display dates in long format': 'Afficher les dates en format long',
  'Display tickets with recent customer comments first': 'Afficher en premier les tickets récemment commentés par les clients',
  'Customer': 'Société',
  'Ticket': 'Ticket',
  'Assigned to': 'Assigné à',
  'Profiles': 'Profils',
  'Choose a profile': 'Choisir un profil',
  'Profile': 'Profil',
  'Profile: ': 'Profil : ',
  'Profile name': 'Nom du profil',
  'New profile': 'Nouveau profil',
  '1 profile | {count} profiles': '1 profil | {count} profils',
  'Default profile': 'Profil par défaut',
  'Choose as default profile': 'Choisir comme profil par défaut',
  'Confirm deletion of «{item}»?': 'Confirmez-vous l\'effacement de « {item} » ?',
  'Address': 'Adresse',
  'Host': 'Hôte',
  'Service': 'Service',
  'Message': 'Message',
  'Use the widget menu to choose a service...': 'Utilisez le menu du widget pour activer des services…',
  'Hide powered off items': 'Cacher les éléments éteints',
  'Hide items without alarms': 'Cacher les éléments sans alarmes',
  'Show VM Chart': 'Montrer le graphe des VM',
  'ON': 'ALLUMÉE',
  'OFF': 'ÉTEINTE',
  'STOPPED': 'ARRÊTÉE',
  'Do you want to follow this instance ?': 'Voulez-vous suivre cette instance ?',
  'vCPU {n}': 'vCPU {n}',
  'vRAM {n}GB': 'vRAM {n} GO',
  'vCPU': 'vCPU',
  'No vCPU!|{count} vCPU': 'Pas de vCPU !|{count} vCPU|{count} vCPU',
  'vRAM': 'vRAM',
  'No vRAM!|{count} GB vRAM': 'Pas de vRAM !|{count} Go vRAM',
  'Display graph only': 'Afficher le graphique seulement',
  'Display all metrics': 'Afficher toutes les métriques',
  'Stop monitoring': 'Ne plus suivre',
  'No instance.|One instance.|{count} instances.': 'Aucune instance.|Une instance.|{count} instances.',
  'Loading instances data...': 'Chargement des données d\'instances…',
  'No storage for this instance.': 'Pas de stockage pour cette instance.',
  'No history for this instance.': 'Pas d\'historique pour cette instance.',
  'No history for this period.': 'Pas d\'historique pour cette période.',
  '1h': '1 h',
  '6h': '6 h',
  '1d': '1 jour',
  '1w': '1 sem.',
  '1M': '1 mois',
  '1Q': '3 mois',
  'Percent Used': 'Pourcentage d\'utilisation',
  '{percent}% used.': '{percent} % utilisé.',
  'Data update frequency': 'Fréquence de mise à jour des données',
  'Click to hide/show graph for {name}': 'Cliquez pour afficher/cacher le graphique {name}',
  'Uptime since {date}': 'En activité depuis {date}',
  '{count}{unit}': '{count} {unit}',
  'Alerts thresholds': 'Seuils d\'alertes',
  'Set alerts thresholds': 'Régler les seuils d\'alertes',
  'Reset alerts thresholds': 'Réinitialiser les seuils d\'alertes',
  'Confirm the reset of all this thresholds?': 'Confirmez-vous la réinitialisation de tous ces seuils ?',
  'You can set the thresholds that trigger alerts at your convenience.': 'Vous pouvez régler à votre convenance les seuils qui déclenchent l\'émission des alertes.',
  'vm_cpu_high_threshold_warning': 'Avertissement de CPU élevée',
  'vm_cpu_high_threshold_critical': 'Avertissement de CPU critique',
  'vm_mem_high_threshold_warning': 'Avertissement de mémoire élevée',
  'vm_mem_high_threshold_critical': 'Avertissement de mémoire critique',
  'vm_disk_high_threshold_warning': 'Avertissement de stockage élevé',
  'vm_disk_high_threshold_critical': 'Avertissement de stockage critique',
  'Metrics saved for {name}.': 'Métriques enregistrées pour {name}.',
  'Add {name} to monitoring widget': 'Ajouter {name} au widget «Suivi»',
  'Do you really want to stop following this instance ?': 'Voulez-vous vraiment arrêter de suivre cette instance ?',
  'Remove all monitored instances': 'Arrêtez le suivi de toutes ces instances',
  'Do you really want to stop following all this instances ?': 'Voulez-vous vraiment arrêter de suivre toutes ces instances ?',
  '{name} is already followed!': '{name} est déjà suivie !',
  'Instance {name} added successfully': 'Instance {name} ajoutée au suivi',
  'Instance is no longer monitored': 'L\'instance n\'est plus suivie.',
  'Add to monitoring': 'Ajouter au suivi',
  'Alerts setting': 'Réglage des alertes',
  'Disable alerts for this metric': 'Désactiver les alertes pour cette métrique',
  'Disabled': 'Désactivé',
  'Warn {0}%, critical {1}%': 'Avertissement {0} %, critique {1} %',
  'Measures': 'Métriques',
  'Loading thresholds...': 'Chargement des seuils…',
  'Error loading thresholds!': 'Erreur au chargement des seuils !',
  'The new thresholds will be applied within 15 minutes.': 'Les nouveaux seuils seront appliqués dans un délai de 15 minutes.',
  'No threshold setup for this instance {name}.': 'Aucun seuil réglable pour l\'instance {name}.',
  'Pick some metrics': 'Choisissez des métriques',
  'Choose one or more metrics to setup their warning and critical alerts threshold.': 'Choisissez une ou plusieurs métriques pour régler leurs seuils d\'avertissement et d\'alerte critique.',
  'Welcome to Scalair Dashboard': 'Bienvenue dans Scalair Dashboard',
  'This application allows you to monitor your instances and tickets.': 'Cette application vous permet de superviser vos instances et vos tickets.',
  'Follow resources consumption': 'Suivre la consommation des ressources',
  'Prioritize your tickets': 'Prioriser vos tickets',
  'Be alerted when something goes wrong': 'Être averti quand quelque chose déraille',
  'Keep in touch with our teams...': 'Rester en contact avec nos équipes…',
  'Your report as been submitted.': 'Votre rapport a été envoyé.',
  'on': 'allumé',
  'off': 'éteinte',
  'stopped': 'suspendue',
  'state_on': 'allumée',
  'state_off': 'éteinte',
  'state_stopped': 'suspendue',
  'state_unknown': 'état inconnu',
  'state_undefined': 'état inconnu',
  'state_suspended': 'suspendue',
  'Start {name}...': 'Démarrer {name}…',
  'Stop {name}...': 'Arrêter {name}…',
  'Force stop {name}...': 'Forcer l\'arrêt de {name}…',
  'Reboot {name}...': 'Redémarrer {name}…',
  'Open console on {name}...': 'Ouvrir la console de {name}…',
  'Start order send for {name}...': 'Démarrage demandée pour {name}…',
  'Stop order send for {name}...': 'Arrêt demandé pour {name}…',
  'Reboot order send for {name}...': 'Redémarrage demandé pour {name}…',
  'Confirm the start of this instance.': 'Confirmez-vous le démarrage de cette instance ?',
  'Confirm the stop of this instance.': 'Confirmez-vous l\'arrêt de cette instance ?',
  'Confirm the reboot of this instance.': 'Confirmez-vous le redémarrage de cette instance ?',
  'Confirm the reboot of this instance. (As vmtools are not installed, a hard reboot well be performed)': 'Confirmez-vous le redémarrage de cette instance ? (Les outils vmtools ne sont pas installés, c\'est un redémarrage à froid qui sera fait.)',
  'Service pack: {pack}': 'Pack de service : {pack}',
  'Thresholds saved for {name}.': 'Seuils enregistrés pour {name}.',
  'The ticket should have at least one comment.': 'Ce ticket devrait avoir au moins un commentaire.',
  'CPU consumption threshold to trigger alert': 'Seuil de déclenchement d\'alerte de consommation de CPU',
  'RAM consumption threshold to trigger alert': 'Seuil de déclenchement d\'alerte de consommation de RAM',
  'This is the value returned by the instance operating system.': 'Cette valeur est celle que retourne le système d\'exploitation.',
  'vmWare tools need date': 'vmWare tools à mettre à jour.',
  'vmWare tools up-to-date': 'vmWare tools à jour.',
  'vm-start': 'Instance {name} démarrée',
  'vm-stop': 'Instance {name} arrêtée',
  'vm-reboot': 'Instance {name} redémarrée',
  'No alarm.': 'Aucune alarme.',
  'No ticket.': 'Aucun ticket.',
  '{label} use {value}{unit} at {date} (Raw: {raw}).': '{value} {unit} de {label} utilisé le {date} (Valeur brute : {raw}).',
  'Type of view:': 'Type de vue :',
  'Click to show or hide all items from {name}.': 'Cliquer pour afficher ou cacher les éléments de {name}.',
  'Instantaneous state': 'État instantanée',
  'Performance history': 'Historique de performance',
  'This instance needs an upgrade of vmTools!': 'Cette instance a besoin d\'une mise à jour de vmTools !',
  'OS': 'SE',
  'Operating system: {name}': 'Système d\'exploitation : {name}',
  'VDC: {name}': 'Cloud Dédié: {name}',
  'vm-mutu': 'Cloud Scalair',
  'vm-vdc': 'Cloud Dédié',
  'physical': 'Équipements',
  'Arrange the widgets in columns for this profile.': 'Disposer les widgets en colonnes pour ce profil.',
  'Arrange the widgets freely for this profile.': 'Disposer les widgets librement pour ce profil.',
  'Force card view': 'Afficher en carte',
  'Remove this widget': 'Retirer ce widget',
  'Dense display': 'Affichage dense',
  'Use the(+) button to place widgets on the dashboard.<br><br><b>Move a widget</b> by grabbing it by its title or an empty part and dropping it at the desired location.<br><br><b>Resize a widget</b> by grabbing its handle in the bottom right corner and releasing it when you reach the desired size.': 'Utilisez le bouton (+) pour placer les widgets sur le tableau de bord.<br><br><b>Déplacez un widget</b> en le saisissant par son titre ou une partie vide et en le déposant à l\'endroit souhaité.<br><br><b> Redimensionnez un widget</b> en saisissant sa poignée dans le coin inférieur droit et en la relâchant lorsque vous atteignez la taille souhaitée.',
  'Visible columns': 'Colonnes visibles',
  'Backups': 'Sauvegardes',
  'No backup': 'Pas de sauvegarde',
  'Created at {date}': 'Créé le {date}',
  'Expires on {date}': 'Expire le {date}',
  'BACKUP_7J': '7 jours',
  'BACKUP_7J_4S': '7j. / 4sem.',
  'BACKUP_7J_4S_12M': '7j. / 4sem. / 12mois',
  'BACKUP_12H': '12 heures',
  '7D': '7 jours',
  '7jours': '7 jours',
  '7D4W': '7j. / 4sem.',
  '7jours4semaines': '7j. / 4sem.',
  '7D4W12M': '7j. / 4sem. / 12mois',
  '7D4W12M-ADV': '7j. / 4sem. / 12mois (adv)',
  '7jours4semaines12mois': '7j. / 4sem. / 12mois',
  '7jours4semaines12mois3ans': '7j. / 4sem. / 12mois / 3ans',
  'Hide grid pagination controls': 'Cacher les contrôles de pagination',
  'No instance|One instance|{count} instances': 'Aucune instance|Une instance|{count} instances',
  'user-role-bus': 'Business',
  'user-role-tec': 'Technicien',
  'user-role-adm': 'Administrateur',
  'user-role-acc': 'Comptable',
  'user-role-prman': 'Chef de Projet',
  'user-role-spe': 'Spectateur',
  'user-role-sup': 'Superviseur',
  'Last authentication: {date}': 'Dernière authentification le {date}',
  'Connect as...': 'Se connecter en tant que…',
  'Choose a user...': 'Choisissez un utilisateur…',
  'Please choose the user you want to connect as. Only user accounts are shown, bot account are not allowed.': 'Choisissez l\'utilisateur dont vous voulez emprunter le compte. Les comptes de type automate ne sont pas autorisés.',
  'Returning to your account.': 'Retour à votre compte.',
  'Error connecting as {name}!': 'Erreur de connexion en tant que {name} !',
  'Error: cannot open console of {name}.': 'Erreur: impossible d\'ouvrir la console de {name}.',
  'No response from {name} console': 'Pas de réponse de la console de {name}',
  'Console of {name} is ready!': 'La console de {name} est prête !',
  'Open': 'Ouvrir',
  'Phone': 'Téléphone',
  'Firstname': 'Prénom',
  'Cart': 'Devis',
  'Order': 'Commande',
  'Document': 'Document',
  'Lastname': 'Nom de famille',
  'Managed': 'Managé',
  'Not managed': 'Non managé',
  '/s': '/s',
  'I-O/s': 'E-S/s',
  'Source: {source}': 'Source: {source}',
  'source-centreon': 'Système d\'exploitation',
  'source-prometheus': 'Hyperviseur',
  'Download as CSV': 'Télécharger le CSV',
  'To open a console, your instance must be started.': 'Pour ouvrir une console l\'instance doit être démarrée.',
  'Probes': 'Sondes',
  'Probe': 'Sonde',
  'Output': 'Résultat',
  'event-status-analysis': 'En cours d\'analyse…',
  'event-status-closed': 'Terminé',
  'event-status-done': 'Terminé',
  'event-status-identified': 'Cause identifiée',
  'event-status-in_progress': 'En cours…',
  'event-status-pause': 'En pause',
  'event-status-scheduled': 'Planifié',
  'event-type-incident': 'Incident',
  'event-type-maintenance': 'Maintenance',
  'Caution! This shutdown will abruptly stop all running programs. It is equivalent to cutting the power.': 'Attention ! Cet arrêt coupera brutalement tous les programmes en cours d\'exécution. Ça équivaut à couper le courant.',
  'Maximize': 'Agrandir',
  'Reduce': 'Réduire',
  'Return to summary': 'Retour au résumé',
  'Show metrics': 'Afficher les métriques',
  '{count} alarms': 'Pas d\'alarme|1 alarme|{count} alarmes',
  'LAN to LAN': 'LAN to LAN',
  'Name / Alias': 'Nom / Alias',
  'Status change': 'Chang. de status',
  'All link are up.': 'Tous les liens sont activés.',
  'Some link are not up.': 'Des liens ne sont pas activés.',
  'Automatic scale': 'Échelle automatique'
}
