<template>
  <v-row align="center" v-on="$listeners" :dense="!large">
    <v-col>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-row v-on="on" align="center" class="ml-0" :dense="!large">
            <v-avatar class="instance-resource-avatar mr-1" :class="dark ? 'theme--dark' : 'theme--light'">
              <v-icon :large="large" :color="getInstanceStateColor(value)">
                {{ getInstanceStateIcon(value) }}
              </v-icon>
            </v-avatar>
            <span v-show="!small" :class="large ? 'ml-5 text-h6' : ''">{{ $t('state_' + value) }}</span>
          </v-row>
        </template>
        <span>{{ $t('state_' + value) }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { getInstanceStateColor, getInstanceStateIcon } from '@/theme/widgets'

export default {
  name: 'CsInstanceState',
  props: {
    large: { type: Boolean, default: false },
    refreshing: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    value: { type: String, default: 'unknown' }
  },
  data () {
    return {
      getInstanceStateColor,
      getInstanceStateIcon
    }
  },
  computed: {
    dark () {
      return this.$store.getters['$stratus-states/isDark']
    }
  }
}
</script>
