import _ from 'lodash'

function getStatusStyle (status) {
  switch (status.toUpperCase()) {
    case 'OK': return {
      class: 'alert_ok',
      icon: 'icon-checkmark-circle'
    }
    case 'SEVERITY1': return {
      class: 'alert_error',
      icon: 'icon-caution-circle'
    }
    case 'SEVERITY2': return {
      class: 'alert_warning',
      icon: 'icon-caution-circle'
    }
    case 'KO': return {
      class: 'alert_critical',
      icon: 'icon-caution-circle'
    }
    case 'SEVERITY3':
    default: return {
      class: 'alert_unknown',
      icon: 'icon-info'
    }
  }
}

function getStyle (service) {
  let criticity = service.status
  if (service.currentEvent) {
    criticity = service.currentEvent.criticity || 'medium'
  }

  switch ((criticity || '').toUpperCase()) {
    case 'HIGH': return {
      class: 'alert_error',
      icon: 'icon-caution-circle'
    }
    case 'MEDIUM': return {
      class: 'alert_warning',
      icon: 'icon-caution-circle'
    }
    case 'LOW':return {
      class: 'alert_unknown',
      icon: 'icon-caution-circle'
    }
    default: return getStatusStyle(service.status)
  }
}

export default {
  setCurrent (state, { rootState, error, body }) {
    let services = body && body.results ? body.results : []
    services.forEach(function (s) {
      s.createdAt = rootState.$stratus.dt(s.createdAt)
      s.updatedAt = rootState.$stratus.dt(s.updatedAt)
      s.status = s.status.toUpperCase()
      s.style = getStyle(s)
    })
    services = _.sortBy(services, function (s) { return s.displayOrder })
    state.current = {
      error: error,
      services
    }
  }
}
