import _ from 'lodash'
import { getDataCollection, filterMetrics } from '@/services/metrics.js'

let stratus = null // Link to internal library
let lan2lanCache = null
let lan2lanNextUpdate = null

export default {
  /**
  * Get widget content
  * @method
  * @param  {Object} context Store instance, read Vuex documentation {@link https://vuex.vuejs.org/fr/actions.html}
  * @param  {[type]} options [description]
  * @return {[type]} [description]
  */
  getData ({ commit, rootState }, options) {
    return rootState.$stratus.services.api.get(rootState.$stratus.services.api.encodeQueryString(options, '/instances'))
      .then((response) => commit('getData', response))
      .catch((error) => commit('API_FAILURE', error))
  },
  /**
   * Load Lan 2 Lan data for all instances
   * @param {Object} context Store instance, read Vuex documentation {@link https://vuex.vuejs.org/fr/actions.html}
   */
  getLan2Lan ({ commit, rootState }) {
    if (lan2lanCache && lan2lanNextUpdate && lan2lanNextUpdate.isAfter(rootState.$stratus.dt())) {
      return lan2lanCache
    }

    return rootState.$stratus.services.api.get('/instances/l2ls')
      .then(response => {
        lan2lanCache = response ? response.results || [] : []
        lan2lanNextUpdate = rootState.$stratus.dt().add(5, 'minutes')
        return lan2lanCache
      })
      .catch((error) => {
        commit('API_FAILURE', error)
      })
  },
  async getMonitoredItems ({ commit, state, rootState, getters }, { provider, id, time = '1h', hideDatasets = {} }) {
    stratus = rootState.$stratus

    let instance, metrics, alarms, probes
    try {
      [instance, metrics] = await Promise.all([
        rootState.$stratus.services.api.get(`/instances/${provider}/${id}`),
        rootState.$stratus.services.api.get(`/metrics/${id}?daterange=${time}`)
      ])
      alarms = await rootState.$stratus.services.api.get(`/alarms/${id}`)
      probes = await rootState.$stratus.services.api.get(`/alarms/probes/${id}`)
    } catch (error) {
      commit('API_FAILURE', error)
      return
    }

    const result = Object.assign({}, {
      expirationDate: rootState.$stratus.dt().add(getters.REFRESH_INTERVAL, 'minutes'),
      instance: instance,
      alarms: alarms.results.map(alarm => ({ ...alarm, create_at: stratus.dt(alarm.create_at) })),
      probes: _.sortBy(probes.results.map(probe => ({ ...probe, create_at: stratus.dt(probe.create_at) })), 'update_at'),
      metrics: {},
      worstAlarm: alarms.results[alarms.worstAlarmIndex]
    }, getDataCollection(stratus, metrics, rootState.appConfig, hideDatasets))

    if (state.props.singleton) commit('setMonitoredItemMetrics', filterMetrics(metrics, result, rootState.appConfig))
    return filterMetrics(metrics, result, rootState.appConfig)
  }
}
