<template>
  <h3>404 — That's the true answer!</h3>
</template>

<script>
export default {
  created () {
    // Redirect outside the app using plain old javascript
    window.location.href = '/404.html'
  }
}
</script>
