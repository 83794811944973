export default {
  askConsole ({ commit, rootState }, { provider, id }) {
    return rootState.$stratus.services.api.get('/instances/' + provider + '/' + id + '/console')
      .then(response => {
        return response
      })
      .catch((error) => {
        commit('API_FAILURE', error)
      })
  },
  askJobState ({ commit, rootState }, jobId) {
    return rootState.$stratus.services.api.get('/instances/jobs/' + jobId)
      .then(response => {
        return response
      })
      .catch((error) => {
        commit('API_FAILURE', error)
      })
  },
  /**
   * Get widget content
   * @method
   * @param  {Object} context Store instance, read Vuex documentation {@link https://vuex.vuejs.org/fr/actions.html}
   * @param  {[type]} options [description]
   * @return {[type]} [description]
   */
  loadThresholds ({ commit, rootState }, options) {
    return rootState.$stratus.services.api.get('/alarms/' + options.id + '/thresholds')
      .then(response => {
        return response
      })
      .catch((error) => {
        if (error && error.status === 404) return []
        else commit('API_FAILURE', error)
      })
  },
  saveThresholds ({ commit, rootState }, { id, thresholds }) {
    const data = {}

    thresholds.forEach(t => {
      data[t.name] = t.inputValue
    })

    return rootState.$stratus.services.api.put('/alarms/' + id + '/thresholds', data)
      .then(() => {
        // commit('setThresholds', data)
      })
      .catch((error) => {
        commit('API_FAILURE', error)
      })
  },
  start ({ commit, rootState }, { id, provider }) {
    return rootState.$stratus.services.api.put('/instances/' + provider, { instancesIds: [{ id, action: 'start' }] })
      .then(response => {
        return response
      })
      .catch((error) => {
        commit('API_FAILURE', error)
      })
  },
  stop ({ commit, rootState }, { id, provider, force = false }) {
    return rootState.$stratus.services.api.put('/instances/' + provider, { instancesIds: [{ id, action: 'stop', force }] })
      .then(response => {
        return response
      })
      .catch((error) => {
        commit('API_FAILURE', error)
      })
  },
  reboot ({ commit, rootState }, { id, provider }) {
    return rootState.$stratus.services.api.put('/instances/' + provider, { instancesIds: [{ id, action: 'reboot' }] })
      .then(response => {
        return response
      })
      .catch((error) => {
        commit('API_FAILURE', error)
      })
  }
}
