import _ from 'lodash'
import Vue from 'vue'
import Vuex from 'vuex'
import menu from '@/views/menu'
import config from '@/config'
import infraStatus from './modules/infra-status'
import instances from './modules/instances'
import users from './modules/users'
import widgets from './modules/widgets'
import pkg from '../../package.json'

Vue.use(Vuex)

const MODULES = {
  infraStatus,
  instances,
  users,
  widgets
}

const store = new Vuex.Store({
  modules: MODULES,
  state: {
    $stratus: null,
    menu: menu,
    appConfig: config,
    appVersion: pkg.version,
    statusBarVisible: false,
    widgetsBarVisible: true,
    fullscreen: false,
    dark: false,
    dense: false,
    showPieChart: true,
    dashboardProfile: 'default',
    dashboardProfileList: ['default'],
    widgetsPreferences: {},
    helpers: {
      showFullscreenHelp: true
    },
    nbActiveWidget: 1,
    nbActiveService: 1,
    MonitoredItems: [],
    appBottomText: ''
  },
  getters: {
    appBottomText: state => state.appBottomText,
    existsMonitoredItems: state => vmId => {
      let found = false
      let i = 0
      while (!found && i < state.MonitoredItems.length) {
        if (state.MonitoredItems[i].id === vmId) {
          found = true
        }
        i++
      }
      return found
    },
    MonitoredItems: state => state.MonitoredItems,
    nbActiveService: state => state.nbActiveService,
    nbActiveWidget: state => state.nbActiveWidget,
    appConfig: state => state.appConfig,
    appVersion: state => state.appVersion,
    dark: state => {
      state.dark = state.$stratus.services.localStorage.get('dark-theme', false)
      return state.dark
    },
    showPieChart: state => state.showPieChart,
    dense: state => state.dense,
    dashboardProfile: state => {
      state.dashboardProfile = state.$stratus.services.localStorage.get('dashboard-profile', 'default')
      return state.dashboardProfile
    },
    dashboardProfileList: state => {
      return state.dashboardProfileList
    },
    fullscreen: state => {
      state.fullscreen = state.$stratus.services.localStorage.get('fullscreen', false)
      return state.fullscreen
    },
    helpers: state => helperKey => {
      state.helpers[helperKey] = state.$stratus.services.localStorage.get('helpers-' + helperKey, true)
      return state.helpers[helperKey]
    },
    widgetsProps: (state, getters) => {
      const _result = {}
      getters['widgets/list'].forEach(widget => {
        _result[widget] = state.widgets[widget].props
      })
      return _result
    },
    widgetConfig: state => widget => { return state.widgetsPreferences[widget] },
    statusBarVisible: state => {
      state.statusBarVisible = state.$stratus.services.localStorage.get('status-bar-visible', false)
      return state.statusBarVisible
    },
    widgetsBarVisible: state => {
      state.widgetsBarVisible = state.$stratus.services.localStorage.get('widgets-bar-visible', true)
      return state.widgetsBarVisible
    }
  },
  actions: {
    nbActiveService ({ commit, state }, value) {
      commit('setNbActiveService', value)
    },
    nbActiveWidget ({ commit, state }, value) {
      commit('setNbActiveWidget', value)
    },
    createProfile ({ commit, state }, profileId) {
      return state.$stratus.services.api.post('/users/preferences/profiles', { profileId })
        .then(response => {
          state.dashboardProfileList = response
        })
        .catch(error => console.error('[createProfile]', error))
    },
    deleteProfile ({ commit, state }, profileId) {
      return state.$stratus.services.api.delete('/users/preferences/profiles/' + profileId)
        .then(response => {
          commit('delProfile', profileId)
        })
        .catch(error => console.error('[deleteProfile]', error))
    },
    loadProfilePreferences ({ commit, state, getters }, prefKey) {
      // Load current profile
      const prof = getters.dashboardProfile
      return state.$stratus.services.api.get(`/users/preferences/${prof}/${prefKey}`)
        .then(response => {
          state.widgetsPreferences[prefKey] = Object.assign({}, response)
          return state.widgetsPreferences[prefKey]
        })
        .catch(error => console.error('[loadProfilePreferences]', error))
    },
    loadProfileList ({ commit, state }) {
      return state.$stratus.services.api.get('/users/preferences/profiles/list')
        .then(response => {
          if (response.indexOf('default') < 0) {
            response.push('default')
          }
          state.dashboardProfileList = response
        })
        .catch(error => console.error('[loadProfileList]', error))
    },
    reveal ({ commit, state }, { id, password }) {
      return state.$stratus.services.api.get(state.$stratus.services.api.encodeQueryString({ password }, '/secrets/' + id))
        .then(response => {
          return response
        })
        .catch(error => {
          throw error
        })
    },
    saveProfilePreference ({ commit, state }, prefKey, value) {
      return state.$stratus.services.api.put(`/users/preferences/${state.dashboardProfile}/${prefKey}`, value)
        .then(response => { commit('setProfilePreferences', value) })
        .catch(error => console.error('[saveProfilePreferences]', error))
    },
    saveWidgetConfig ({ commit, state }, data) {
      return state.$stratus.services.api.put('/users/preferences/widgets/' + data.widgetId + '/' + state.dashboardProfile, data.config)
        .then(response => { commit('setWidgetPreferences', data) })
        .catch(error => console.error('[saveWidgetConfig]', error))
    },
    delWidgetConfig ({ commit, state }, data) {
      return state.$stratus.services.api.delete(`/users/preferences/widgets/${data.widgetId}/${state.dashboardProfile}`)
        .then(response => {
          commit('setWidgetPreferences', data)
          return state.widgetsPreferences
        })
        .catch(error => console.error('[delWidgetConfig]', error))
    }
  },
  mutations: {
    loadAlto (state) {
      state.$alto = this._vm.$alto
      console.log('Linked to Alto framework', state.$alto ? 'Done' : ' Failed!')
    },
    loadStratus (state) {
      state.$stratus = this._vm.$stratus
      console.log('Linked to Stratus framework', state.$stratus ? 'Done' : ' Failed!')
    },
    addMonitoredItems (state, vm) {
      let found
      let i = 0
      while (found === undefined && i < state.MonitoredItems.length) {
        if (state.MonitoredItems[i].id === vm.id) {
          state.MonitoredItems[i] = _.cloneDeep(vm)
          found = i
        }
        i++
      }
      if (found === undefined) {
        state.MonitoredItems.unshift(vm)
        // state.MonitoredItems.push(vm)
      }
    },
    delMonitoredItems (state, vmId) {
      let found
      let i = 0
      while (found === undefined && i < state.MonitoredItems.length) {
        if (state.MonitoredItems[i].id === vmId) {
          found = i
        }
        i++
      }
      if (found !== undefined) {
        state.MonitoredItems.splice(found, 1)
      }
    },
    delMonitoredItemsAll (state) {
      state.MonitoredItems = []
    },
    delProfile (state, profileId) {
      state.dashboardProfileList = _.pull(state.dashboardProfileList, profileId)
    },
    clearMonitoredItems (state) {
      state.MonitoredItems.splice(0, state.MonitoredItems.length)
    },
    setNbActiveService (state, value) {
      state.nbActiveService = value
    },
    setNbActiveWidget (state, value) {
      state.nbActiveWidget = value
    },
    setAppBottomText (state, text) {
      state.appBottomText = state.$stratus.services.strings.stripHtmlTags(text)
    },
    setDashboardProfile (state, profileId) {
      state.$stratus.services.localStorage.set('dashboard-profile', profileId)
      state.dashboardProfile = profileId
    },
    setMenu (state, data) {
      state.menu = data
    },
    setProfilePreferences (state, data) {
      state.widgetsPreferences = data
    },
    setWidgetBarVisible (state, data) {
      state.$stratus.services.localStorage.set('widgets-bar-visible', data)
      state.widgetsBarVisible = data
    },
    setWidgetPreferences (state, data) {
      state.widgetsPreferences[data.widgetId] = data.config
    },
    setHelper (state, { helperKey, data }) {
      state.$stratus.services.localStorage.set('helper-' + helperKey, data)
      state.helpers[helperKey] = data
    },
    toggleDark (state) {
      state.$stratus.services.localStorage.set('dark-theme', !state.dark)
      state.dark = !state.dark
    },
    togglePieChart (state) {
      state.showPieChart = !state.showPieChart
    },
    toggleFullscreen (state) {
      state.$stratus.services.localStorage.set('fullscreen', !state.$stratus.services.localStorage.get('fullscreen', false))
      state.fullscreen = state.$stratus.services.localStorage.get('fullscreen', false)
    },
    toggleStatusBarVisible (state) {
      state.$stratus.services.localStorage.set('status-bar-visible', !state.$stratus.services.localStorage.get('status-bar-visible', false))
      state.statusBarVisible = state.$stratus.services.localStorage.get('status-bar-visible', false)
    },
    toggleWidgetBarVisible (state) {
      state.$stratus.services.localStorage.set('widgets-bar-visible', !state.$stratus.services.localStorage.get('widgets-bar-visible', true))
      state.widgetsBarVisible = state.$stratus.services.localStorage.get('widgets-bar-visible', true)
    }
  }
})

export default store
