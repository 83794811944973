<template>
  <div>
    <sca-modal-dialog :visible="instance && visible" :max-width="$vuetify.breakpoint.mdAndUp ? '60%' : ''" :can-close="!addingVmToMonitor" @closeDialog="close">
      <template #title>
        {{ instance.name }}
      </template>

      <div slot="subtitle" class="d-flex">
        <div class="highlighted-panel">
          {{ instance.provider }} / {{ $t(instance.machineType) }} {{ instance.customerName ? ' — ' + instance.customerName : '' }} / {{ instance.customerId || '-' }}
        </div>
      </div>

      <template #content>
        <v-card-text class="mt-4">
          <div v-show="!showDetails">
            <v-row dense>
              <v-col v-if="instance.vdcName" cols="12">
                {{ $t('VDC: {name}', { name: instance.vdcName }) }}
              </v-col>
              <v-col v-else-if="instance.OSType" cols="12">
                {{ $t('Operating system: {name}', { name: instance.OSType }) }}
              </v-col>

              <v-col>
                <div class="mt-4">
                  <cs-instance-state large v-model="instance.state" />
                </div>

                <div class="mt-6">
                  <cs-vcpu large :dark="dark" v-model="instance.vcpu" v-if="instance.vcpu" :text="$t('vCPU')" />
                </div>

                <div class="mt-4">
                  <cs-vram large :dark="dark" v-model="instance.vram" v-if="instance.vram" :text="$t('vRAM')" />
                </div>

                <div class="mt-4">
                  <v-avatar v-if="instance.type" icon class="instance-resource-avatar mr-1" :class="dark ? 'theme--dark' : 'theme--light'">
                    <v-icon>
                      icon-list
                    </v-icon>
                  </v-avatar> {{ instance.type }}
                </div>
              </v-col>

              <v-col cols="12" md="8">
                <v-list class="pa-0">
                  <v-list-item @click="addInstanceToMonitor()" v-if="$root?.widget_monitor !== undefined">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon :dark="dark" left>
                          icon-eye
                        </v-icon>
                        {{ $t('Add {name} to monitoring widget', { name: instance.name }) }}
                        <cs-icon-loading v-if="addingVmToMonitor" />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="isVirtualInstance(instance) && canChangeThresholds" @click="setInstanceThresholds()">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon :dark="dark" left>
                          $vuetify.icons.thresholds
                        </v-icon>
                        {{ $t('Set alerts thresholds') }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="isVirtualInstance(instance) && isInternalProvider(instance) && instance.state === 'on' && canCommandInstance" :disabled="askingForConsole" @click="askInstanceConsole(instance)">
                    <v-list-item-content>
                      <v-progress-circular v-if="askingForConsole" :value="jobWaitProgress" :size="24" class="mx-2" />
                      <v-list-item-title v-else>
                        <v-icon :dark="dark" left>
                          $vuetify.icons.vmConsole
                        </v-icon>
                        {{ $t('Open console on {name}...', { name: instance.name }) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="isVirtualInstance(instance) && isInternalProvider(instance) && instance.state !== 'on' && canCommandInstance">
                    <v-alert type="info" dense text>
                      {{ $t('To open a console, your instance must be started.') }}
                    </v-alert>
                  </v-list-item>

                  <v-divider class="ma-3" />

                  <v-list-item v-if="isVirtualInstance(instance) && isInternalProvider(instance) && instance.state === 'off' && canCommandInstance" @click="instanceStart()">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon :dark="dark" left>
                          $vuetify.icons.vmStart
                        </v-icon>
                        {{ $t('Start {name}...', { name: instance.name }) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="isVirtualInstance(instance) && isInternalProvider(instance) && instance.state === 'on' && canCommandInstance" @click="instanceStop(false)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon :dark="dark" left>
                          $vuetify.icons.vmStop
                        </v-icon>
                        {{ $t('Stop {name}...', { name: instance.name }) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-if="isVirtualInstance(instance) && isInternalProvider(instance) && canCommandInstance" @click="instanceReboot()">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon :dark="dark" left>
                          $vuetify.icons.vmReboot
                        </v-icon>
                        {{ $t('Reboot {name}...', { name: instance.name }) }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-list class="pa-0" three-line>
                  <v-list-item v-if="isVirtualInstance(instance) && isInternalProvider(instance) && canCommandInstance" @click="instanceStop(true)">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon :dark="dark" left>
                          $vuetify.icons.unplug
                        </v-icon>
                        {{ $t('Force stop {name}...', { name: instance.name }) }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-caption">
                        {{ $t('Caution! This shutdown will abruptly stop all running programs. It is equivalent to cutting the power.') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

            <v-row align="start" class="mt-4">
              <v-col cols="12">
                <cs-backups-panel v-if="backupsCount > 0" v-model="instance.backups" />
                <span v-else>
                  {{ $t('No backup') }}
                </span>
              </v-col>

              <v-col v-if="instance.alarms && instance.alarms.length" cols="12">
                <cs-alarms-panel v-model="instance.alarms" :worst-alarm="instance.worstAlarm" />
              </v-col>

              <v-col cols="12">
                <cs-probes-panel v-model="probes" :loading="isLoadingCard" />
              </v-col>
            </v-row>
          </div>

          <div v-show="showDetails">
            <cs-monitor-card v-show="showDetails" :vm="instance" :dense-gauges="denseMonitorCard" ref="supervision-monitor-card" @widgetRefreshed="onMonitorCardWidgetRefreshed" />
          </div>
        </v-card-text>
      </template>

      <template #buttons-prepend>
        <v-switch v-show="showDetails" v-model="denseMonitorCard" :label="$t('Dense display')" class="ml-2" />
      </template>

      <template #buttons>
        <v-btn rounded color="main-button" @click="toggleDetails">
          {{ showDetails ? $t('Return to summary') : $t('Show metrics') }}
          <cs-icon-loading v-if="isLoadingCard" right small />
        </v-btn>
      </template>
    </sca-modal-dialog>

    <cs-confirm-dialog ref="vm-confirm-dialog" />
    <cs-console-dialog ref="console-dialog" />
  </div>
</template>

<script>
import VCpu from '@/components/cs/VCpu'
import VRam from '@/components/cs/VRam'
import AlarmsPanel from '@/components/cs/AlarmsPanel'
import ProbesPanel from '@/components/cs/ProbesPanel'
import BackupsPanel from '@/components/cs/BackupsPanel'
import InstanceState from '@/components/cs/InstanceState'
import ConsoleDialog from '@/components/ConsoleDialog'
import MonitorCard from './MonitorCard'

const JOB_POLLING_DELAY = 1500 // ms
const JOB_MAX_WAIT = 10 // seconds

const REFRESH_INTERVAL_METRICS = 5 * 60 * 1000 // Refresh widget data every 5 minutes

export default {
  name: 'SupervisionDialog',
  components: {
    'cs-vcpu': VCpu,
    'cs-vram': VRam,
    'cs-alarms-panel': AlarmsPanel,
    'cs-backups-panel': BackupsPanel,
    'cs-probes-panel': ProbesPanel,
    'cs-instance-state': InstanceState,
    'cs-console-dialog': ConsoleDialog,
    'cs-monitor-card': MonitorCard
  },
  data () {
    return {
      addingVmToMonitor: false,
      askingForConsole: false,
      denseMonitorCard: false,
      isLoadingCard: false,
      instance: {},
      jobWait: 0,
      monitorCardTimeoutHandle: null,
      probes: [],
      showDetails: false,
      visible: false
    }
  },
  computed: {
    backupsCount () {
      let count = 0
      if (this.instance.backups && this.instance.backups.length > 0) {
        for (const backup of this.instance.backups) {
          if (Array.isArray(backup.backups)) count += backup.backups.length
        }
      }
      return count
    },
    canCommandInstance () {
      return this.$store.getters['$alto-roles/can'](this.instance.$can, this.$alto.API_PERMISSIONS.INSTANCE_ACTION)
    },
    canChangeThresholds () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ALARMS_THRESHOLDS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    dark () {
      return this.$store.getters['$stratus-states/isDark']
    },
    jobWaitProgress () { return this.jobWait * 10 }
  },
  methods: {
    addInstanceToMonitor () {
      if (this.$root?.widget_monitor) this.$root.widget_monitor.addInstanceToMonitor(this.instance)
    },
    async askInstanceConsole (instance) {
      try {
        this.askingForConsole = true
        const job = await this.$store.dispatch('instances/askConsole', instance)
        if (job && job.task_id) {
          // start polling
          this.jobWait = 0
          job.instance = {
            name: instance.name,
            id: instance.id,
            vCenterName: instance.vCenterName
          }
          this.askJobState(job)
        } else {
          this.jobWait = 0
          if (job) console.error('[console] error:', job)
          throw new Error(this.$t('Error: cannot open console of {name}.', instance))
        }
      } catch (error) {
        this.jobWait = 0
        this.$stratus.services.notify.error(error)
        this.askingForConsole = false
      }
    },
    askJobState (job) {
      this.jobWait++
      if (this.jobWait > JOB_MAX_WAIT) {
        this.$stratus.services.notify.error(this.$t('No response from {name} console', job.instance))
        this.jobWait = 0
        this.askingForConsole = false
        return
      }

      this.$store.dispatch('instances/askJobState', job.task_id)
        .then(response => {
          if (response.status === 'finished') {
            this.jobWait = 0
            this.askingForConsole = false
            let socketId = response.res.websocket.split('/')
            socketId = socketId[socketId.length - 1]
            const hostname = (new URL(response.res.websocket)).hostname
            this.$refs['console-dialog'].open(this.instance, hostname, socketId)
          } else if (response.status === 'failed') {
            this.jobWait = 0
            throw new Error(this.$t('Error: cannot open console of {name}.', job.instance))
          } else {
            setTimeout(() => { this.askJobState(job) }, JOB_POLLING_DELAY)
          }
        })
        .catch(error => {
          this.jobWait = 0
          console.error('Failed job:', job)
          this.$stratus.services.notify.error(error)
          this.askingForConsole = false
        })
    },
    close () {
      if (this.monitorCardTimeoutHandle) clearTimeout(this.monitorCardTimeoutHandle)
      this.visible = false
    },
    instanceStart () {
      this.$refs['vm-confirm-dialog'].open(this.instance.name, this.$t('Confirm the start of this instance.')).then((confirm) => {
        if (confirm) {
          this.$stratus.services.notify.info(this.$t('Start order send for {name}...', { name: this.instance.name }))
          this.$store.dispatch('instances/start', this.instance)
            .then()
            .catch(error => {
              this.$stratus.services.notify.error(error)
            })
        }
      })
    },
    instanceStop (force = false) {
      const warning = force ? this.$t('Caution! This shutdown will abruptly stop all running programs. It is equivalent to cutting the power.') + ' ' : ''
      this.$refs['vm-confirm-dialog'].open(this.instance.name, warning + this.$t('Confirm the stop of this instance.')).then((confirm) => {
        if (confirm) {
          this.$stratus.services.notify.info(this.$t('Stop order send for {name}...', { name: this.instance.name }))
          this.$store.dispatch('instances/stop', { ...this.instance, force })
            .then()
            .catch(error => {
              this.$stratus.services.notify.error(error)
            })
        }
      })
    },
    instanceReboot () {
      const m = this.instance.toolsOk ? 'Confirm the reboot of this instance.' : 'Confirm the reboot of this instance. (As vmtools are not installed, a hard reboot well be performed)'
      this.$refs['vm-confirm-dialog'].open(this.instance.name, this.$t(m)).then((confirm) => {
        if (confirm) {
          this.$stratus.services.notify.info(this.$t('Reboot order send for {name}...', { name: this.instance.name }))
          this.$store.dispatch('instances/reboot', this.instance)
            .then()
            .catch(error => {
              this.$stratus.services.notify.error(error)
            })
        }
      })
    },
    isInternalProvider (instance) {
      return this.$store.getters.appConfig && this.$store.getters.appConfig.cloud.providers.internals.indexOf(instance.provider) >= 0
    },
    isVirtualInstance (instance) {
      return this.$store.getters.appConfig && this.$store.getters.appConfig.cloud.virtuals.indexOf(instance.machineType) >= 0
    },
    onMonitorCardWidgetRefreshed ({ probes } = {}) {
      if (probes !== undefined) this.probes = probes
    },
    open (instance) {
      if (this.monitorCardTimeoutHandle) clearTimeout(this.monitorCardTimeoutHandle)
      this.instance = instance
      this.addingVmToMonitor = false
      this.askingForConsole = false
      this.jobWait = 0
      this.monitorCardTimeoutHandle = null
      this.showDetails = false
      this.isLoadingCard = false
      this.probes = []
      this.resetMonitoredInstance()
      this.visible = true
      this.$nextTick(() => this.refreshMonitoredInstance())
    },
    resetMonitoredInstance () {
      if (this.$refs['supervision-monitor-card']) this.$refs['supervision-monitor-card'].reset()
    },
    async refreshMonitoredInstance () {
      // Ask instance monitor to refresh itself
      if (this.$refs['supervision-monitor-card']) {
        try {
          this.isLoadingCard = true
          await this.$refs['supervision-monitor-card'].refresh()
        } catch (e) {
          console.error(e)
        } finally {
          this.isLoadingCard = false
        }
      } else {
        console.warn('[Supervision] refreshMonitoredInstance No $ref!')
      }

      this.monitorCardTimeoutHandle = setTimeout(this.refreshMonitoredInstance, REFRESH_INTERVAL_METRICS)
    },
    setInstanceThresholds () {
      if (this.instance) {
        this.$root['instance-alert-setup-alarms'].open(this.instance)
          .catch((error) => {
            console.error('[setInstanceThresholds]', error)
            this.$stratus.services.notify.error(this.$t('Error loading thresholds!'))
          })

        // Always Close menu
        this.$nextTick(() => {
          this.visible = false
        })
      }
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
    }
  }
}
</script>
