<template>
  <v-menu offset-y transition="scale-transition" :close-on-content-click="false" v-model="menuVisible">
    <template v-slot:activator="{ on }">
      <v-btn text small rounded v-on="on">
        <small>{{ currentProfile === 'default' ? $t('Default profile') : currentProfile }}</small>
      </v-btn>
    </template>
    <v-form ref="voProfileList" v-model="valid">
      <v-card id="profile-list" outlined tile>
        <v-list subheader dense>
          <v-subheader>{{ $tc('1 profile | {count} profiles', sortProfileList().length, { count: sortProfileList().length }) }}</v-subheader>
          <template v-for="(profile, index) in sortProfileList()">
            <v-list-item :key="index">
              <v-list-item-content>
                <v-btn small block rounded @click="setAsCurrent(profile)" :class="profile === currentProfile ? 'main-button' : ''" class="profile-chip text-caption">
                  {{ profile === 'default' ? $t('Default profile') : profile }}
                  <v-icon small right class="menu-icon--text">
                    {{ !preferences.layouts || preferences.layouts[profile] === 'columns' ? 'icon-display-columns' : 'icon-dashboard' }}
                  </v-icon>
                </v-btn>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn v-if="profile !== 'default' && profile !== currentProfile" @click="deleteProfile(profile)" icon small class="danger--text">
                  <v-icon color="danger" small>
                    $vuetif.icons.delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>

        <v-divider class="mx-4" />

        <v-container>
          <v-row>
            <v-col>
              <v-toolbar flat dense>
                <v-text-field v-model="newProfile" class="text-body-1" :placeholder="$t('New profile')" :rules="[$stratus.services.form.rules.singleWord, $stratus.services.form.rules.min(4), $stratus.services.form.rules.max(32)]" />

                <v-btn icon small :class="!valid ? '' : 'primary--text'">
                  <v-icon @click="addProfile" :disabled="!valid" class="menu-icon--text">
                    $vuetif.icons.add
                  </v-icon>
                </v-btn>
              </v-toolbar>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer />
          <v-btn text rounded @click="menuVisible = false">
            {{ $t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-menu>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CsPrefsProfiles',
  data () {
    return {
      valid: true,
      toggle_one: null,
      menuVisible: false,
      newProfile: ''
    }
  },
  computed: {
    currentProfile () {
      return this.profiles.indexOf(this.$store.getters.dashboardProfile) >= 0 ? this.$store.getters.dashboardProfile : 'default'
    },
    preferences () { return this.$store.getters['$alto-preferences/preferences'] },
    profiles () {
      return this.$store.getters.dashboardProfileList
    }
  },
  methods: {
    addProfile () {
      if (!this.$refs.voProfileList.validate()) {
        return
      }

      const newProfile = this.newProfile.toLowerCase()
      this.$store.dispatch('createProfile', newProfile)
        .then(() => {
          return this.$store.dispatch('$alto-preferences/load')
        })
        .then(() => {
          const prefs = this.$store.getters['$alto-preferences/preferences']
          if (!prefs.layouts) {
            prefs.layouts = {}
          }
          prefs.layouts[newProfile] = 'columns'
          return this.$store.dispatch('$alto-preferences/save', prefs)
        })
        .then(() => {
          this.$refs.voProfileList.reset()
          return this.$store.dispatch('loadProfileList')
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    deleteProfile (profileId) {
      this.$root.confirm.open(this.$t('Delete'), this.$t('Confirm deletion of «{item}»?', { item: profileId }), { color: 'red' })
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('deleteProfile', profileId)
          }
        })
    },
    pushRoute () {
      if (!this.preferences.layouts || this.preferences.layouts[this.currentProfile] === 'columns') {
        if (this.$router.name !== 'dashboard') this.$router.push('/dashboard').catch((error) => { console.warn(error) })
        window.location.reload()
      } else {
        if (this.$router.name !== 'dashboardgrid') this.$router.push('/dashboardgrid').catch((error) => { console.warn(error) })
        window.location.reload()
      }
    },
    setAsCurrent (profileId) {
      if (profileId !== this.currentProfile) {
        this.$stratus.services.notify.warning(this.$t('Reloading application...'))
        this.$store.commit('setDashboardProfile', profileId)
        this.pushRoute()
      }
    },
    sortProfileList () {
      return _.sortBy(this.profiles, profile => { return profile.toUpperCase() })
    }
  },
  async mounted () {
    await this.$store.dispatch('loadProfileList')
    await this.$store.dispatch('$alto-preferences/load')
  }
}
</script>

<style scoped>
button.profile-chip {
  text-transform: none;
  font-weight: normal;
}
</style>
