export default {
  delMonitoredItem (state) {
    state.MonitoredItems = null
  },
  getData (state, items) {
    if (Array.isArray(items)) {
      state.data.items = items
      state.data.count = state.data.items.length
    }
  },
  setMonitoredItemMetrics (state, data) {
    if (!data || !data.instance || !data.instance.id) throw new Error('API return anonymous instance!')
    state.monitoredItemsMetrics[data.instance.id] = data // FIXME: Clone data with { ...data }
  },
  API_FAILURE (state, error) {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  }
}
