<template>
  <v-app standalone>
    <div v-if="!fullscreen || ($route.name !== 'dashboard' && $route.name !== 'dashboardgrid')">
      <v-navigation-drawer v-model="drawer" app :width="drawerWidth" disable-resize-watcher>
        <div class="pa-2 d-flex">
          <v-img v-if="drawer" :src="`/img/brand-2024-${dark ? 'dark' : 'light'}.svg?1`" :max-width="200" :height="56" />
          <div :class="!drawer ? 'ml-auto mr-auto' : 'ml-auto'">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click.native.stop="drawer = !drawer" class="clickable menu-icon--text" :class="drawer ? 'rotate-once-180' : ''">
                  $vuetify.icons.right
                </v-icon>
              </template>
              <span>{{ drawer ? $t('Expand') : $t('Minimize') }}</span>
            </v-tooltip>
          </div>
        </div>

        <sca-app-menu :menu="isLogged ? menu.CONNECTED : menu.DISCONNECTED" brand-website :external-links="externalLinks" />
      </v-navigation-drawer>

      <v-slide-y-transition>
        <v-app-bar fixed flat app>
          <v-icon v-show="!drawer" dark @click.native.stop="drawer = !drawer" left class="clickable menu-icon--text">
            $vuetify.icons.right
          </v-icon>
          <img class="beta-icon" v-if="isBeta" src="/img/beta.png">
          <v-toolbar-title>
            <router-link to="/">
              <span :class="dark ? 'white--text' : 'black--text'">{{ $t('Scalair Dashboard') }}</span>
            </router-link>
          </v-toolbar-title>

          <v-spacer />
          <v-chip v-if="$store.getters.appConfig.env !== 'production'" label small>
            {{ $store.getters.appConfig.env }}-{{ $store.getters.appConfig.appVersion }}
          </v-chip>
          <v-spacer />

          <!-- Available on dashboard view only -->

          <cs-prefs-profiles v-if="isLogged && ($route.name === 'dashboard' || $route.name === 'dashboardgrid')" />

          <div>
            <v-tooltip v-if="isLogged && ($route.name === 'dashboard' || $route.name === 'dashboardgrid')" bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" @click="changeLayout" icon>
                  <v-icon small class="menu-icon--text">
                    {{ $route.name === 'dashboard' ? 'icon-dashboard' : 'icon-display-columns' }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $route.name === 'dashboard' ? $t('Arrange the widgets freely for this profile.') : $t('Arrange the widgets in columns for this profile.') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" v-if="isLogged && ($route.name === 'dashboard' || $route.name === 'dashboardgrid')" @click="toggleStatusBar" icon>
                  <v-icon small class="menu-icon--text">
                    icon-services
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Toggle Scalair services status bar') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" v-if="isLogged && ($route.name === 'dashboard')" @click="toggleWidgetsBar" icon>
                  <v-icon small class="menu-icon--text">
                    icon-widgets
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('Toggle widgets bar') }}</span>
            </v-tooltip>
          </div>

          <!-- FUTURE <sca-notifications-menu-sse v-if="isLogged" /> -->

          <sca-connection v-if="$route.name !== 'home' && isLogged" :sentry="Sentry" @login="onLogin" @logout="onLogout" show-theme show-zoom confirm-logout identifier-text="Email" identifier-is-email>
            <template slot="prepend-menu-connected">
              <v-list dense>
                <v-list-item @click="openPreferences">
                  <v-icon small left>
                    $vuetify.icons.settings
                  </v-icon>
                  <v-list-item-title>
                    {{ $t('Preferences') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item v-if="canChameleon" @click="connectAs">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small left>
                        $vuetify.icons.chameleon
                      </v-icon>
                      {{ $t('Connect as...') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>
          </sca-connection>
        </v-app-bar>
      </v-slide-y-transition>
    </div>

    <v-main class="app-main-container">
      <cs-simple-notifications group="cs-notifications" position="bottom center" :width="$vuetify.breakpoint.smAndDown ? '100%' : '50%'" @openBugReport="openBugReport" :show-bug-report-on-error="isLogged" />

      <div class="px-4">
        <sca-banners :height="appBarHeight" :delay-for-next="10" @loaded="({count}) => bannerCount = count" />
      </div>

      <v-container class="pa-0" fluid>
        <div :class="$vuetify.breakpoint.mdAndUp && !fullscreen ? 'py-2 px-3 mb-12' : ''">
          <v-btn v-show="$route.name === 'dashboard' || $route.name === 'dashboardgrid'" fab :fixed="!fullscreen" :bottom="!fullscreen" :right="!fullscreen" :small="fullscreen" :absolute="fullscreen" :outlined="fullscreen" :color="fullscreen ? 'menu-icon darken-3' : 'main-button'" @click="toggleFullscreen">
            <v-icon>{{ fullscreen ? 'icon-exit-fullscreen' : 'icon-enter-fullscreen' }}</v-icon>
          </v-btn>

          <v-snackbar multi-line top color="accent" v-model="showFullscreenHelp" :timeout="HELPER_TIMEOUT">
            <v-icon dark left>
              icon-info
            </v-icon>
            {{ $t('To automate the transition to full screen, you can install a kiosk extension in your browser.') }}
          </v-snackbar>

          <v-slide-y-transition mode="out-in">
            <router-view />
          </v-slide-y-transition>
        </div>
      </v-container>

      <div class="text-center">
        <v-bottom-sheet v-model="showText" inset hide-overlay persistent dark width="50%" :retain-focus="false">
          <v-sheet class="pa-4">
            <div v-html="sanitizedText" />
          </v-sheet>
        </v-bottom-sheet>
      </div>
      <sca-bug-report ref="bug-report" />
      <cs-confirm-dialog ref="confirm-global" />
    </v-main>

    <sca-share-secret-dialog /> <!-- used by menu -->
    <sca-reveal-secret-dialog /> <!-- used by menu -->
    <sca-connect-as-dialog ref="connect-as-dialog" />
    <sca-preferences-dialog ref="preferences-dialog" disallow-mini-app-drawer />
  </v-app>
</template>

<script>
import * as Sentry from '@sentry/browser'
import _ from 'lodash'
import crypto from 'crypto'
import { mapState } from 'vuex'

import config from '@/config'

export default {
  components: {
    'cs-prefs-profiles': () => import(/* webpackChunkName: "components" */ '@/components/cs/PrefsProfiles.vue')
  },
  data () {
    return {
      Sentry,
      bannerCount: 0,
      HELPER_TIMEOUT: 10 * 1000,
      avatarPathTemplate: _.template(this.$store.getters.appConfig.avatarApi),
      canChameleon: false,
      dark: false,
      hubAvailable: false,
      me: {},
      newNotifications: false,
      notificationsCount: 0,
      drawer: false, // Navigation drawer open/close
      fullscreen: false, // Hide all except widgets
      showFullscreenHelp: false,
      currentLocale: null,
      LOCALES: this.$stratus.defines.i18n.LOCALES,
      LOCALES_COUNTRIES: this.$stratus.defines.i18n.LOCALES_COUNTRIES
    }
  },
  computed: {
    appBarHeight () { return this.bannerCount ? '108px' : '' },
    ...mapState(['menu']),
    pageTitle () { return this.$store.getters['$stratus-states/pageTitle'] },
    avatarPath () {
      return this.avatarPathTemplate({ hash: crypto.createHash('md5').update(this.me.email || '').digest('hex') })
    },
    currentProfile () { return this.$store.getters.dashboardProfile || 'default' },
    drawerWidth () {
      const appFontSize = this.$store.getters['$stratus-states/appFontSize'] || this.$store.getters['$stratus-states/appFontSizeDefault']
      return appFontSize ? Math.max(appFontSize * 16, 250) : 250
    },
    externalLinks () {
      return [
        { href: config.urlScalairManager, help: this.$t('Manager'), img: '/img/scalair/manager.png' },
        { href: config.urlScalairStatus, help: this.$t('Scalair Status'), img: '/img/scalair/status.png' }
      ]
    },
    isChameleon () { return this.$stratus.services.auth.isChameleon() },
    isBeta () { return this.$store.getters.appConfig.beta },
    isLogged () { return this.$stratus.services.auth.isLogged() },
    preferences () { return this.$store.getters['$alto-preferences/preferences'] },
    sanitizedText () { return this.$store.getters.appBottomText },
    showText () { return !_.isEmpty(this.sanitizedText) }
  },
  methods: {
    changeLayout () {
      const _prefs = this.preferences
      if (this.$route.name === 'dashboard') {
        _prefs.layouts[this.currentProfile] = 'grid'
      } else if (this.$route.name === 'dashboardgrid') {
        _prefs.layouts[this.currentProfile] = 'columns'
      }

      this.$store.dispatch('$alto-preferences/save', _prefs)
        .then(() => {
          this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
        })
        .catch(error => {
          this.$stratus.services.notify.error(error)
        })
    },
    changeLocale (newLocale) {
      this.currentLocale = newLocale
      this.$stratus.services.localStorage.set('locale', newLocale)
      this.$stratus.i18n.locale = newLocale
      this.$stratus.dt.locale(newLocale)
      this.$vuetify.lang.current = newLocale
      this.$root.$i18n.locale = this.$store.getters['$stratus-states/locale']
      this.$i18n.locale = this.$store.getters['$stratus-states/locale']
    },
    connectAs () {
      this.$refs['connect-as-dialog'].open()
        .then(({ confirmed }) => {
          if (confirmed) window.location.reload()
        })
    },
    onLogin (error) {
      if (!error) {
        this.$router.push({ name: 'dashboard' }).catch((error) => { console.warn(error) })
        window.location.reload()
      }
    },
    onLogout (reason, error, response) {
      if (error) {
        console.error(error)
      }
      if (reason) {
        this.$stratus.services.notify.warning(this.$t(reason), { duration: -1 })
      } else {
        if (response?.fromChameleon) {
          this.$stratus.services.notify.warning(this.$t('Returning to your account.'), { duration: -1 })
        }
        this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
        window.location.reload()
      }
    },
    openBugReport (data) {
      this.$root['bug-report'].open(data)
    },
    openPreferences () {
      this.$refs['preferences-dialog'].open().then()
    },
    toggleDark () {
      this.dark = this.$store.getters['$stratus-states/isDark']
      this.$vuetify.theme.dark = this.dark
    },
    toggleFullscreen () {
      /*
      * Note:
      * Going fullscreen implies a user action. You cannot go fullscreen automatically.
      * Trying to de so will throw warning.
      * /!\ Failed to execute 'requestFullscreen' on 'Element': API can only be initiated by a user gesture.
      * To go fullscreen automatically, use a browser plugin to enter kiosk mode.
      */
      this.$store.commit('toggleFullscreen')
      this.fullscreen = this.$store.getters.fullscreen
      if (this.fullscreen && this.$store.getters.helpers('showFullscreenHelp')) {
        this.showFullscreenHelp = true
      }
    },
    toggleFullscreenHelper () {
      this.$store.commit('setHelper', { helperKey: 'showFullscreenHelp', data: !this.$store.getters.helpers('showFullscreenHelp') })
    },
    toggleStatusBar () {
      this.$store.commit('toggleStatusBarVisible')
    },
    toggleWidgetsBar () {
      this.$store.commit('toggleWidgetBarVisible')
    }
  },
  async created () {
    document.title = this.$t('Scalair Dashboard')

    if (this.isLogged) {
      this.$stratus.fetchCaches(true)
      this.$alto.fetchCaches(true)
    }
  },
  async mounted () {
    const { name, version, isValid } = this.$stratus.services.browser.checkBrowser()
    if (!isValid) {
      this.$stratus.services.notify.error(this.$t('This browser ({name} {version}) is too old or not supported. This application will not work properly!', { name, version }))
    }

    // Keep a global reference for unique dialogs
    this.$root.confirm = this.$refs['confirm-global']
    this.$root['bug-report'] = this.$refs['bug-report']
    this.$root['preferences-dialog'] = this.$refs['preferences-dialog']

    if (this.isLogged) {
      try {
        await this.$store.dispatch('$stratus-states/getMe')
        this.me = this.$store.getters['$stratus-states/me']
        this.canChameleon = this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.USERS_AUTH, this.$alto.API_PERMISSIONS.AUTH_AS) && !this.isChameleon
        await this.$store.dispatch('$alto-preferences/load')
        this.$store.dispatch('$stratus-states/applyAppFontSize')
        this.changeLocale(this.$stratus.services.localStorage.get('locale') || this.$stratus.defines.i18n.DEFAULT_LOCALE)
        this.dark = this.$store.getters['$stratus-states/isDark']
        this.$vuetify.theme.dark = this.dark
        this.fullscreen = this.$store.getters.fullscreen
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    }
  }
}
</script>
