import _ from 'lodash'

export default {
  GET_DATA: (state, { rootState, items, options }) => {
    if (items && Array.isArray(items.results)) {
      state.data.items = _.chain(items.results)
        .map(alarm => {
          alarm.state = alarm.state.toLowerCase()
          if (alarm.create_at) alarm.create_at = rootState.$stratus.dt(alarm.create_at)
          alarm.isManaged = alarm.source && alarm.source.toUpperCase() === 'CENTREON'
          return alarm
        })
        .value()

      state.data.count = items.count
      state.data.pagination = {
        totalItems: items.count
      }
    }
  },
  API_FAILURE: (state, { rootState, error }) => {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  }
}
