export default {
  setThresholds (state, data) {
    const _tmp = []
    data.thresholds.forEach((t) => {
      _tmp.push({
        name: t.name,
        values: [t.warning, t.value],
        // values: [t.config.warning, t.config.critical],
        disabled: t.disabled
      })
    })
    state.thresholds[data.name] = _tmp
  },
  API_FAILURE: (state, error) => {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  }
}
