import config from '@/config'

// Locales to load
const LOCALES = ['en-US', 'fr-FR']
const DEFAULT_LOCALE = config.locale
const LOCALES_COUNTRIES = {
  'en-US': 'us',
  'fr-FR': 'fr'
}

// Load all locales according to constant LOCALES
const messages = {}
LOCALES.forEach(v => {
  messages[v] = require(`./lang/${v}.js`).default
})

export default {
  DEFAULT_LOCALE,
  LOCALES,
  LOCALES_COUNTRIES,
  messages
}
