/*
* Create SCALAIR theme from RGB colors.
*
* Theme can be applied using approximated values from Material Design palette if you use
* import colors from 'vuetify/es5/util/colors' and colors.<color>.<saturation> variables.
*/
import alerts from './alerts'
import { INSTANCES_COLORS } from './widgets'

const light = Object.assign({
  primary: '#14C6B2',
  color0: '#141B32',
  color1: '#17E1CB',
  color2: '#F8C050',
  color3: '#68FE81',
  color4: '#013270',
  color5: '#12A7AE',
  color6: '#BFCDFC',
  secondary: '#013270',
  accent: '#12A7AE',
  info: '#C1CCF8',
  error: '#EB5128',
  danger: '#EB5128',
  warning: '#FFC133',
  success: '#6BDE81',
  'menu-icon': '#141B32',
  'button-main': '#141B32',
  'button-main-ink': '#FFFFFF',
  background: '#EEEEEE',
  'background-panel': '#F6F6F6',
  'background-component': '#FFFFFF',
  'text-ink': '#141B32'
}, alerts, INSTANCES_COLORS)

const dark = Object.assign({
  primary: '#14C6B2',
  color0: '#141B32',
  color1: '#17E1CB',
  color2: '#F8C050',
  color3: '#68FE81',
  color4: '#013270',
  color5: '#12A7AE',
  color6: '#BFCDFC',
  secondary: '#013270',
  accent: '#12A7AE',
  info: '#C1CCF8',
  error: '#EB5128',
  danger: '#EB5128',
  warning: '#FFC133',
  success: '#6BDE81',
  'menu-icon': '#F7F7EE',
  'button-main': '#F7F7EE',
  'button-main-ink': '#141B32',
  background: '#141B32',
  'background-panel': '#141B32',
  'background-component': '#141B32',
  'text-ink': '#F7F7EE'
}, alerts, INSTANCES_COLORS)

export default {
  dark: false,
  themes: {
    light,
    dark
  }
}
