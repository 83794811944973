<template>
  <cs-reset-password-form @passwordReseted="passwordReseted" />
</template>

<script>
export default {
  data () { return {} },
  mounted () {
    this.$store.commit('$stratus-states/setPageTitle', this.$t('Change your password'))
  },
  methods: {
    passwordReseted ({ error }) {
      if (!error) {
        this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
        window.location.reload()
      }
    }
  }
}
</script>
