import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

import App from './App.vue'
import { registerRouteCheck, router } from './router/'

import config from '@/config'
import VueResource from 'vue-resource'
import VueGridLayout from 'vue-grid-layout'

import http from './services/http'
import Widget from '@/components/cs/Widget'
import WidgetResponsive from '@/components/cs/WidgetResponsive'
import CSWidgets from '@/components/widgets'
import InfraStatus from '@/components/cs/InfraStatus'

function init (Vue, vuetify, store, Stratus, Alto) {
  if (config.sentryDSN?.length) {
    Sentry.init({
      dsn: config.sentryDSN,
      environment: config.env,
      integrations: [new Integrations.Vue({ Vue, attachProps: true })],
      ignoreErrors: [
        'Non-Error exception captured with keys: body, bodyText, headers, ok, status',
        'Object captured as promise rejection with keys: body, bodyText, headers, ok, status',
        'Object captured as exception with keys: body, bodyText, headers, ok, status',
        // Read https://stackoverflow.com/a/50387233/1660152
        'ResizeObserver loop limit exceeded',
        // Occurs after session expired
        'e.queue is undefined',
        'Non-Error promise rejection captured',
        'Rendering cancelled, page',
        'TypeError this.renderPage(vue-pdf/src/pdfjsWrapper)',
        'NavigationDuplicated',
        'requestAnimationFrame',
        'Hub.prototype.captureException'
      ],
      beforeSend (event, hint) {
        if (hint.originalException === 'Timeout') {
          // Ignore "UnhandledRejection: Non-Error promise rejection captured with value: Timeout"
          // This issue comes from [recaptcha bug](https://github.com/getsentry/sentry-javascript/issues/2514)
          console.log('[Sentry] ignoring "UnhandledRejection: Non-Error promise rejection captured with value: Timeout"')
          return null
        }
        return event
      }
    })
    Stratus.services.debug.log('%c Sentry %c activated! %c')
    Stratus.services.debug.info(`Sentry DSN: %c ${config.sentryDSN} %c`)
  } else {
    Stratus.services.debug.warn('Sentry %c disabled! %c')
  }

  Vue.use(VueResource)
  Vue.use(VueGridLayout)

  Vue.component('scalair-widget', Widget)
  Vue.component('scalair-widget-responsive', WidgetResponsive)
  Vue.component('infra-status', InfraStatus)
  Vue.use(CSWidgets)

  new Vue({
    i18n: Alto.i18n,
    router,
    store,
    vuetify,
    created () {
      // Update framework modules with ready to all-inclusive translations
      this.$stratus.services.array.register(Alto.i18n, this.$stratus.dt)
      // Allow frameworks access from store
      this.$store.commit('loadStratus')
      this.$store.commit('loadAlto')
      this.$stratus.services.debug.info(`Stratus version: %c ${Stratus.version} %c`)
      this.$stratus.services.debug.info(`Alto version: %c ${Alto.version} %c`)
      registerRouteCheck(this.$stratus, router)
      // Register HTTP interceptors
      this.$stratus.services.http = Vue.use(this.$stratus.services.http, this.$stratus.services.auth)
      Vue.use(http, this.$stratus.services.auth)
    },
    render: h => h(App)
  }).$mount('#app')
}

export default { init }
