export default {
  async getData ({ commit, rootState }) {
    let instances, alarms, probes
    try {
      instances = await rootState.$stratus.services.api.get('/instances?limit=0')
    } catch (error) {
      commit('API_FAILURE', error)
      return
    }

    commit('GET_DATA', { instances, alarms, probes, rootState })
  },
  getAllAlarms ({ commit, rootState }) {
    return rootState.$stratus.services.api.get('/alarms?limit=0')
      .then(alarms => {
        commit('SET_ALARMS', { alarms, rootState })
      })
      .catch(error => {
        commit('API_FAILURE', error)
      })
  },
  getAllProbes ({ commit, rootState }) {
    return rootState.$stratus.services.api.get('/alarms/probes?limit=0')
      .then(probes => {
        commit('SET_PROBES', { probes, rootState })
      })
      .catch(error => {
        commit('API_FAILURE', error)
      })
  }
}
