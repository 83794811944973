/*
* This module exports a chartjs LINE chart.
* Read https://vue-chartjs.org/guide/#vue-single-file-components
*
* References:
* - https://vue-chartjs.org/
* - https://www.chartjs.org/
*/
import _ from 'lodash'
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

const DEFAULT_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      type: 'time',
      distribution: 'linear',
      time: {
        displayFormats: {
          year: 'YYYY',
          month: 'll',
          week: 'll',
          day: 'll',
          hour: 'LT',
          minute: 'LT',
          second: 'LTS'
        },
        tooltipFormat: 'LT'
      }
    }],
    yAxes: [{
      type: 'linear'
    }]
  },
  legend: {
    display: true,
    position: 'bottom'
  }
}

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    options: { type: Object, default: () => DEFAULT_OPTIONS }
  },
  data () {
    return {}
  },
  methods: {
    update () {
      if (this.$data._chart) {
        this.$data._chart.update()
      }
    },
    displayDataset (id, isHidden) {
      try {
        _.forEach(this.$data._chart.data.datasets, dataset => {
          if (dataset.datasetId === id) {
            dataset.hidden = isHidden
          }
        })
        this.update()
      } catch (e) {
        console.error(e)
      }
    },
    getSize () {
      return {
        height: this.$data._chart.canvas.height,
        width: this.$data._chart.canvas.width
      }
    },
    resize (height, width) {
      this.h = height
      this.w = width
      this.$data._chart.canvas.parentNode.style.height = `${height}px`
      this.$data._chart.canvas.parentNode.style.width = `${width}px`
      this.$data._chart.canvas.parentNode.style.position = 'relative'
    }
  },
  mounted () {
    // Datasets is this.chartData that is created in the mixin.
    this.renderChart(this.chartData, this.options)
  }
}
