<template>
  <v-row align="center" v-on="$listeners" :dense="!large">
    <v-col class="shrink">
      <v-avatar class="instance-resource-avatar" :class="dark ? 'theme--dark' : 'theme--light'">
        <v-icon :large="large" class="vram--text">
          icon-ram-filled
        </v-icon>
      </v-avatar>
    </v-col>
    <v-col>
      <span :class="large ? 'text-h6' : ''">
        {{ $tc('No vRAM!|{count} GB vRAM', (value > 0 ? 2 : 0), { count: value }) }}
      </span>
      <v-menu left offset-y v-if="helpText" transition="slide-y-transition" open-on-hover :close-on-content-click="false" :nudge-width="200">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" small right class="white--text">
            icon-info
          </v-icon>
        </template>
        <v-card flat tile>
          <v-card-text class="text-caption">
            {{ helpText }}
          </v-card-text>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'VRam',
  props: {
    dark: { type: Boolean, default: false },
    helpText: { type: String, default: '' },
    large: { type: Boolean, default: false },
    text: { type: String, default: '' },
    value: { type: Number, default: 0 }
  },
  data () {
    return {}
  },
  computed: {
    valueGB () {
      return _.round(this.value, 2)
    }
  }
}
</script>
