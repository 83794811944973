export default {
  /**
  * Get widget content
  * @method
  * @param  {Object} context Store instance, read Vuex documentation {@link https://vuex.vuejs.org/fr/actions.html}
  * @param  {[type]} options [description]
  * @return {[type]} [description]
  */
  getData ({ commit, rootState }, options) {
    // return rootState.$stratus.services.api.get(rootState.$stratus.services.api.encodeQueryString(options.criteria, '/tickets/old'))
    return rootState.$stratus.services.api.get(rootState.$stratus.services.api.encodeQueryString(options.criteria, '/tickets'))
      .then((response) => commit('GET_DATA', { rootState, items: response, options }))
      .catch((error) => commit('API_FAILURE', { rootState, error }))
  }
}
