<template>
  <div :id="id" :ref="id" />
</template>

<script>
import _ from 'lodash'
import Konva from 'konva'
import { getAlarmStateColor } from '@/theme/alerts'
import { getInstanceStateColor } from '@/theme/widgets'

const WIDGET_SIZE = [
  { width: 18, height: 12, fontSize: 11 },
  { width: 36, height: 24, fontSize: 16 },
  { width: 44, height: 28, fontSize: 20 }
]
const ITEM_MARGIN_X = 0
const ITEM_MARGIN_Y = 0

export default {
  name: 'CsSupervisionCanvas',
  props: {
    id: { type: String, required: true },
    items: { type: Array, required: true },
    widgetSize: { type: Number, default: 0 }
  },
  data () {
    return {
      canvasWidth: 0,
      clickedInstance: {},
      itemPerLine: 1,
      lineCount: 1,
      menuX: 0,
      menuY: 0,
      prevItemPerLine: 0,
      prevLineCount: 0
    }
  },
  watch: {
    canvasWidth (newWidth, oldWidth) {
      this.computeCanvasGrid(newWidth)
    }
  },
  computed: {
    dark () {
      return this.$store.getters['$stratus-states/isDark']
    }
  },
  methods: {
    computeCanvasGrid (width) {
      this.itemPerLine = Math.trunc((width) / (WIDGET_SIZE[this.widgetSize].width + ITEM_MARGIN_X))
      this.lineCount = this.itemPerLine ? Math.ceil(this.items.length / this.itemPerLine) : 1
      if (this.prevItemPerLine !== this.itemPerLine || this.prevLineCount !== this.lineCount) {
        this.prevItemPerLine = this.itemPerLine
        this.prevLineCount = this.lineCount
        // Compute the size of the canvas
        this.stage.width(width)
        const margin = this.widgetSize === 0 ? 32 : 0
        this.stage.height((this.lineCount) * (WIDGET_SIZE[this.widgetSize].height + ITEM_MARGIN_Y) + margin)
        this.refresh()
      }
    },
    displayInstanceDialog (event, node) {
      if (node && this.items[node.index]) {
        this.menuX = event.clientX
        this.menuY = event.clientY
        this.clickedInstance = this.items[node.index]
        this.$root['supervision-instance-dialog'].open(this.clickedInstance)
      }
    },
    installCanvas () {
      this.container = this.$refs[this.id]
      this.stage = new Konva.Stage({
        container: this.id,
        width: this.container.clientWidth,
        height: 200
      })

      this.squaresLayer = new Konva.Layer()
      this.alarmsLayer = new Konva.Layer()

      this.stage.on('click', ev => {
        this.displayInstanceDialog(ev, ev.target)
      })

      this.stage.on('tap', ev => {
        this.displayInstanceDialog(ev, ev.target)
      })

      this.stage.add(this.squaresLayer)
      this.stage.add(this.alarmsLayer)

      this.installTooltip()
    },
    installTooltip () {
      this.stage.on('mouseover mousemove dragmove', (ev) => {
        const node = ev.target
        // Display tooltip only when mouse pointer is over a VM rect.
        if (node && node.attrs && !node.attrs.container && this.items[node.index]) {
          let t = `<b>${this.items[node.index].customerId} / ${this.items[node.index].name}</b>` +
            ` (${this.$t(this.items[node.index].machineType)})<br>` +
            `${this.$t('state_' + this.items[node.index].state)}<br>`
          if (this.items[node.index].alarms && this.items[node.index].alarms.length) t += `<b>${this.$tc('{count} alarms', this.items[node.index].alarms.length, { count: this.items[node.index].alarms.length })}</b><br>`
          if (this.items[node.index].vdcName) t += `${this.items[node.index].vdcName}<br>`
          if (this.items[node.index].vcpu) t += `${this.$t('vCPU {n}', { n: this.items[node.index].vcpu })}<br>`
          if (this.items[node.index].vram) t += `${this.$t('vRAM {n}GB', { n: _.round(this.items[node.index].vram, 1) })}<br>`
          if (this.items[node.index].OSType) t += `${this.items[node.index].OSType}<br>`

          this.$store.commit('setAppBottomText', t)
        }
      })

      this.stage.on('mouseout', () => {
        this.$store.commit('setAppBottomText', '')
      })
    },
    refresh () {
      try {
        this.$emit('drawing', true)
        this.$nextTick(() => {
          this.squaresLayer.destroyChildren()
          this.alarmsLayer.destroyChildren()

          this.items.forEach((item, index) => {
            this.squaresLayer.add(
              new Konva.Rect({
                x: (index % this.itemPerLine) * (WIDGET_SIZE[this.widgetSize].width + ITEM_MARGIN_X),
                y: Math.trunc(index / this.itemPerLine) * (WIDGET_SIZE[this.widgetSize].height + ITEM_MARGIN_Y),
                width: WIDGET_SIZE[this.widgetSize].width,
                height: WIDGET_SIZE[this.widgetSize].height,
                fill: item.worstAlarm && item.worstAlarm.state && getAlarmStateColor(item.worstAlarm.state) ? getAlarmStateColor(item.worstAlarm.state) : (item.state !== 'on' && item.state !== 'off') ? getInstanceStateColor('unknown') : getInstanceStateColor(item.state.toLowerCase()),
                shadowBlur: 1,
                opacity: item.state === 'on' ? 1 : item.state === 'off' ? 0.6 : 0.8
              })
            )

            this.alarmsLayer.add(new Konva.Text({
              x: (index % this.itemPerLine) * (WIDGET_SIZE[this.widgetSize].width + ITEM_MARGIN_X),
              y: Math.trunc(index / this.itemPerLine) * (WIDGET_SIZE[this.widgetSize].height + ITEM_MARGIN_Y),
              width: WIDGET_SIZE[this.widgetSize].width,
              height: WIDGET_SIZE[this.widgetSize].height,
              align: 'center',
              verticalAlign: 'middle',
              text: (item.alarms || []).length || '',
              fontSize: WIDGET_SIZE[this.widgetSize].fontSize,
              fontStyle: 'bold',
              fontFamily: 'monospace,fixed,courier new',
              ellipsis: false,
              wrap: 'none',
              fill: '#141B32'
            }))
          })

          this.squaresLayer.batchDraw()
          this.alarmsLayer.batchDraw()

          this.$emit('drawing', false)
        })
      } catch (e) {
        console.error(e)
      }
    },
    watchWidth () {
      if ((this._w !== this.$el.offsetWidth || this._h !== this.$el.offsetHeight) || (this._w !== window.Width || this._h !== window.Height)) {
        this._w = this.$el.offsetWidth
        this._h = this.$el.offsetHeight
        if (this._w > 0 && this._h > 0) this.computeCanvasGrid(this.container.clientWidth)
      }
      setTimeout(this.watchWidth, 2000)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResizeFunction)
  },
  mounted () {
    this.refresh = _.debounce(this.refresh, 500)
    this.installCanvas()
    this.computeCanvasGrid(this.container.clientWidth)

    // Hook an event handler to capture window resizing and redraw canvas
    this.$nextTick(() => {
      this.onResizeFunction = window.addEventListener('resize', () => {
        if (!isNaN(this.container.clientWidth)) {
          this.canvasWidth = this.container.clientWidth
        }
      })
    })

    this._w = 1
    this._h = 1
    this.watchWidth()
  }
}
</script>
