<template>
  <cs-expand-panel @expand="onExpand">
    <div slot="header" class="d-flex align-center">
      {{ $t('Alarms') }}
      <v-chip v-show="value.length" small class="ml-1" :class="`${getAlarmStateClass(worstAlarm.state)} ${getAlarmStateClass(worstAlarm.state)}_text--text`">
        {{ value.length }}
      </v-chip>
      <slot name="header-append" />
    </div>

    <v-sheet slot="content" v-if="value.length" class="transparent">
      <div class="mt-2 d-flex flex-column justify-start align-left">
        <div v-for="(alarm, index) in value" :key="index">
          <v-hover v-slot="{ hover }">
            <div :class="{ focused: hover }" class="d-flex align-center mb-1">
              <v-chip label small :class="`${getAlarmStateClass(alarm.state)} ${getAlarmStateClass(alarm.state)}_text--text`">
                {{ $t('severity-' + alarm.state) }}
              </v-chip>
              <span class="text-caption mx-1">
                {{ alarm.output }}
              </span>
              <span class="text-caption">({{ $stratus.dt(alarm.create_at).fromNow() }})</span>
            </div>
          </v-hover>
        </div>
      </div>
    </v-sheet>
  </cs-expand-panel>
</template>

<script>
import { getAlarmStateClass, getAlarmStateColor } from '@/theme/alerts'

export default {
  name: 'CsAlarmsPanel',
  props: {
    value: { type: Array, default () { return [] } },
    worstAlarm: { type: Object, default () { return {} } }
  },
  data () {
    return {
      getAlarmStateClass,
      getAlarmStateColor
    }
  },
  computed: {
    dark () { return this.$store.getters['$stratus-states/isDark'] }
  },
  methods: {
    onExpand (expanded) {
      this.$emit('expand', expanded)
    }
  }
}
</script>
