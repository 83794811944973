import _ from 'lodash'

export default {
  GET_DATA: (state, { rootState, items, options }) => {
    if (items && Array.isArray(items.results)) {
      const _tmp = []
      let _item
      // const orderByCommentsIndex = 0 // Index in array to insert ticket with recent comments
      _.forEach(items.results, ticket => {
        if (ticket.name) {
          // Sort comments by descending creation date
          // _comment = _.chain(ticket.comments)
          //   .sortBy(['-created'])
          //   .map(comment => {
          //     comment.createdAt = rootState.$stratus.dt(comment.createdAt)
          //     return comment
          //   })
          //   .head() // Keep latest comment
          //   .value()

          _item = {
            code: ticket.code,
            id: ticket.id,
            name: ticket.name,
            id_owner: ticket.id_owner,
            severity: ticket.severity,
            state: ticket.state.toLowerCase(),
            created_at: rootState.$stratus.dt(ticket.created_at),
            update_at: rootState.$stratus.dt(ticket.update_at),
            update_by: ticket.update_by
            // blink: !ticket.comments || ticket.comments.length === 0, // There must be one comment!
            // comments: {
            //   latest: _comment, // Keep latest comment
            //   count: ticket.comments ? ticket.comments.length : 0,
            //   blink: _comment ? rootState.$stratus.dt().diff(_comment.createdAt) <= state.BLINK_DELAY : false
            // }
          }
          // Add ticket into internal data
          // if (options && options.orderByComments && _item.comments.blink) {
          //   // ordered by ticket recent comment and createdAt time
          //   _tmp.splice(orderByCommentsIndex, 0, _item)
          //   orderByCommentsIndex++
          // } else {
          // ordered by ticket createdAt time
          _tmp.push(_item)
          // }
        }
      })
      state.data.items = _tmp
      state.data.count = _tmp.length
      state.data.pagination = {
        totalItems: items.count
      }
    }
  },
  API_FAILURE: (state, { rootState, error }) => {
    if (error.status === 401) {
      console.warn('[API] status 401', error)
    } else throw error
  }
}
