import alarms from './alarms'
import monitor from './monitor'
import monitorSingle from './monitorSingle'
import supervision from './supervision'
import tickets from './tickets'

export default {
  namespaced: true,
  state: {
    // Ordered list of available widgets
    list: ['supervision', 'alarms', 'tickets', 'monitor', 'monitorSingle']
  },
  getters: {
    list: state => {
      return state.list
    }
  },
  modules: {
    alarms,
    monitor,
    monitorSingle,
    supervision,
    tickets
  }
}
