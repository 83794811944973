<template>
  <cs-expand-panel @expand="onExpand">
    <div slot="header" class="d-flex align-center">
      {{ $t('Probes') }}
      <v-chip small class="ml-1">
        <cs-icon-loading v-if="loading" small />
        <span v-else>
          {{ value.length }}
        </span>
      </v-chip>
    </div>

    <v-sheet slot="content" v-if="value.length" class="transparent">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{ $t('Probe') }}
              </th>
              <th class="text-left">
                {{ $t('Output') }}
              </th>
              <th class="text-left">
                {{ $t('Since') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(probe, index) in value" :key="index">
              <td>{{ probe.serviceName || '-' }}</td>
              <td>{{ probe.output }}</td>
              <td class="text-no-wrap">
                {{ $stratus.dt(probe.update_at).format('l LT') }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </cs-expand-panel>
</template>

<script>
import { getAlarmStateClass } from '@/theme/alerts'

export default {
  name: 'CsAlarmsPanel',
  props: {
    loading: { type: Boolean, default: false },
    value: { type: Array, default () { return [] } }
  },
  data () {
    return {
      getAlarmStateClass
    }
  },
  computed: {
    dark () { return this.$store.getters['$stratus-states/isDark'] }
  },
  methods: {
    onExpand (expanded) {
      this.$emit('expand', expanded)
    }
  }
}
</script>
