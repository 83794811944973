<template>
  <div>
    <v-row class="px-1" align="center">
      <v-col class="flex" sm="12" md="3">
        <span class="clickable text-subtitle-1" @click="openScalairStatus()">
          {{ $t('Services Status') }}
          <v-icon small>
            icon-outbound-link
          </v-icon>
        </span>
        <div class="text-caption">
          {{ $t('Last update:') }} {{ updatedAt.format('lll') }}
        </div>
      </v-col>

      <v-col cols="12" md="9">
        <v-row dense>
          <v-col v-if="!status.services || status.services.length === 0" cols="12">
            <v-progress-circular small indeterminate />
          </v-col>

          <v-col class="pa-0 pl-1 pb-1" v-for="(service, index) in status.services" :key="index">
            <v-chip v-if="service.currentEvent" :class="service.style.class + ' py-0 px-1'" @click="toggleCurrentEvent(index)">
              <v-icon small :class="service.style.class + '_text--text'">
                {{ service.style.icon }}
              </v-icon>
              <span :class="service.style.class + '_text--text mx-2'"> {{ $t('scalair-status-' + service.name) }}
              </span>
              <v-icon :class="service.style.class + '_text--text' + (showCurrentEvent[index] ? ' rotate-once-180' : '')">
                $vuetif.icons.expand
              </v-icon>
            </v-chip>

            <v-chip v-else :class="service.style.class + ' py-0 px-1'">
              <v-icon small :class="service.style.class + '_text--text'">
                {{ service.style.icon }}
              </v-icon>
              <span :class="service.style.class + '_text--text mx-2'"> {{ $t('scalair-status-' + service.name) }}
              </span>
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-for="(service, index) in status.services" :key="index" justify="space-between">
      <v-slide-y-transition>
        <v-col v-if="showCurrentEvent[index]">
          <span :class="`${service.style.class} ${service.style.class}_text--text px-2`"> {{ $t('scalair-status-' + service.name) }}</span>
          — {{ $t('event-type-' + service.currentEvent.type) }}
          — {{ $t('event-status-' + service.currentEvent.status) }}
          <p v-html="service.currentEvent.description" />
        </v-col>
      </v-slide-y-transition>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'InfraStatus',
  data () {
    return {
      showCurrentEvent: {},
      updatedAt: this.$stratus.dt()
    }
  },
  computed: {
    status () { return this.$store.getters['infraStatus/current'] },
    style (status) {
      return this.$store.getters['infraStatus/current']
    }
  },
  methods: {
    openScalairStatus () {
      window.open(this.$store.getters.appConfig.infraStatusBaseUrl, '_srs')
    },
    async refreshStatus () {
      this.$store.dispatch('infraStatus/getCurrent')
      this.updatedAt = this.$stratus.dt()

      _.forEach(this.status.services, (service, index) => {
        this.showCurrentEvent[index] = false
      })

      setTimeout(this.refreshStatus, 5 * 60 * 1000) // Every 5 minutes
    },
    toggleCurrentEvent (index) {
      this.$set(this.showCurrentEvent, index, !this.showCurrentEvent[index])
      this.$forceUpdate()
    }
  },
  mounted () {
    this.refreshStatus()
  }
}
</script>
