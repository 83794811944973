<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row>
      <v-col cols="12">
        <v-menu offset-overflow :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn :fixed="!fullscreen" :outlined="fullscreen" :absolute="fullscreen" :bottom="!fullscreen" :right="fullscreen" :small="fullscreen" :color="fullscreen ? 'menu-icon darken-3' : 'primary'" fab dark v-on="on">
              <v-icon>$vuetify.icons.add</v-icon>
            </v-btn>
          </template>
          <v-card tile class="pa-2" :width="300">
            <div v-for="widgetType in availableWidgetTypes" :key="widgetType">
              <v-row v-if="widgetsProps[widgetType].singleton" align="center" no-gutters dense>
                <v-col>
                  <v-icon :class="'pl-2 widget-' + widgetType" left>
                    {{ widgetsProps[widgetType].icon }}
                  </v-icon>
                  <span class="v-label">{{ $t(widgetsProps[widgetType].name) }}</span>
                </v-col>
                <v-col v-if="widgetsConfig[widgetType]" class="shrink">
                  <v-switch dense inset flat v-model="widgetsConfig[widgetType].visible" @change="toggleWidget(widgetsConfig[widgetType], widgetType)" />
                </v-col>
              </v-row>

              <v-row v-else dense no-gutters align="center">
                <v-col>
                  <v-icon :class="'pl-2 widget-' + widgetType" left>
                    {{ widgetsProps[widgetType].icon }}
                  </v-icon>
                  <span class="v-label">{{ $t(widgetsProps[widgetType].name) }}</span>
                </v-col>
                <v-col class="shrink mr-4">
                  <v-btn @click="addWidget(widgetType)" icon>
                    <v-icon class="menu-icon--text">
                      $vuetify.icons.add
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-menu>
      </v-col>

      <v-col cols="12" ref="dashboard-grid-layout">
        <grid-layout v-if="showLayout && layout.length > 0" id="dashboard-grid-layout" :layout.sync="layout" :auto-size="layout_autoSize" :breakpoints="layout_breakpoints" :col-num="layout_colNum" :cols="layout_cols" :is-draggable="layout_isDraggable" :is-mirrored="layout_isMirrored" :is-resizable="layout_isResizable" :max-rows="layout_maxRows" :margin="layout_margin" :prevent-collision="layout_preventCollision" :responsive="layout_responsive" :row-height="layout_rowHeight" :use-css-transforms="layout_useCssTransforms" :vertical-compact="layout_verticalCompact" @layout-updated="layoutUpdated">
          <grid-item v-for="item in layout" :x="item.x" :y="item.y" :w="item.w" :h="item.h" :i="item.i" :key="item.i" @resized="resized" @container-resized="containerResized">
            <widget-supervision v-if="item.widgetType === 'supervision' && canShowSupervision" ref="widget_supervision" />
            <widget-alarms v-else-if="item.widgetType === 'alarms' && canShowAlarms" ref="widget_alarms" />
            <widget-tickets v-else-if="item.widgetType === 'tickets' && canShowTickets" ref="widget_tickets" />
            <widget-monitor v-else-if="item.widgetType === 'monitor' && canShowMonitor" ref="widget_monitor" />
            <widget-monitor-single v-else-if="item.widgetType === 'monitorSingle' && canShowMonitor" :ref="item.i" :widget-id="item.configId" @removeWidget="removeWidget" @widgetRefreshed="onWidgetRefreshed" />
          </grid-item>
        </grid-layout>

        <v-row class="mt-2 mx-1" v-else align="center">
          <v-col cols="12">
            <v-card outlined tile>
              <v-card-title>
                <v-row class="fill-height" align="center" justify="start">
                  <v-col cols="6">
                    <v-img height="116" max-height="116" min-height="48" contain :src="dark ? '/img/dashboard-dark.svg' : '/img/dashboard-light.svg'" />
                  </v-col>
                  <v-col>
                    <h3 class="text-h6">
                      {{ $t('Dashboard') }}
                    </h3>
                    <p class="text-caption mt-6" v-html="$t('Use the(+) button to place widgets on the dashboard.<br><br><b>Move a widget</b> by grabbing it by its title or an empty part and dropping it at the desired location.<br><br><b>Resize a widget</b> by grabbing its handle in the bottom right corner and releasing it when you reach the desired size.')" />
                  </v-col>
                </v-row>
              </v-card-title>

              <v-list three-line>
                <v-list-item v-if="canShowSupervision">
                  <v-list-item-action>
                    <v-avatar :dark="dark" class="widget-background-supervision">
                      <v-icon small :dark="dark" class="widget-supervision">
                        icon-supervision-filled
                      </v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('Supervision') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('The Supervision widget gives you a grid that will display current alarms status for all your instances.') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="canShowAlarms">
                  <v-list-item-action>
                    <v-avatar :dark="dark" class="widget-background-alarms">
                      <v-icon small :dark="dark" class="widget-alarms">
                        icon-alarm-filled
                      </v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('Alarms') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('The Alarms widget gives you an up-to-date list of all alarms that your instances raised.') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="canShowTickets">
                  <v-list-item-action>
                    <v-avatar :dark="dark" class="widget-background-tickets">
                      <v-icon small :dark="dark" class="widget-tickets">
                        $vuetif.icons.ticket
                      </v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('Tickets') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('The Tickets widget allow to keep an eye on all your opened tickets shared with our support team.') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="canShowMonitor">
                  <v-list-item-action>
                    <v-avatar :dark="dark" class="widget-background-monitor">
                      <v-icon small :dark="dark" class="widget-monitor">
                        icon-eye-filled
                      </v-icon>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('Monitor') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('The Monitor widget gives you an up-to-date detailed view of the resources that an instance is consuming.') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <cs-supervision-dialog ref="supervision-instance-dialog" />
    <cs-instance-alert-setup ref="instance-alert-setup-alarms" />
  </v-container>
</template>

<script>
import _ from 'lodash'
import VueGridLayout from 'vue-grid-layout'

import InstanceThresholdsSetup from '@/components/cs/InstanceThresholdsSetup'
import SupervisionDialog from '@/components/widgets/SupervisionDialog'

const MAX_COLS = 48
const ROW_HEIGHT = 16
const DEFAULT_WIDTH = 8
const DEFAULT_HEIGHT = 8

export default {
  name: 'DashboardGrid',
  components: {
    'grid-layout': VueGridLayout.GridLayout,
    'grid-item': VueGridLayout.GridItem,
    'cs-instance-alert-setup': InstanceThresholdsSetup,
    'cs-supervision-dialog': SupervisionDialog
  },
  data () {
    return {
      layout_autoSize: true, // Says if the container height should swells and contracts to fit contents.
      layout_breakpoints: { lg: 1904, md: 1264, sm: 960, xs: 600, xxs: 0 }, // Breakpoints defined for responsive layout. Sets widths on which column number changes
      layout_colNum: MAX_COLS, // Says how many columns the grid has. The value should be a natural number.
      layout_cols: { lg: MAX_COLS, md: 10, sm: 6, xs: 4, xxs: 2 }, // Defines number of columns for each breakpoint
      layout_isDraggable: true, // Says if the grids items are draggable.
      layout_isMirrored: false, // Says if the RTL/LTR should be reversed.
      layout_isResizable: true, // Says if the grids items are resizable.
      layout_maxRows: Infinity, // Says what is a maximal number of rows in the grid.
      layout_margin: [4, 4], // Says what are the margins of elements inside the grid. The value must be a two-element Array of Number. Each value is expressed in pixels. The first element is a margin horizontally, the second element is a vertical margin.
      layout_preventCollision: false, // Says if grid items will move when being dragged over.
      layout_responsive: false, // Says if the layout should be responsive to window width
      layout_rowHeight: ROW_HEIGHT, // Says what is a height of a single row in pixels.
      layout_useCssTransforms: true, // Says if the CSS transition-property: transform; should be used.
      layout_verticalCompact: true, // Says if the layout should be compact vertically.
      availableWidgetTypes: [],
      layout: [],
      showLayout: false,
      widgetsConfig: {},
      widgetsList: [],
      widgetsProps: {},
      widgetsSizes: {} // Allow to restore widget content by calling their resizeContent methods
    }
  },
  computed: {
    canShowAlarms () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ALARMS, this.$alto.API_PERMISSIONS.LIST)
    },
    canShowMonitor () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.INSTANCES, this.$alto.API_PERMISSIONS.LIST) && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.METRICS, this.$alto.API_PERMISSIONS.LIST)
    },
    canShowSupervision () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.INSTANCES, this.$alto.API_PERMISSIONS.LIST)
    },
    canShowTickets () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS, this.$alto.API_PERMISSIONS.LIST)
    },
    dark () { return this.$store.getters['$stratus-states/isDark'] },
    fullscreen () { return this.$store.getters.fullscreen }
  },
  methods: {
    addWidget (widgetType, { x = 0, y = 0, w = DEFAULT_WIDTH, h = DEFAULT_HEIGHT } = {}) {
      const uuid = this.$stratus.uuid()
      const widgetId = widgetType + '-' + uuid

      // Setup widget defaults attributes
      this.widgetsConfig[widgetId] = {
        visible: true,
        layout: {
          i: uuid, // UNIQUE Identifier for rendering
          widgetType, // Use to render component according to its type
          configId: widgetId, // UNIQUE Identifier to find configuration
          x,
          y,
          w,
          h,
          isStatic: false
        }
      }
      // Add widget to layout
      this.layout.push(this.widgetsConfig[widgetId].layout)
      this.saveWidgetConfig(widgetId)
    },
    canIShow (widgetName) {
      return this.widgetsProps[widgetName].layout.indexOf('grid') >= 0 && this.$store.getters['$alto-roles/canIWidget'](widgetName)
    },
    layoutUpdated (newLayout) {
      this.$store.dispatch('loadProfilePreferences', 'widgets')
        .then(result => {
          // Refresh internal config with freshly loaded data
          if (result) {
            this.widgetsConfig = Object.assign(this.widgetsConfig, result)
            try {
              _.forEach(newLayout, item => {
                this.widgetsConfig[item.configId].layout = {
                  x: item.x,
                  y: item.y,
                  w: item.w,
                  h: item.h,
                  isStatic: item.isStatic
                }
                this.saveWidgetConfig(item.configId)
              })
            } catch (error) {
              console.error(error)
            }
          }
        })
    },
    // grid-item events
    containerResized (i, newH, newW, newHPx, newWPx) {
      // Store widget size
      this.widgetsSizes[i] = { h: Number(newH), w: Number(newW), hpx: Number(newHPx), wpx: Number(newWPx) }
    },
    resized (i, newH, newW, newHPx, newWPx) {
      const ref = this.$refs[i]
      if (ref) {
        const gridItem = _.find(this.layout, { i })
        let widgetConf = null
        if (gridItem && gridItem.configId) {
          widgetConf = this.widgetsConfig[gridItem.configId]
        }
        if (_.isArray(ref)) {
          if (ref[0].resizeContent) ref[0].resizeContent(i, newH, newW, newHPx, newWPx, widgetConf)
        } else {
          if (ref.resizeContent) ref.resizeContent(i, newH, newW, newHPx, newWPx, widgetConf)
        }
      } else {
      }
    },
    async removeWidget (widgetId) {
      try {
        if (this.removeWidgetFromLayout(widgetId)) {
          const result = await this.$store.dispatch('delWidgetConfig', { widgetId })
          this.widgetsConfig = result
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    removeWidgetFromLayout (widgetType) {
      // Remove widget from layout
      let found = -1
      let i = 0
      while (found < 0 && i < this.layout.length) {
        if (this.layout[i].configId === widgetType) found = i
        i++
      }
      if (found >= 0) {
        this.layout.splice(found, 1)
      }

      return found >= 0
    },
    refreshDisplay (widgetsConfig) {
      // This will render display again
      // Read https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
      this.widgetsConfig = Object.assign({}, widgetsConfig)
    },
    onWidgetRefreshed ({ ref, configId }) {
      // Restore size of fully loaded widget
      const gridItem = _.find(this.layout, { configId })
      const ws = this.widgetsSizes[gridItem.i]
      // Compute up-to-date size
      this.resized(gridItem.i, ws.h, ws.w, ws.hpx, ws.wpx)
    },
    async saveWidgetConfig (widgetId) {
      try {
        if (!this.widgetsConfig[widgetId].widgetType) this.widgetsConfig[widgetId].widgetType = widgetId.split('-')[0]
        await this.$store.dispatch('saveWidgetConfig', { widgetId, config: this.widgetsConfig[widgetId] })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    },
    toggleWidget (widget, widgetType) {
      if (!widget.widgetType) widget.widgetType = widgetType // Keep internal type
      if (this.widgetsConfig[widget.widgetType] && !this.widgetsConfig[widget.widgetType].visible) {
        if (this.removeWidgetFromLayout(widget.widgetType)) {
          this.saveWidgetConfig(widget.widgetType)
        }
      } else {
        // Add widget to layout
        this.layout.push({
          i: widget.widgetType, // UNIQUE Identifier for rendering
          widgetType: widget.widgetType, // Use to render component according to its type
          configId: widget.widgetType, // UNIQUE Identifier to find configuration
          x: 0,
          y: 0,
          w: DEFAULT_WIDTH,
          h: DEFAULT_HEIGHT,
          isStatic: false
        })
        this.saveWidgetConfig(widget.widgetType)
      }
    },
    isAllowed (widget) {
      switch (widget) {
        case 'alarms':
          return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ALARMS, this.$alto.API_PERMISSIONS.LIST)
        case 'monitor':
        case 'monitorSingle':
          return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.INSTANCES, this.$alto.API_PERMISSIONS.LIST) && this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.METRICS, this.$alto.API_PERMISSIONS.LIST)
        case 'supervision':
          return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.INSTANCES, this.$alto.API_PERMISSIONS.LIST)
        case 'tickets':
          return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.TICKETS, this.$alto.API_PERMISSIONS.LIST)
      }

      return false
    },
    async loadWidgets () {
      // Load properties and configuration for all available widgets
      this.widgetsProps = this.$store.getters.widgetsProps
      const _availableWidgetTypes = []
      const _widgetsList = this.$store.getters['widgets/list']
      const _layout = []

      try {
        const _widgetsConfig = await this.$store.dispatch('loadProfilePreferences', 'widgets')
        // Initialize all available widgets.
        _.forEach(this.widgetsProps, (widgetProp, key) => {
          if (widgetProp.layout.includes('grid') && this.isAllowed(key)) {
            _availableWidgetTypes.push(key) // This will appears in the menu to add a widget
            if (!_widgetsConfig[key] && widgetProp.singleton) {
              // We create a default configuration because we're not sure a configuration will be read from widgets preferences (that occurs on brand new profile).
              _widgetsConfig[key] = {
                widgetType: key,
                visible: false
              }
            }
          }
        })

        _.forEach(_widgetsConfig, (widget, key) => {
          if (!widget.widgetType) widget.widgetType = key.split('-')[0]

          // Keep widgets for our layout only
          if (this.canIShow(widget.widgetType) && this.isAllowed(key)) {
            // Push only visible widgets on layout. Note: Some widgets (like MonitorSingle ones) do not use a visibility flag!
            if ((widget.visible || widget.visible === undefined) && this.widgetsProps[widget.widgetType].layout.indexOf('grid') >= 0) {
              if (!widget.layout) {
                // Create a default layout
                widget.layout = {}
              }

              const tmp = {
                i: this.$stratus.uuid(), // key, UNIQUE Identifier for rendering
                widgetType: widget.widgetType, // Use to render component according to its type
                configId: key, // UNIQUE Identifier to find configuration
                x: widget.layout.x !== undefined ? widget.layout.x : 0,
                y: widget.layout.y !== undefined ? widget.layout.y : 0,
                w: widget.layout.w || DEFAULT_WIDTH,
                h: widget.layout.h || DEFAULT_HEIGHT,
                isStatic: Boolean(widget.layout.isStatic)
              }
              _layout.push(tmp)
            }
          }
        })

        // This will launch rendering of widget bar
        this.widgetsList = _widgetsList
        this.widgetsConfig = _widgetsConfig
        this.availableWidgetTypes = _availableWidgetTypes
        this.layout = _layout
        this.$nextTick(() => {
          this.$root.widget_supervision = this.$refs.widget_supervision
          this.$root.widget_alarms = this.$refs.widget_alarms
          this.$root.widget_tickets = this.$refs.widget_tickets
          this.$root.widget_monitor = this.$refs.widget_monitor
          this.showLayout = true
        })
      } catch (error) {
        this.$stratus.services.notify.error(error)
      }
    }
  },
  created () {
    this.$store.commit('$stratus-states/setPageTitle', this.$t('Dashboard'))
  },
  mounted () {
    if (!this.$stratus.services.auth.isLogged()) {
      // We're not logged in so return to home page to get a connection dialog
      this.$stratus.services.auth.logout()
      this.$router.push({ name: 'home' }).catch((error) => { console.warn(error) })
      window.location.reload()
      return
    }

    this.$root['supervision-instance-dialog'] = this.$refs['supervision-instance-dialog']
    this.$root['instance-alert-setup-alarms'] = this.$refs['instance-alert-setup-alarms']

    this.loadWidgets()
    this.$nextTick(() => { this.refreshDisplay(this.widgetsConfig) })
  }
}
</script>
