// Replaced at run time by the NGINX container before serving the JS files.
const baseUrl = '{{ API_URL }}'
const sentryDSN = '{{ SENTRY_DSN }}'
const appVersion = '{{ APP_VERSION }}'

const config = {
  beta: false,
  appVersion,
  env: 'production',
  url: baseUrl,
  api: baseUrl,
  sentryDSN,
  urlScalairDashboard: '{{ DASHBOARD_URL }}',
  urlScalairManager: '{{ MANAGER_URL }}',
  urlScalairStatus: '{{ STATUS_URL }}',
  ajaxUploadUrl: `${baseUrl}/upload`,
  infraStatusBaseUrl: 'https://status.scalair.fr',
  infraStatusServicesUrl: 'https://api-status.scalair.fr/services',
  servicesIconsPath: '/img/services/',
  avatarApi: 'https://www.gravatar.com/avatar/<%=hash%>?s=64pd=identicon',
  locale: 'fr-FR',
  debug: {
    i18n: false, // Log warning if translation is missing
    router: false, // Display log for route loading
    http: false // Log every HTTP request
  },
  metrics: {
    uptime: { ignore: true }
  },
  cloud: {
    // Can be started/stopped/rebooted and can setup alert thresholds from this app
    virtualVDC: 'vm-vdc',
    virtualVM: 'vm-mutu',
    virtuals: ['vm-mutu', 'vm-vdc'],
    // Can NOT be started/stopped/rebooted and NO setup alert thresholds from this app
    physicals: ['server'], // First is the default
    // Our Infra is fully manage by us ;)
    providers: {
      internals: ['scalair'], // First is the default
      // Third party infra we do NOT fully manage
      externals: ['aws']
    }
  }
}

export default config
