<template>
  <v-dialog v-model="show" persistent :max-width="$vuetify.breakpoint.mdAndUp ? '700px' : ''">
    <v-card outlined tile>
      <v-card-title class="text-h6">
        <v-icon dark left>
          $vuetify.icons.thresholds
        </v-icon>
        {{ instance.name }} — {{ $t('Alerts thresholds') }}
        <v-spacer />
        <span class="text-subtitle-1">{{ instance.provider }} / {{ instance.customerId }}</span>
      </v-card-title>

      <cs-color-divider color="widget-underline-monitor" />

      <v-card-text v-if="thresholds.length === 0" class="pa-8">
        <cs-icon-loading small left /> {{ $t('Loading thresholds...') }}
      </v-card-text>

      <v-card-text v-else>
        <p class="text-caption py-6">
          {{ $t('You can set the thresholds that trigger alerts at your convenience.') }}
        </p>

        <v-row v-for="(threshold, index) in thresholds" :key="index" no-gutters>
          <v-col cols="12" md="6">
            <div class="text-body-2">
              {{ $t(threshold.name) }}
              <span class="text-caption grey--text">({{ threshold.unit }})</span>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <v-row v-if="threshold.unit === '%'" align="start" dense>
              <v-col cols="6" md="6">
                <v-slider v-if="threshold.unit === '%'" v-model="threshold.inputValue" v-show="$vuetify.breakpoint.mdAndUp" max="100" min="0" thumb-label="always" />
              </v-col>
              <v-col cols="6" md="5" offset-md="1">
                <cs-integer-input v-if="threshold.unit === '%'" v-model="threshold.inputValue" :max="100" :min="0" />
              </v-col>
            </v-row>
            <cs-integer-input v-else-if="threshold.format === 'int'" v-model="threshold.inputValue" :label="threshold.name" />
            <v-text-field v-else-if="threshold.format === 'float'" v-model="threshold.inputValue" :label="threshold.name" />
            <v-text-field v-else v-model="threshold.inputValue" :label="threshold.name" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn text rounded @click="resetThresholds" :disabled="isWorking">
          {{ $t('Reset alerts thresholds') }}
        </v-btn>
        <v-spacer />
        <v-btn rounded class="main-button" @click.native="save(thresholds)" :disabled="isWorking || thresholds.length === 0" :loading="isWorking">
          {{ $t('Save') }}
        </v-btn>
        <v-btn rounded @click.native="cancel()" :disabled="isWorking">
          {{ $t('Cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <cs-confirm-dialog ref="confirmThresholdsReset" />
    <cs-alert-dialog ref="alertThresholdsUpdateDelayed" />
  </v-dialog>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'InstanceThresholdsSetup',
  data () {
    return {
      instance: {},
      isWorking: false,
      thresholds: [],
      show: false
    }
  },
  methods: {
    cancel () {
      this.resolve(false)
      this.show = false
    },
    load () {
      return this.$store.dispatch('instances/loadThresholds', { provider: this.instance.provider, id: this.instance.id })
    },
    open (instance) {
      this.show = true
      this.instance = instance
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        this.load()
          .then(thresholds => {
            this.thresholds = _.chain(thresholds)
              .map(t => { t.inputValue = t.unit === '%' ? t.value : t; return t })
              .sortBy(t => { return (t.name || '').toUpperCase() })
              .value()

            if (this.thresholds.length) {
              resolve(true)
              return
            }

            this.$stratus.services.notify.warning(this.$t('No threshold setup for this instance {name}.', { name: instance.name }))
            this.show = false
            resolve(false)
          })
          .catch(error => {
            this.show = false
            reject(error)
          })
      })
    },
    resetThresholds () {
      this.$refs.confirmThresholdsReset.open(this.$t('Reset alerts thresholds'), this.$t('Confirm the reset of all this thresholds?'), { color: 'red' })
        .then(confirmed => {
          if (confirmed) this.save([]) // Reset
        })
    },
    save (thresholds) {
      this.isWorking = true
      this.$store.dispatch('instances/saveThresholds', {
        id: this.instance.id,
        thresholds: thresholds
      })
        .then(() => {
          this.$stratus.services.notify.success(this.$t('Thresholds saved for {name}.', { name: this.instance.name }))
          this.$refs.alertThresholdsUpdateDelayed.open(this.$t('Important!'), this.$t('The new thresholds will be applied within 15 minutes.'), { color: 'orange' })
            .then(() => {
              this.show = false
            })
        })
        .catch(error => this.$stratus.services.notify.error(error))
        .finally(() => {
          this.isWorking = false
        })
    }
  }
}
</script>
