import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    current: { error: false, services: [] }
  },
  getters: {
    current: state => {
      return state.current
    }
  },
  mutations,
  actions
}
