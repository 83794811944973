<template>
  <v-scale-transition origin="center center">
    <div class="transition" :class="cls + (dark ? ' theme--dark' : '')" v-show="!isHidden" ref="container-monitor-card">
      <div v-if="instance.name" flat tile class="pb-6 ma-2">
        <!-- TITLE -->
        <v-row align="center" no-gutters>
          <v-col v-if="!hideVmName" class="shrink">
            <v-chip v-if="!canCommandInstance" :class="instance.state">
              <span class="text-truncate">{{ instance.name }}</span>
              <cs-icon-loading small right v-if="refreshing" />
            </v-chip>

            <v-menu v-else close-on-content-click offset-y transition="slide-y-transition" :nudge-width="200" v-model="menuVmVisible">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" outlined>
                  <cs-instance-state small v-model="instance.state" />
                  <span class="ml-1 text-truncate">{{ instance.name }}</span>
                  <cs-icon-loading v-if="refreshing" small right />
                  <v-icon v-else small right :class="menuVmVisible ? 'rotate-once-180' : ''">
                    $vuetify.icons.expand
                  </v-icon>
                </v-chip>
              </template>
              <v-list dense>
                <v-list-item v-if="canChangeThresholds" @click="setInstanceThresholds(vm)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small :dark="dark" left>
                        $vuetify.icons.thresholds
                      </v-icon>
                      {{ $t('Set alerts thresholds') }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isInternalProvider(instance.provider) && instance.state === 'on'" :disabled="askingForConsole" @click="askInstanceConsole(instance)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <cs-icon-loading v-if="askingForConsole" small :dark="dark" left />
                      <v-icon v-else small :dark="dark" left>
                        $vuetify.icons.vmConsole
                      </v-icon>
                      {{ $t('Open console on {name}...', { name: instance.name }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider class="ma-3" />

                <v-list-item v-if="isInternalProvider(instance.provider) && instance.state === 'off'" @click="instanceStart(instance)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small :dark="dark" left>
                        $vuetify.icons.vmStart
                      </v-icon>
                      {{ $t('Start {name}...', { name: instance.name }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isInternalProvider(instance.provider) && instance.state === 'on'" @click="instanceStop(instance, false)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small :dark="dark" left>
                        $vuetify.icons.vmStop
                      </v-icon>
                      {{ $t('Stop {name}...', { name: instance.name }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isInternalProvider(instance.provider)" @click="instanceReboot(instance)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small :dark="dark" left>
                        $vuetify.icons.vmReboot
                      </v-icon>
                      {{ $t('Reboot {name}...', { name: instance.name }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="isInternalProvider(instance.provider)" @click="instanceStop(instance, true)">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon small :dark="dark" left>
                        $vuetify.icons.unplug
                      </v-icon>
                      {{ $t('Force stop {name}...', { name: instance.name }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col class="text-right">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small left v-on="on" :class="page === 'gauges' ? 'primary--text' : 'menu-icon--text'" @click="page = 'gauges'">
                  icon-ring-gauge
                </v-icon>
              </template>
              <span>{{ $t('Instantaneous state') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small left v-on="on" :class="page === 'graphs' ? 'primary--text' : 'menu-icon--text'" @click="displayChart()">
                  $vuetify.icons.graph
                </v-icon>
              </template>
              <span>{{ $t('Performance history') }}</span>
            </v-tooltip>

            <v-tooltip v-if="allowMaximize" top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" :class="isMaximized ? 'primary--text' : 'menu-icon--text'" @click="toggleMaximize(vm)">
                  {{ isMaximized ? 'icon-exit-fullscreen' : 'icon-enter-fullscreen' }}
                </v-icon>
              </template>
              <span>{{ isMaximized ? $t('Reduce') : $t('Maximize') }}</span>
            </v-tooltip>

            <v-tooltip top v-if="canStopFollowing">
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on" class="ml-2 menu-icon--text" @click="stopFollowingVM(vm)">
                  $vuetify.icons.clear
                </v-icon>
              </template>
              <span>{{ $t('Stop monitoring') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row v-if="page === 'gauges'" class="text-center">
          <v-col cols="12">
            <v-row no-gutters class="text-left">
              <v-alert v-if="instance.toolsOk === false" type="warning" text dense>
                {{ $t('This instance needs an upgrade of vmTools!') }}
              </v-alert>

              <v-col cols="12" v-show="instance.vdcName">
                {{ instance.vdcName }}
              </v-col>

              <v-col cols="12" v-show="instance.OSType">
                <span class="text-caption">{{ instance.OSType }}</span>
              </v-col>

              <v-col cols="12" v-show="backups.length" class="mt-2">
                <cs-backups-panel v-if="backups.length > 0" v-model="backups" />
                <span v-else>
                  {{ $t('No backup') }}
                </span>
              </v-col>

              <v-col cols="12" v-show="alarms.length" class="mt-2">
                <cs-alarms-panel v-model="alarms" :worst-alarm="this.worstAlarm" />
              </v-col>

              <v-col cols="12" v-show="probes.length" class="mt-2">
                <cs-probes-panel v-model="probes" />
              </v-col>
            </v-row>

            <div v-if="denseGauges">
              <v-divider class="ma-3" />
              <v-row align="center" no-gutters>
                <v-col class="pt-2 text-left">
                  {{ $tc('No vCPU!|{count} vCPU', instance.vcpu, { count: instance.vcpu }) }}
                </v-col>
                <v-col class="text-right" cols="3">
                  <v-progress-linear :value="getMetricValue('cpu')" :color="getProgressBarColor(getMetricValue('cpu'))" />
                </v-col>
                <v-col cols="2" class="pt-2 shrink text-right">
                  {{ $t('{percent}%', { percent: getMetricValue('cpu') }) }}
                </v-col>
              </v-row>

              <v-row align="center" no-gutters>
                <v-col class="pt-2 text-left">
                  {{ $tc('No vRAM!|{count} GB vRAM', (instance.vram > 0 ? 2 : 0), { count: instance.vram }) }}
                </v-col>
                <v-col class="text-right" cols="3">
                  <v-progress-linear :value="getMetricValue('ram')" :color="getProgressBarColor(getMetricValue('ram'))" />
                </v-col>
                <v-col cols="2" class="pt-2 shrink text-right">
                  {{ $t('{percent}%', { percent: getMetricValue('ram') }) }}
                </v-col>
              </v-row>

              <v-row v-if="!disksCount || !stats.disks.count" align="center" no-gutters>
                <v-col cols="12">
                  <v-alert type="warning" text dense>
                    {{ $t('No storage for this instance.') }}
                  </v-alert>
                </v-col>
              </v-row>

              <v-row v-for="(disk, drive) in disks" :key="drive" align="center" no-gutters>
                <v-col class="pt-2 text-left">
                  {{ $t('«{drive}»', { drive }) }} {{ $t('{count}{unit}', $stratus.services.format.bytesToBestUnit(disk.total)) }}
                </v-col>
                <v-col class="text-right" cols="3">
                  <v-progress-linear :value="disk.used" :color="getProgressBarColor(disk.used)" />
                </v-col>
                <v-col cols="2" class="pt-2 shrink text-right">
                  {{ $t('{percent}%', { percent: formatPercent(disk.used) }) }}
                </v-col>
              </v-row>
            </div>

            <v-row v-else no-gutters>
              <!-- VCPU -->
              <v-col cols="6">
                <div height="100%">
                  <div class="pa-0">
                    <v-progress-circular :size="64" :width="7" :rotate="-90" :value="getMetricValue('cpu')" :color="getProgressBarColor(getMetricValue('cpu'))">
                      <div>{{ $t('{percent}%', { percent: getMetricValue('cpu') }) }}</div>
                    </v-progress-circular>
                  </div>
                  {{ $tc('No vCPU!|{count} vCPU', instance.vcpu, { count: instance.vcpu }) }}
                </div>
              </v-col>

              <!-- VRAM -->
              <v-col cols="6">
                <div height="100%">
                  <div class="pa-0">
                    <v-progress-circular :size="64" :width="7" :rotate="-90" :value="getMetricValue('ram')" :color="getProgressBarColor(getMetricValue('ram'))">
                      <div>{{ $t('{percent}%', { percent: getMetricValue('ram') }) }}</div>
                    </v-progress-circular>
                  </div>
                  {{ $tc('No vRAM!|{count} GB vRAM', (instance.vram > 0 ? 2 : 0), { count: instance.vram }) }}
                </div>
              </v-col>
            </v-row>

            <!-- STORAGE GAUGES -->
            <v-row v-if="!denseGauges" no-gutters>
              <v-col class="text-center">
                <div class="pa-1" height="100%">
                  <div v-if="!disksCount || !stats.disks.count" class="warning--text">
                    <v-icon small class="warning--text">
                      $vuetify.icons.warning
                    </v-icon>{{ $t('No storage for this instance.') }}
                  </div>

                  <v-row v-else v-show="showStorage" no-gutters>
                    <v-col cols="12" sm="6" v-for="(disk, drive) in disks" :key="drive">
                      <div class="pa-1">
                        <div class="pa-0">
                          <v-progress-circular :size="64" :width="7" :rotate="-90" :value="disk.used" :color="getProgressBarColor(disk.used)">
                            <div>{{ $t('{percent}%', { percent: formatPercent(disk.used) }) }}</div>
                          </v-progress-circular>
                        </div>
                        {{ $t('«{drive}»', { drive }) }} {{ $t('{count}{unit}', $stratus.services.format.bytesToBestUnit(disk.total)) }}
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- CHARTS TIME RANGE -->
        <v-row v-if="page === 'graphs'" dense>
          <v-col cols="12" class="text-center mb-2">
            <v-row align="center" justify="center">
              <v-col class="shrink">
                <v-btn-toggle v-model="graphRange" class="transparent" mandatory text>
                  <v-btn small v-for="(time, index) in timeRange" :key="index" text :value="index" @click="refreshTimeLine(time)" :class="index === graphRange ? 'button-main button-main-ink--text' : ''" :disabled="refreshing">
                    {{ $t(time) }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col class="shrink text-no-wrap">
                <v-switch v-model="autoScale" :label="$t('Automatic scale')" hide-details dense class="pa-0 ma-0" />
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <!-- CHARTS -->
            <v-row v-for="(graph, index) in graphs" :key="index" dense>
              <v-col class="text-center" cols="12" :ref="'flex-' + graph.chartKey">
                <div v-if="graph.isEmpty" class="warning--text">
                  <v-alert dense text type="warning" class="mx-12">
                    <v-icon class="warning--text">
                      $vuetify.icons.graph
                    </v-icon> {{ graph.metricNames.join(', ') }}
                    <p>{{ $t('No history for this period.') }}</p>
                  </v-alert>
                </div>
                <div v-else>
                  <cs-chart-legend :ref="'graph-legend-' + graph.chartKey" :graph="graph" can-hide-graph :graph-hidden="hideGraphs[graph.chartKey]" @toggle-graph="toggleGraphVisibility" @toggle-dataset="toggleFromLegend" :resume="getResume(graph)" />
                  <line-chart v-show="!hideGraphs[graph.chartKey]" :chart-data="graph" :options="getGraphOptions(graph)" :ref="'graph-' + graph.chartKey" />
                </div>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="!graphs" cols="12" class="mt-2">
            <v-card outlined tile class="background-blurred">
              <v-card-text class="text-center mt-12">
                {{ $t('No history for this instance.') }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-else>
        <v-row>
          <v-col cols="12">
            <v-toolbar class="transparent" dense flat>
              <v-chip small>
                <span class="text-truncate">{{ vm.name }}</span>
                <cs-icon-loading dark right small v-if="!error" />
              </v-chip>

              <v-spacer />

              <v-btn v-if="canStopFollowing" small icon @click="stopFollowingVM(vm)" class="menu-icon--text">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on">
                      $vuetify.icons.clear
                    </v-icon>
                  </template>
                  <span>{{ $t('Stop monitoring') }}</span>
                </v-tooltip>
              </v-btn>
            </v-toolbar>
            <cs-error-panel v-model="error" />
          </v-col>
        </v-row>
      </div>

      <cs-confirm-dialog ref="vm-confirm" />
      <cs-console-dialog ref="console-dialog" />
    </div>
  </v-scale-transition>
</template>

<script>
import _ from 'lodash'

import { getInstanceStateColor } from '@/theme/widgets'
import LineChart from './LineChart'
import ConsoleDialog from '@/components/ConsoleDialog'
import InstanceState from '@/components/cs/InstanceState'

const WIDGET_ID = 'monitor'
const DISK_PERCENT_THRESHOLD_WARNING = 50
const DISK_PERCENT_THRESHOLD_CRITICAL = 80

const JOB_POLLING_DELAY = 3000
const JOB_MAX_WAIT = 10 // wait 30 times

export default {
  components: {
    'cs-instance-state': InstanceState,
    'cs-alarms-panel': () => import(/* webpackChunkName: "components" */ '@/components/cs/AlarmsPanel.vue'),
    'cs-backups-panel': () => import(/* webpackChunkName: "components" */ '@/components/cs/BackupsPanel.vue'),
    'cs-probes-panel': () => import(/* webpackChunkName: "components" */ '@/components/cs/ProbesPanel.vue'),
    'line-chart': LineChart, // Component with ref are tricky to async load, so I avoid doing this
    'cs-console-dialog': ConsoleDialog
  },
  props: {
    allowMaximize: { type: Boolean, default: false },
    canStopFollowing: { type: Boolean, default: false },
    cls: { type: String, default: '' },
    denseGauges: { type: Boolean, default: false },
    hideVmName: { type: Boolean, default: false },
    vm: { type: Object, required: true }
  },
  data () {
    return {
      // localeData: this.$stratus.dt.localeData,
      askingForConsole: false,
      autoScale: false,
      error: '',
      isHidden: false,
      instance: {},
      refreshing: true,
      timeRange: ['1h', '6h', '1d', '1w', '1M', '1Q'],
      graphRange: 0,
      showChart: false,
      showLegend: true,
      showStorage: [true],
      alarms: [],
      backups: [],
      probes: [],
      worstAlarm: {},
      disks: {},
      otherMetrics: {}, // Metrics that are not cpu, ram and disks
      stats: { disks: null },
      graphs: null,
      chartHeight: 1,
      chartWidth: 1,
      menuVmVisible: false,
      page: 'gauges',
      periods: null,
      graphsRefs: {},
      hideGraphs: {},
      hideDatasets: {},
      isMaximized: false
    }
  },
  watch: {
    page (newPage, oldPage) {
      this.saveConfig({ page: newPage })
    },
    showStorage (newVal, oldVal) {
      if (newVal.length === oldVal.length && newVal.join() !== (oldVal || []).join()) {
        this.saveConfig({ showStorage: newVal })
      }
    }
  },
  computed: {
    canCommandInstance () {
      return this.$store.getters['$alto-roles/can'](this.instance.$can, this.$alto.API_PERMISSIONS.INSTANCE_ACTION)
    },
    canChangeThresholds () {
      return this.$store.getters['$alto-roles/canI'](this.$alto.API_CONTEXTS.ALARMS_THRESHOLDS, this.$alto.API_PERMISSIONS.UPDATE)
    },
    dark () {
      return this.$store.getters['$stratus-states/isDark']
    },
    disksCount () {
      return Object.keys(this.disks).length
    }
  },
  methods: {
    async askInstanceConsole (instance) {
      try {
        this.askingForConsole = true
        const job = await this.$store.dispatch('instances/askConsole', instance)
        if (job && job.task_id) {
          // start polling
          this.jobWait = 0
          job.instance = {
            name: instance.name,
            id: instance.id,
            vCenterName: instance.vCenterName
          }
          this.askJobState(job)
        } else {
          if (job) console.error('[console] error:', job)
          throw new Error(this.$t('Error: cannot open console of {name}.', instance))
        }
      } catch (error) {
        this.$stratus.services.notify.error(error)
        this.askingForConsole = false
      }
    },
    askJobState (job) {
      this.jobWait++
      if (this.jobWait > JOB_MAX_WAIT) {
        this.$stratus.services.notify.error(this.$t('No response from {name} console', job.instance))
        this.askingForConsole = false
        return
      }

      this.$store.dispatch('instances/askJobState', job.task_id)
        .then(response => {
          if (response.status === 'finished') {
            this.askingForConsole = false
            let socketId = response.res.websocket.split('/')
            socketId = socketId[socketId.length - 1]
            const hostname = (new URL(response.res.websocket)).hostname
            this.$refs['console-dialog'].open(this.instance, hostname, socketId)
          } else if (response.status === 'failed') {
            throw new Error(this.$t('Error: cannot open console of {name}.', job.instance))
          } else {
            setTimeout(() => { this.askJobState(job) }, JOB_POLLING_DELAY)
          }
        })
        .catch(error => {
          console.error('Failed job:', job)
          this.$stratus.services.notify.error(error)
          this.askingForConsole = false
        })
    },
    displayGraph (name, isHidden) {
      try {
        if (this.$refs['graph-' + this.graphsRefs[name].chartKey]) {
          this.graphsRefs[name].dataset.hidden = isHidden
          // Hide/Show in LineCart.js
          this.$refs['graph-' + this.graphsRefs[name].chartKey][0].displayDataset(name, isHidden)
        }
      } catch (e) {
        console.error(e)
      }
    },
    formatPercent (value) {
      return value < 10 ? Math.round(value * 10) / 10 : Math.round(value)
    },
    getGraphScaledValue (value, unit) {
      let count = value
      let perSec
      switch (unit.toUpperCase()) {
        case 'B':
        case 'B/S':
          perSec = this.$stratus.services.format.bytesToBestUnit(value, true) // Already have { count, unit }
          count = perSec.count
          unit = perSec.unit + this.$t('/s')
          break
        case 'MS':
          count = this.$stratus.services.format.asDigits(value, 3)
          break
        case 'S':
          count = this.$stratus.services.format.secondsToDays(value)
          unit = ''
          break
        case 'IOPS':
          perSec = this.$stratus.services.format.numberToBestUnit(value, true) // Already have { count, unit }
          count = perSec.count
          unit = perSec.unit + ' ' + this.$t('I-O/s')
          break
        case 'COUNT':
          unit = ''
          break
      }
      return { count, unit }
    },
    getGraphOptions (graph) {
      if (graph.source === 'prometheus') {
        return {
          scales: {
            xAxes: [{
              type: 'time',
              distribution: 'linear',
              time: {
                displayFormats: {
                  year: 'YYYY',
                  month: 'MMMM',
                  week: this.$stratus.dt.localeData().longDateFormat('ll'),
                  day: this.$stratus.dt.localeData().longDateFormat('ll'),
                  hour: this.$stratus.dt.localeData().longDateFormat('LT'),
                  minute: this.$stratus.dt.localeData().longDateFormat('LT'),
                  second: this.$stratus.dt.localeData().longDateFormat('LTS')
                },
                tooltipFormat: '[' + this.$t('Details') + ']' // this.$stratus.dt.localeData().longDateFormat('l LT')
              }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: this.$t('Percent Used')
              },
              type: 'linear',
              ticks: {
                max: !this.autoScale && graph.unit === '%' ? 100 : undefined,
                min: !this.autoScale && graph.unit === '%' ? 0 : undefined,
                userCallback: (tick) => {
                  if (tick > 0 && tick <= 100) {
                    tick = this.$t('{count}{unit}', { count: tick, unit: graph.unit })
                  }
                  return tick
                }
              }
            }]
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            labels: {
              fontSize: 10
            }
          },
          tooltips: {
            enabled: true,
            intersect: false,
            callbacks: {
              label: (tooltipItems, data) => {
                return this.$t('{label} use {value}{unit} at {date} (Raw: {raw}).', {
                  label: data.datasets[tooltipItems.datasetIndex].label,
                  value: this.formatPercent(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y),
                  unit: data.unit,
                  date: this.$stratus.dt(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].x).format('LL LTS')
                })
              }
            }
          }
        }
      } else {
        const yAxes = [{
          id: 'y-axis-' + graph.unit,
          type: 'linear',
          position: 'left',
          ticks: {
            max: graph.unit === '%' ? 100 : undefined,
            min: graph.unit === '%' ? 0 : undefined,
            callback: (tick) => {
              return this.$t('{count}{unit}', this.getGraphScaledValue(tick, graph.unit))
            }
          }
        }]
        if (graph.extraUnits.length) {
          graph.extraUnits.forEach(unit => {
            yAxes.push({
              id: 'y-axis-' + unit,
              type: 'linear',
              position: 'right',
              ticks: {
                max: graph.unit === '%' ? 100 : undefined,
                min: graph.unit === '%' ? 0 : undefined,
                callback: (tick) => {
                  return this.$t('{count}{unit}', this.getGraphScaledValue(tick, unit))
                }
              }
            })
          })
        }

        const options = {
          scales: {
            xAxes: [{
              type: 'time',
              distribution: 'linear',
              time: {
                displayFormats: {
                  year: 'YYYY',
                  month: 'MMMM',
                  week: this.$stratus.dt.localeData().longDateFormat('ll'),
                  day: this.$stratus.dt.localeData().longDateFormat('ll'),
                  hour: this.$stratus.dt.localeData().longDateFormat('LT'),
                  minute: this.$stratus.dt.localeData().longDateFormat('LT'),
                  second: this.$stratus.dt.localeData().longDateFormat('LTS')
                },
                tooltipFormat: '[' + this.$t('Details') + ']' // this.$stratus.dt.localeData().longDateFormat('l LT')
              }
            }],
            yAxes
          },
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            labels: {
              fontSize: 10
            }
          },
          tooltips: {
            enabled: true,
            intersect: false,
            callbacks: {
              label: (tooltipItems, data) => {
                const convertedValue = this.getGraphScaledValue(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y, data.unit)
                return this.$t('{label} use {value}{unit} at {date} (Raw: {raw}).', {
                  date: this.$stratus.dt(data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].x).format('LL LTS'),
                  label: data.datasets[tooltipItems.datasetIndex].label,
                  unit: convertedValue.unit,
                  value: convertedValue.count,
                  raw: data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y
                })
              }
            }
          }
        }

        return options
      }
    },
    getMetricValue (metric) {
      if (!this.metrics[metric] || !this.metrics[metric].current || this.metrics[metric].current.value === undefined) return '?'
      return Math.round(this.metrics[metric].current.value)
    },
    getProgressBarColor (used) {
      if (used >= DISK_PERCENT_THRESHOLD_CRITICAL) return 'error'
      else if (used >= DISK_PERCENT_THRESHOLD_WARNING) return 'warning'
      else return 'success'
    },
    getResume (graph) {
      return this.$t('Source: {source}', { source: this.$t('source-' + graph.source) }) + ' — ' + graph.metricNames.join(', ')
    },
    getStateColor (state) {
      return getInstanceStateColor(state)
    },
    instanceStart (instance) {
      this.$refs['vm-confirm'].open(instance.name, this.$t('Confirm the start of this instance.')).then((confirm) => {
        if (confirm) {
          this.$stratus.services.notify.info(this.$t('Start order send for {name}...', { name: instance.name }))
          this.$store.dispatch('instances/start', instance)
            .then()
            .catch(error => {
              this.$stratus.services.notify.error(error)
            })
        }
      })
    },
    instanceStop (instance, force = false) {
      const warning = force ? this.$t('Caution! This shutdown will abruptly stop all running programs. It is equivalent to cutting the power.') + ' ' : ''
      this.$refs['vm-confirm'].open(instance.name, warning + this.$t('Confirm the stop of this instance.')).then((confirm) => {
        if (confirm) {
          this.$stratus.services.notify.info(this.$t('Stop order send for {name}...', { name: instance.name }))
          this.$store.dispatch('instances/stop', { ...instance, force })
            .then()
            .catch(error => {
              this.$stratus.services.notify.error(error)
            })
        }
      })
    },
    instanceReboot (instance) {
      const m = instance.toolsOk ? 'Confirm the reboot of this instance.' : 'Confirm the reboot of this instance. (As vmtools are not installed, a hard reboot well be performed)'
      this.$refs['vm-confirm'].open(instance.name, this.$t(m)).then((confirm) => {
        if (confirm) {
          this.$stratus.services.notify.info(this.$t('Reboot order send for {name}...', { name: instance.name }))
          this.$store.dispatch('instances/reboot', instance)
            .then()
            .catch(error => {
              this.$stratus.services.notify.error(error)
            })
        }
      })
    },
    isInternalProvider (provider) {
      return this.$store.getters.appConfig && this.$store.getters.appConfig.cloud.providers.internals.indexOf(provider) >= 0
    },
    async refresh (forceRefresh = false) {
      if (!this.vm.name || !this.vm.provider) {
        throw new Error('Erroneous instance!')
      }

      this.refreshing = true
      // Attach loaded vm config
      if (this.vm.delay !== undefined) this.graphRange = this.timeRange.indexOf(this.vm.delay) >= 0 ? this.timeRange.indexOf(this.vm.delay) : 0
      if (this.vm.page) this.page = this.vm.page || 'gauges'
      this.showStorage = this.vm.showStorage || [true]
      this.hideGraphs = this.vm.hideGraphs || {}

      try {
        let monitoredItem = this.$store.getters[`widgets/${WIDGET_ID}/monitoredItemsMetrics`](this.vm.id)

        if (forceRefresh || !monitoredItem || !monitoredItem.expirationDate || this.$stratus.dt().isAfter(monitoredItem.expirationDate)) {
          // Call API to get fresh data
          await this.$store.dispatch(`widgets/${WIDGET_ID}/getMonitoredItems`, {
            id: this.vm.id,
            provider: this.vm.provider,
            time: this.timeRange[this.graphRange],
            hideDatasets: this.vm.hideDatasets || {},
            customerId: this.vm.customerId
          })
          monitoredItem = this.$store.getters[`widgets/${WIDGET_ID}/monitoredItemsMetrics`](this.vm.id)
        }

        if (!monitoredItem) {
          throw new Error(this.$t('No data for {name}.', { name: this.vm.name }))
        }
        // Get some shortcut
        this.instance = monitoredItem.instance
        this.alarms = monitoredItem.alarms
        this.probes = monitoredItem.probes || []
        this.backups = monitoredItem.instance.backups || []
        this.worstAlarm = monitoredItem.worstAlarm
        this.metrics = monitoredItem.metrics
        if (monitoredItem.metrics.disk) {
          this.disks = monitoredItem.metrics.disk.current || {}
          this.stats = {
            disks: {
              ...this.$stratus.services.format.bytesToBestUnit(monitoredItem.metrics.disk.stats.totalSize),
              avg: Math.round(monitoredItem.metrics.disk.stats.avgFree),
              min: Math.round(monitoredItem.metrics.disk.stats.minFree),
              max: Math.round(monitoredItem.metrics.disk.stats.maxFree)
            }
          }
        }

        // List all metrics that are NOT cpu, ram and disks.
        this.otherMetrics = {}
        _.forEach(this.metrics, (metric, key) => {
          if (['cpu', 'ram', 'disk'].indexOf(key) < 0) this.otherMetrics[key] = metric
        })

        this.periods = monitoredItem.periods
        this.graphs = monitoredItem.graphs
        this.graphsRefs = monitoredItem.refs

        this.refreshing = false
        this.error = ''

        this.$emit('widgetRefreshed', {
          instance: this.instance,
          alarms: this.alarms,
          worstAlarm: this.worstAlarm,
          probes: this.probes,
          backups: this.backups,
          disks: this.disks,
          stats: this.stats,
          otherMetrics: this.otherMetrics,
          periods: this.periods,
          graphs: this.graphs,
          graphsRefs: this.graphsRefs
        })

        return true
      } catch (error) {
        console.error(this.vm.name, this.vm.id, error)
        this.error = this.$t(this.$stratus.services.format.getErrorMessage(error))
        throw new Error(this.error)
      }
    },
    refreshGraph (key) {
      try {
        if (this.$refs['graph-' + key][0]) {
          this.$refs['graph-' + key][0].update()
        }
      } catch (e) {
        console.error(e)
      }
    },
    refreshTimeLine (newTime) {
      this.refreshing = true
      this.saveConfig({ delay: newTime })
      setTimeout(() => {
        this.refresh(true).then()
      }, 1000) // force refresh
    },
    reset () {
      this.askingForConsole = false
      this.error = ''
      this.isHidden = false
      this.instance = {}
      this.refreshing = true
      this.graphRange = 0
      this.showChart = false
      this.showLegend = true
      this.showStorage = [true]
      this.alarms = []
      this.backups = []
      this.probes = []
      this.worstAlarm = {}
      this.disks = {}
      this.otherMetrics = {}
      this.stats = { disks: null }
      this.graphs = null
      this.chartHeight = 1
      this.chartWidth = 1
      this.menuVmVisible = false
      this.page = 'gauges'
      this.periods = null
      this.graphsRefs = {}
      this.hideGraphs = {}
      this.hideDatasets = {}
      this.isMaximized = false
    },
    saveConfig (config) {
      this.$emit('saveMonitoredItems', Object.assign({ id: this.vm.id, name: this.vm.name, delay: this.timeRange[this.graphRange], showChart: this.showChart, showStorage: this.showStorage, page: this.page, hideGraphs: this.hideGraphs, hideDatasets: this.hideDatasets }, config))
    },
    setInstanceThresholds (vm) {
      if (vm) {
        this.$root['instance-alert-setup-alarms'].open(this.instance)
      }
    },
    toggleGraph (name) {
      if (this.graphsRefs[name] && this.$refs['graph-' + this.graphsRefs[name].chartKey]) {
        this.$set(this.hideGraphs, name, !this.hideGraphs[name])
        this.displayGraph(name, this.hideGraphs[name])
        this.saveConfig()
      }
    },
    toggleDatasetVisibility (chartKey, datasetId, visible) {
      if (!this.hideDatasets[chartKey]) this.hideDatasets[chartKey] = {}
      this.hideDatasets[chartKey][datasetId] = visible
    },
    toggleFromLegend (dataset) {
      if (dataset === 'all') {
        // Hide all datasets
        _.forEach(this.graphsRefs, graphRef => {
          if (this.$refs['graph-' + graphRef.chartKey]) {
            this.$refs['graph-' + graphRef.chartKey][0].displayDataset(dataset.datasetId, dataset.hidden)
          }
          this.toggleDatasetVisibility(graphRef.chartKey, dataset.datasetId, dataset.hidden)
        })
        this.saveConfig()
      } else if (this.graphsRefs[dataset.datasetId] && this.$refs['graph-' + this.graphsRefs[dataset.datasetId].chartKey]) {
        this.$refs['graph-' + this.graphsRefs[dataset.datasetId].chartKey][0].displayDataset(dataset.datasetId, dataset.hidden)
        this.toggleDatasetVisibility(this.graphsRefs[dataset.datasetId].chartKey, dataset.datasetId, dataset.hidden)
        this.saveConfig()
      }
    },
    toggleGraphVisibility (graph) {
      if (this.$refs['graph-' + graph.chartKey]) {
        this.$set(this.hideGraphs, graph.chartKey, !this.hideGraphs[graph.chartKey])
        this.saveConfig()
        if (!this.hideGraphs[graph.chartKey]) this.resizeGraph(graph.chartKey)
      }
    },
    displayChart () {
      this.page = 'graphs'
      this.saveConfig({ showChart: true })
      this.$emit('widgetRefreshed')
    },
    displayProbes () {
      this.page = 'probes'
      this.saveConfig({ showProbe: true })
      this.$emit('widgetRefreshed')
    },
    resizeContent (height, width) {
      const h = []
      const w = []
      _.forEach(this.graphsRefs, (graph) => {
        _.forEach(this.$refs['flex-' + graph.chartKey], (flex) => {
          h.push(height - flex.offsetTop - 88)
          w.push(width - flex.offsetLeft - 32)
        })
        _.forEach(this.$refs['graph-' + graph.chartKey], (g, index) => { g.resize(h[index], w[index]) })
      })
    },
    resizeGraph (chartKey) {
      // Search a visible graph to get height and width of a chart
      let size = null
      _.forEach(this.graphsRefs, graph => {
        _.forEach(this.$refs['graph-' + graph.chartKey], g => {
          const s = g ? g.getSize() : null
          if (!size && s && parseInt(s.height) > 0 && parseInt(s.width) > 0) {
            size = { ...s }
          }
        })
      })
      if (size) {
        _.forEach(this.$refs['graph-' + chartKey], g => {
          g.resize(size.height, size.width)
        })
      }
    },
    stopFollowingVM (instance) {
      this.$refs['vm-confirm'].open(instance.name, this.$t('Do you really want to stop following this instance ?')).then((confirm) => {
        if (confirm) {
          this.$emit('vmUnfollowed', instance.id)
        }
      })
    },
    toggleMaximize (instance) {
      this.isMaximized = !this.isMaximized
      this.$emit('maximize', { id: instance.id, maximize: this.isMaximized })
    }
  },
  mounted () {
    this.container = this.$refs['container-monitor-card']
    this.resizeContent = _.debounce(this.resizeContent, 500)
  }
}
</script>
